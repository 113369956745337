import {
  FETCH_ACCOUNTS_BEGIN,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_ACCESS_LOGS_BEGIN,
  FETCH_ACCOUNT_ACCESS_LOGS_SUCCESS,
  FETCH_ACCOUNT_ACCESS_LOGS_FAILURE,
  FETCH_DOMAIN_CONFIG_SUCCESS,
  FETCH_REPORT_DATA_FEEDS_SUCCESS,
  FETCH_ORG_THEME_SUCCESS,
  RESET_ACCOUNTS,
  UPDATE_ACCOUNT_PUBLIC_BEGIN,
  UPDATE_ACCOUNT_PUBLIC_SUCCESS,
  REPORT_CREATED,
  REPORT_UPDATED,
} from "../actions/accountActions";

const initialState = {
  accounts: [],
  organisations: [],
  theme: {},
  reports: {},
  domainConfig: {},
  loading: true,
  updateAccountLoading: false,
  error: null,
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: action.payload.accounts,
        organisations: action.payload.organisations,
      };

    case FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        showNewOrganisation: true,
        loading: false,
        error: action.payload.error,
        accounts: [],
        organisations: [],
      };

    case FETCH_ACCOUNT_SUCCESS:
      const accounts = state.accounts.map((item) => {
        if (item.id !== action.payload.account.id) {
          return item;
        }

        return { ...item, ...action.payload.account };
      });
      return {
        ...state,
        accounts,
        loading: false,
      };

    case FETCH_REPORT_DATA_FEEDS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
      };

    // -- Access Logs
    case FETCH_ACCOUNT_ACCESS_LOGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        accessLogs: [],
      };

    case FETCH_ACCOUNT_ACCESS_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        accessLogs: action.payload,
      };

    case FETCH_ACCOUNT_ACCESS_LOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        accessLogs: [],
      };
    // -- Access Logs

    case FETCH_DOMAIN_CONFIG_SUCCESS:
      return {
        ...state,
        domainConfig: action.payload,
      };

    case FETCH_ORG_THEME_SUCCESS:
      return {
        ...state,
        theme: action.payload,
      };

    case UPDATE_ACCOUNT_PUBLIC_BEGIN: {
      return {
        ...state,
        updateAccountLoading: true,
      };
    }

    case UPDATE_ACCOUNT_PUBLIC_SUCCESS: {
      return {
        ...state,
        updateAccountLoading: false,
        accounts: state.accounts.map((account) => {
          if (account.id === action.payload.id) {
            return {
              ...account,
              public: action.payload.public,
            };
          }
          return account;
        }),
      };
    }

    case RESET_ACCOUNTS:
      return {
        ...state,
        accounts: initialState.accounts,
        loading: initialState.loading,
        error: initialState.error,
      };

    case REPORT_CREATED:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.id === action.payload.accountId) {
            return {
              ...account,
              sections: account.sections.some(
                (x) => x.name === action.payload.sectionName
              )
                ? account.sections.map((section) => {
                    if (section.name === action.payload.sectionName) {
                      const reports = [...section.reports, action.payload];
                      return {
                        ...section,
                        reports: reports.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        ),
                      };
                    }
                    return section;
                  })
                : [
                    ...account.sections,
                    {
                      name: action.payload.sectionName,
                      reports: [action.payload],
                    },
                  ],
            };
          }
          return account;
        }),
      };
    default:
      return state;
  }
}
