import Box from "@material-ui/core/Box";
import { FormInputLabel } from "./FormInputLabel";
import { Control, Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import React from "react";

type FormInputTextProps = {
  name: string;
  label: string;
  control: Control<any>;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  docsUrl?: string;
  error?: string;
  disabled?: boolean;
};
export function FormInputText({
  control,
  label,
  placeholder,
  defaultValue,
  required,
  name,
  docsUrl,
  error,
  disabled,
}: FormInputTextProps) {
  return (
    <Box display="flex" flexDirection="column" gridGap="0.5rem">
      <FormInputLabel value={label} required={required} docsUrl={docsUrl} />
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => (
          <TextField
            variant="outlined"
            value={value}
            onChange={onChange}
            required={required}
            placeholder={placeholder}
            error={!!error}
            helperText={error}
            disabled={disabled}
          />
        )}
      />
    </Box>
  );
}
