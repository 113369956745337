import React from "react";
import { IFrameReportFields, IframeFormValues } from "./form.types";
import { FormSectionTitle } from "../Form/FormSectionTitle";
import { FormInputText } from "../FormInput/FormInputText";
import { Chip, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { FormInputLabel } from "../FormInput/FormInputLabel";
import { FormInputCheckbox } from "../FormInput/FormInputCheckbox";
import { FormSplitter } from "../Form/FormSplitter";
import { FormInputSelect } from "../FormInput/FormInputSelect";
import { reportSections } from "../reportSections";
import { FormButton } from "../Form/FormButton";
import { makeStyles } from "@material-ui/core/styles";
import { UseFormReturn } from "react-hook-form";

type CreateIframeReportFormViewProps = {
  form: UseFormReturn<IframeFormValues>;
  handleBackClick: () => void;
  onSubmit: (data: IframeFormValues) => void;
  fields: IFrameReportFields;
};
export function CreateIframeReportFormView({
  form,
  onSubmit,
  handleBackClick,
  fields,
}: CreateIframeReportFormViewProps) {
  const { control, handleSubmit } = form;
  const classes = useStyles();
  const submitDisabled =
    !form.formState.isValid ||
    (!form.formState.isDirty &&
      form.formState.defaultValues?.applicationName !== "");
  const showBackButton = form.formState.defaultValues?.applicationName === "";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.outerContainer}>
        <div className={classes.leftContainer}>
          <FormSectionTitle value="Settings" />
          <FormInputText
            name="applicationName"
            label={fields.name.text}
            control={control}
            placeholder={
              fields.name.placeholder ?? "How to access your API Key"
            }
            error={form.formState.errors.applicationName?.message}
            disabled={form.formState.isSubmitting}
            required
          />
          <FormInputText
            name="notes"
            label={fields.description.text}
            control={control}
            placeholder={
              fields.description.placeholder ?? "Demo of new Auth Key tool"
            }
            error={form.formState.errors.notes?.message}
            disabled={form.formState.isSubmitting}
          />
          <FormInputText
            name="publicUrl"
            control={control}
            placeholder="https://thatapicompany.retool.com/embedded/public"
            label={fields.url.text}
            docsUrl={fields.url.docsUrl}
            error={form.formState.errors.publicUrl?.message}
            disabled={form.formState.isSubmitting}
            required
          />
          <Box display="flex" flexDirection="column" gridGap="0.5rem">
            <FormInputLabel value="Publishing" />
            <Typography variant="caption">
              Decide how you want to share the report
            </Typography>
            <FormInputCheckbox
              control={control}
              name="publishing"
              label="Notify teammates when I save"
              disabled
            />
            <Chip
              size="small"
              label="Coming soon"
              color="secondary"
              disabled
              variant="outlined"
              style={{ width: "fit-content" }}
            />
            <FormInputCheckbox
              control={control}
              name="public"
              label="Make page public"
              disabled={form.formState.isSubmitting}
            />
            {fields.slug === "retool" && (
              <>
                <FormInputCheckbox
                  control={control}
                  name="passUserProfile"
                  label="Pass user’s profile to Retool application (what’s this?)"
                  disabled
                />
                <Chip
                  size="small"
                  label="Coming soon"
                  color="secondary"
                  disabled
                  variant="outlined"
                  style={{ width: "fit-content" }}
                />
              </>
            )}
          </Box>
        </div>
        <FormSplitter />
        <div className={classes.leftContainer}>
          <FormSectionTitle value="Display Options" />
          <FormInputSelect
            name="section"
            control={control}
            label="Section"
            placeholder="Support Documents"
            options={reportSections}
            disabled={form.formState.isSubmitting}
          />

          <Box display="flex" flexDirection="column" gridGap="0.5rem">
            <FormInputLabel value="Width" />
            <FormInputCheckbox
              control={control}
              name="allowFullWidth"
              label="Allow Full Width (the report will scale to the size of the viewer's device)"
              disabled={form.formState.isSubmitting}
            />
            <Box
              display="flex"
              flexDirection="column"
              gridGap="0.5rem"
              className={classes.disabled}
            >
              <FormInputLabel value="Automated screenshots" />
              <Chip
                size="small"
                label="Coming soon"
                color="secondary"
                disabled
                variant="outlined"
                style={{ width: "fit-content" }}
              />
              <Typography variant="caption">
                Take a snapshot of the page every few hours
              </Typography>
              <FormInputCheckbox
                control={control}
                name="snapshots"
                label="Yes"
              />
            </Box>
          </Box>
        </div>
      </div>
      <Box
        display="flex"
        justifyContent={showBackButton ? "space-between" : "flex-end"}
        marginTop="1.5rem"
      >
        {showBackButton && (
          <FormButton
            value="Back"
            onClick={handleBackClick}
            disabled={form.formState.isSubmitting}
          />
        )}
        <FormButton
          value="Submit"
          disabled={submitDisabled}
          isLoading={form.formState.isSubmitting}
          isSubmit
        />
      </Box>
    </form>
  );
}

const useStyles = makeStyles(() => ({
  outerContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5.25rem 1fr",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
  },
  disabled: {
    opacity: "0.5",
    pointerEvents: "none",
  },
}));
