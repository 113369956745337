import React from "react";
import { Modal } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CreateReportCards } from "./CreateReportCards";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { CreateExternalLinkReport } from "../CreateReport/CreateExternalLinkReport";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { closeCreateReportModal } from "../../../../slices/createReportModalSlice";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { CreateMarkdownReport } from "../CreateReport/CreateMarkdownReport";
import { CreateOpenApiReport } from "../CreateReport/CreateOpenApiReport";
import { CreateIframeReport } from "../CreateReport/CreateIframeReport";
import Card from "@material-ui/core/Card";
import {
  getIframeFields,
  reportTemplates,
} from "../../../../shared/reportTemplates";
import { CreateReportTutorialCard } from "./CreateReportTutorialCard";
import { CreateReportModalHeader } from "./CreateReportModalHeader";

export function CreateReportModal() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const reportName = useAppSelector((state) => state.createReportModal.report);
  const editedReport = useAppSelector(
    (state) => state.createReportModal.editedReport
  );
  const open = useAppSelector((state) => state.createReportModal.open);

  const tutorial =
    reportName !== null &&
    reportName !== "iframe" &&
    reportName !== "link" &&
    reportTemplates[reportName] &&
    reportTemplates[reportName].supportDocs;
  const iframeFields = getIframeFields(reportName, editedReport);

  function onClose() {
    dispatch(closeCreateReportModal());
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.dialog}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          height: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          gridGap="1.5rem"
          alignItems="flex-start"
          width="100%"
        >
          {tutorial && <CreateReportTutorialCard {...tutorial} />}
          <Card className={classes.card}>
            <Grid className={classes.container}>
              {reportName === null && !editedReport && (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CreateReportModalHeader />
                  <Grid container>
                    <CreateReportCards />
                  </Grid>
                </Grid>
              )}
              {reportName === "link" && <CreateExternalLinkReport />}
              {reportName === "markdown" && <CreateMarkdownReport />}
              {reportName === "openapi-spec" && <CreateOpenApiReport />}
              {reportName !== null &&
                reportName !== "markdown" &&
                reportName !== "link" &&
                reportName !== "openapi-spec" && (
                  <CreateIframeReport fields={iframeFields} />
                )}
            </Grid>
          </Card>
        </Box>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  dialog: {
    width: "100%",
  },
  container: {
    padding: "1.5rem",
  },
  card: {
    width: "100%",
    maxWidth: "55.625rem",
  },
}));
