import React from "react";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import {
  IFrameReportFields,
  mapIframeFormData,
  IframeFormData,
  IframeFormValues,
} from "./form.types";
import { setCreateReportType } from "../../../../../slices/createReportModalSlice";
import { useForm } from "react-hook-form";
import { CreateIframeReportFormView } from "./CreateIframeReportFormView";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  applicationName: yup.string().required(),
  notes: yup.string().optional().default(""),
  publicUrl: yup.string().url().required(),
  section: yup.string().required(),
  allowFullWidth: yup.boolean().required(),
  public: yup.boolean().required(),
});

type CreateIframeReportFormProps = {
  fields: IFrameReportFields;
  defaultValues?: IframeFormValues;
  handleSubmit: (data: IframeFormData) => Promise<void>;
};
export function CreateIframeReportForm({
  defaultValues,
  handleSubmit,
  fields,
}: CreateIframeReportFormProps) {
  const dispatch = useAppDispatch();

  function onSubmit(data: IframeFormValues) {
    return handleSubmit(mapIframeFormData(data));
  }

  function handleBackClick() {
    dispatch(setCreateReportType(null));
  }

  const form = useForm<IframeFormValues>({
    mode: "onChange",
    defaultValues: defaultValues ?? {
      applicationName: "",
      notes: "",
      publicUrl: "",
      public: false,
      section: "Reports",
      allowFullWidth: true,
    },
    resolver: yupResolver(schema),
  });

  return (
    <CreateIframeReportFormView
      form={form}
      onSubmit={onSubmit}
      handleBackClick={handleBackClick}
      fields={fields}
    />
  );
}
