import { makeStyles, Theme, Modal, Button } from "@material-ui/core";
import React from "react";
import ShareReportModal from "../../../Modals/ShareReportModal";
import { Accounts, Reports } from "../../../shared/types";
import clsx from "clsx";
import PeopleIcon from "../Icons/PeopleIcon";
import LanguageIcon from "@material-ui/icons/Language";
import { updateReport } from "../../../features/report/api";
import { trackReportVisibility } from "../../../analytics";
import { getCurrentUser } from "../../../shared/api";

interface ShareModalUIProps {
  openShareReport: boolean;
  handleShareReportOpen: (report: Reports) => void;
  report: Reports | null;
  account: Accounts | null;
  handleShareReportStateChange: () => void;
  showButton: boolean;
  isAllowedToEdit: boolean;
}

export default function ShareModalUI({
  openShareReport,
  handleShareReportOpen,
  report,
  account,
  showButton = false,
  isAllowedToEdit = true,
  handleShareReportStateChange,
}: ShareModalUIProps) {
  const classes = useStyles({});

  const isPublicReport = account?.public || report?.public;
  const handleChange = (isPublic: boolean) => {
    updateReport(report?.id!, { ...report, public: isPublic }).then(
      (updatedReport) => {
        trackReportVisibility({
          reportId: updatedReport.id,
          reportType: updatedReport.type,
          userId: getCurrentUser()?.uid ?? "",
          accountId: account?.id ?? "",
          visible: updatedReport.public,
        });
        handleShareReportStateChange();
      }
    );
  };

  if (!report) {
    return <></>;
  }

  return (
    <>
      {showButton && (
        <Button
          onClick={() => handleShareReportOpen(report)}
          className={clsx(
            classes.ShareBtn,
            isPublicReport ? classes.ShareBtnPublic : ""
          )}
        >
          {isPublicReport ? <LanguageIcon /> : <PeopleIcon />}
          &nbsp;&nbsp;
          {isPublicReport ? "Shared" : "Share"}
        </Button>
      )}
      <Modal
        className={classes.shareModal}
        onClose={() => handleShareReportOpen(report)}
        open={openShareReport}
      >
        <ShareReportModal
          report={report}
          account={account}
          isSettings={true}
          setIsPublic={handleChange}
          isPublic={report.public}
          isAllowedToEdit={isAllowedToEdit}
        />
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  shareModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  ShareBtn: {
    textTransform: "none",
    color: "white",
    backgroundColor: "#3F62FF",
    height: "35px",
    margin: "0px 10px 0px 10px",
  },
  ShareBtnPublic: {
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#66bb6a",
    },
  },
}));
