import {
  trackAccountThemeUpdated,
  trackOrganizationThemeUpdated,
} from "../analytics";
import { getCurrentUser, getUserToken } from "../shared/api";
import { updateAccountPublic } from "../features/Accounts/api";
import {
  Accounts,
  AccountTheme,
  Organisations,
  Reports,
} from "../shared/types";
import Report from "../components/Report";
export const FETCH_ACCOUNTS_BEGIN = "FETCH_ACCOUNTS_BEGIN";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";
export const FETCH_ACCOUNT_FAILURE = "FETCH_ACCOUNT_FAILURE";

export const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS";
export const FETCH_DOMAIN_CONFIG_SUCCESS = "FETCH_DOMAIN_CONFIG_SUCCESS";
export const FETCH_REPORT_DATA_FEEDS_SUCCESS =
  "FETCH_REPORT_DATA_FEEDS_SUCCESS";
export const FETCH_ORG_THEME_SUCCESS = "FETCH_ORG_THEME_SUCCESS";

export const RESET_ACCOUNTS = "RESET_ACCOUNTS";

export const UPDATE_ACCOUNT_PUBLIC_BEGIN = "UPDATE_ACCOUNT_PUBLIC_BEGIN";

export const UPDATE_ACCOUNT_PUBLIC_SUCCESS = "UPDATE_ACCOUNT_PUBLIC_SUCCESS";

export const REPORT_CREATED = "REPORT_CREATED";
export const REPORT_UPDATED = "REPORT_UPDATED";

export const reportCreated = (report: Reports) => ({
  type: REPORT_CREATED,
  payload: report,
});

export function reportUpdated(report: Reports) {
  return (dispatch: any) => {
    getUserToken().then((token) => {
      dispatch(fetchAccount(token!, report.accountId));
    });
  };
}

export const updateAccountPublicBegin = () => ({
  type: UPDATE_ACCOUNT_PUBLIC_BEGIN,
});

export const updateAccountPublicSuccess = (items: { account: Accounts }) => ({
  type: UPDATE_ACCOUNT_PUBLIC_SUCCESS,
  payload: items.account,
});

export const fetchAccountsBegin = () => ({
  type: FETCH_ACCOUNTS_BEGIN,
});

export const fetchAccountsSuccess = (items: {
  accounts: Accounts;
  organisations: Organisations;
}) => ({
  type: FETCH_ACCOUNTS_SUCCESS,
  payload: items,
});

export const fetchAccountsFailure = (error: Error) => ({
  type: FETCH_ACCOUNTS_FAILURE,
  payload: { error },
});

export const fetchAccountFailure = (error: Error) => ({
  type: FETCH_ACCOUNT_FAILURE,
  payload: { error },
});

export const fetchAccountSuccess = (items: {
  id: string | undefined;
  account: Accounts;
}) => ({
  type: FETCH_ACCOUNT_SUCCESS,
  payload: items,
});

export const fetchReportDataFeedsSuccess = (items: {
  [x: string]: string;
}) => ({
  type: FETCH_REPORT_DATA_FEEDS_SUCCESS,
  payload: items,
});

export const fetchDomainConfigSuccess = (items: undefined) => ({
  type: FETCH_DOMAIN_CONFIG_SUCCESS,
  payload: items,
});

export const fetchThemeSuccess = (items: AccountTheme) => ({
  type: FETCH_ORG_THEME_SUCCESS,
  payload: items,
});

export const resetAccounts = () => ({
  type: RESET_ACCOUNTS,
});

// --  access logs
export const FETCH_ACCOUNT_ACCESS_LOGS_BEGIN =
  "FETCH_ACCOUNT_ACCESS_LOGS_BEGIN";
export const FETCH_ACCOUNT_ACCESS_LOGS_SUCCESS =
  "FETCH_ACCOUNT_ACCESS_LOGS_SUCCESS";
export const FETCH_ACCOUNT_ACCESS_LOGS_FAILURE =
  "FETCH_ACCOUNT_ACCESS_LOGS_FAILURE";

export const fetchAccountAccessLogsBegin = () => ({
  type: FETCH_ACCOUNT_ACCESS_LOGS_BEGIN,
});

export const fetchAccountAccessLogsSuccess = (items: undefined) => ({
  type: FETCH_ACCOUNT_ACCESS_LOGS_SUCCESS,
  payload: items,
});

export const fetchAccountAccessLogsFailure = (error: Error) => ({
  type: FETCH_ACCOUNT_ACCESS_LOGS_FAILURE,
  payload: { error },
});
// -- access logs

export function fetchDomainConfig() {
  return (
    dispatch: (arg0: { type: string; payload: string | undefined }) => void
  ) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/config/domain-theme`)
      .then((response) => response.json())
      .then((result) => {
        dispatch(fetchDomainConfigSuccess(result));
      })
      .catch();
  };
}

export function fetchAccounts(token: string) {
  return (
    dispatch: (arg0: {
      type: string;
      payload?:
        | { accounts: Accounts; organisations: Organisations }
        | { error: Error };
    }) => void
  ) => {
    dispatch(fetchAccountsBegin());
    const headers = { Authorization: "Bearer " + token };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/config`, { headers })
      .then((response) => response.json())
      .then(({ accounts, organisations }) => {
        dispatch(fetchAccountsSuccess({ accounts, organisations }));
      })
      .catch((error: Error) => dispatch(fetchAccountsFailure(error)));
  };
}

export function fetchAccount(token: string, id: string | undefined) {
  return (
    dispatch: (arg0: {
      type: string;
      payload?: { id: string | undefined; account: Accounts };
    }) => void
  ) => {
    dispatch(fetchAccountsBegin());
    const headers = { Authorization: "Bearer " + token };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/config/accounts/${id}`, {
      headers,
    })
      .then((response) => response.json())
      .then((account) => {
        dispatch(fetchAccountSuccess({ id, account }));
      })
      .catch((eroor) => console.log("error", eroor));
  };
}

export function fetchAccountAccessLogs(token: string, accountId: String) {
  return (
    dispatch: (arg0: { type: string; payload?: string | any }) => void
  ) => {
    dispatch(fetchAccountAccessLogsBegin());
    const headers = { Authorization: "Bearer " + token };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/accounts/${accountId}/access-log`,
      { headers }
    )
      .then((response) => response.json())
      .then((result) => {
        //if (result.length)
        dispatch(fetchAccountAccessLogsSuccess(result));
      })
      .catch((error) => dispatch(fetchAccountAccessLogsFailure(error)));
  };
}

export function fetchReportDataFeeds(token: string, id: string) {
  return (
    dispatch: (arg0: { type: string; payload: { [x: string]: string } }) => void
  ) => {
    const headers = { Authorization: "Bearer " + token };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports/${id}/data-feeds/`, {
      headers,
    })
      .then((response) => response.json())
      .then((feeds) => {
        dispatch(fetchReportDataFeedsSuccess({ [id]: feeds }));
      })
      .catch();
  };
}
export function updateReportType(
  token: string,
  rid: string,
  data: {
    public?: boolean;
    id?: string | undefined;
    type?: string | undefined;
    description?: string | undefined;
    url?: string | undefined;
    name?: string | undefined;
    width?: string | number | undefined;
    height?: string | number | undefined;
    sectionName?: string | undefined;
    body?: string;
  }
) {
  return (dispatch: (arg0: { type: string; payload?: string }) => void) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports/${rid}`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        // if (result) dispatch(fetchAccounts(token));
      });
  };
}
export function addReportPreviewImage(
  token: string,
  rid: string,
  data: FormData
) {
  return (dispatch: (arg0: { type: string; payload?: string }) => void) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      //"Content-Type": "application/json",
    };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/reports/${rid}/preview/upload`,
      {
        method: "POST",
        headers,
        body: data,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        
        // if (result)
        // dispatch(fetchOrganisationAccounts(token, data.organisationId));
      });
  };
}

export function fetchAccountTheme(token: string, aid: string | undefined) {
  return (
    dispatch: (arg0: { type: string; payload: AccountTheme }) => void
  ) => {
    const headers = { Authorization: "Bearer " + token };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/${aid}/theme`, {
      headers,
    })
      .then((response) => response.json())
      .then((theme) => {
        dispatch(fetchThemeSuccess(theme));
      })
      .catch();
  };
}

export function updateAccountTheme(
  token: string,
  aid: string | undefined,
  data: {
    navColour: string;
    colour1: string;
    colour2: string;
    hideTitle: boolean;
  }
) {
  return (
    dispatch: (
      arg0: (
        dispatch: (arg0: {
          type: string;
          payload?:
            | { accounts: Accounts; organisations: Organisations }
            | { error: Error }
            | string;
        }) => void
      ) => void
    ) => void
  ) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/${aid}/theme`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        trackAccountThemeUpdated(aid ?? "", getCurrentUser()?.uid ?? "");
        if (result) {
          dispatch(fetchAccounts(token));
        }
      });
  };
}

export function fetchOrgTheme(token: string, oid: string | undefined) {
  return (
    dispatch: (arg0: { type: string; payload: AccountTheme }) => void
  ) => {
    const headers = { Authorization: "Bearer " + token };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/organisations/${oid}/theme`, {
      headers,
    })
      .then((response) => response.json())
      .then((theme) => {
        dispatch(fetchThemeSuccess(theme));
      })
      .catch();
  };
}

export function updateOrgTheme(
  token: string,
  oid: string | undefined,
  data: {
    navColour: string;
    colour1: string;
    colour2: string;
    hideTitle: boolean;
  }
) {
  return (
    dispatch: (
      arg0: (
        dispatch: (arg0: {
          type: string;
          payload?:
            | { accounts: Accounts; organisations: Organisations }
            | { error: Error }
            | undefined;
        }) => void
      ) => void
    ) => void
  ) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/organisations/${oid}/theme`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        trackOrganizationThemeUpdated(oid ?? "", getCurrentUser()?.uid ?? "");
        if (result) {
          dispatch(fetchAccounts(token));
        }
      });
  };
}

export function updateAccountVisibility(accountId: string, isPublic: boolean) {
  return (dispatch: (arg0: { type: string; payload?: Accounts }) => void) => {
    dispatch(updateAccountPublicBegin());
    updateAccountPublic(accountId, isPublic).then((account) => {
      dispatch(updateAccountPublicSuccess({ account }));
    });
  };
}
