import { Accounts, Organisations } from "../../../shared/types";
import { useNavigate, useParams } from "react-router-dom";
import { Button, List, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { SettingsOutlined } from "@material-ui/icons";
import LanguageIcon from "@material-ui/icons/Language";
import { RootState } from "../../../store/store";
import { connect } from "react-redux";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

type DrawerHeaderProps = {
  account: Accounts | null;
  organisation: Organisations | null;
  organisationAccounts: Accounts[];
};
function _UIDrawerHeader({
  account,
  organisationAccounts,
  organisation,
}: DrawerHeaderProps) {
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles({ colour1: "" });
  return (
    <div className={classes.drawerHeader}>
      <List>
        <OrganisationsMenu
          organisationAccounts={organisationAccounts}
          account={account}
          organisation={organisation}
        />
        <Tooltip title="Account Settings">
          <Button
            startIcon={<SettingsOutlined />}
            onClick={() => navigate(`/account/${id}/settings`)}
            style={{
              margin: "0.5rem 0.1rem",
              width: "99%",
              display: "flex",
              justifyContent: "left",
              textTransform: "none",
              textAlign: "left",
            }}
            variant="text"
          >
            Settings and Users
          </Button>
        </Tooltip>
        {account && account.public && (
          <Tooltip title="Account Settings">
            <Button
              startIcon={<LanguageIcon />}
              target="_blank"
              href={`${window.location.origin}/view-account/${account.id}`}
              style={{
                margin: "0.5rem 0.1rem",
                width: "99%",
                display: "flex",
                justifyContent: "left",
                textTransform: "none",
                textAlign: "left",
              }}
              variant="text"
            >
              Visit Public Page
            </Button>
          </Tooltip>
        )}
      </List>
    </div>
  );
}

type OrganisationsMenuProps = {
  organisationAccounts: Accounts[];
  account: Accounts | null;
  organisation: Organisations | null;
};

function OrganisationsMenu({
  organisationAccounts,
  account,
  organisation,
}: OrganisationsMenuProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    if (organisationAccounts.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (id: string) => {
    setAnchorEl(null);
    //navigate if it's a page change request.
    if (typeof id === "string") {
      navigate(`/account/${id}`);
    }
  };

  if (!account) {
    return <></>;
  }

  return (
    <div>
      <Button
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          margin: "0.1rem 0.3rem",
          display: "flex",
          justifyContent: "left",
          textTransform: "none",
          fontWeight: "bold",
          textAlign: "left",
        }}
        variant="outlined"
      >
        {account.name}{" "}
        {account.public ? (
          <LanguageIcon
            style={{ marginLeft: "1rem", color: "#4caf50" }}
            fontSize="small"
          />
        ) : (
          <LockIcon
            style={{ marginLeft: "1rem" }}
            color="disabled"
            fontSize="small"
          />
        )}
      </Button>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {organisationAccounts &&
          organisationAccounts.map((accnt) => (
            <MenuItem
              onClick={(e) => handleClose(accnt.id)}
              style={{ fontWeight: accnt.id === account.id ? "bold" : "" }}
              key={accnt.id}
            >
              {accnt.name}{" "}
              {accnt.public ? (
                <LanguageIcon
                  style={{ marginLeft: "1rem", color: "#4caf50" }}
                  fontSize="small"
                />
              ) : (
                <LockIcon
                  style={{ marginLeft: "1rem" }}
                  color="disabled"
                  fontSize="small"
                />
              )}
            </MenuItem>
          ))}
        <MenuItem
          onClick={() =>
            navigate(`/organisation/${organisation?.id}?setting=accounts`)
          }
        >
          <Grid container alignItems="center">
            <Typography style={{ fontWeight: 700 }}>Add new account</Typography>
            <AddIcon
              style={{ width: "1rem", height: "1rem", marginLeft: "0.5rem" }}
            />
          </Grid>
        </MenuItem>
      </Menu>
    </div>
  );
}
const mapHeaderStateToProps = (state: RootState) => ({
  account: state.selected.account,
  organisation: state.selected.organisation,
  organisationAccounts: state.users.organisationAccounts,
});

const useStyles = makeStyles(() => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    margin: "0.625rem 0.0625rem 0 0.0625rem",
    justifyContent: "center",
  },
}));

export const UIDrawerHeader = connect(mapHeaderStateToProps)(_UIDrawerHeader);
