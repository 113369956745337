import React from "react";
import { useDispatch } from "react-redux";
import firebase from "firebase";
import { updateReportType } from "../../actions/accountActions";
import { Button } from "@material-ui/core";
import MDEditor from "@uiw/react-md-editor";
import { IReportFrameProps } from "./ReportFrame";
import { makeStyles } from "@material-ui/core/styles";

export function MarkDownReportFrame({ report, account }: IReportFrameProps) {
  const [viewEdit, setViewEdit] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(report.body);
  const [editButtonStyle, setEditButtonStyle] =
    React.useState<React.CSSProperties>({
      margin: "0 0 1.3% 78%",
    });
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentUser = firebase.auth().currentUser;

  React.useEffect(() => {
    setValue(report.body);
  }, [report.body]);

  const openMarkDownEditor = () => {
    setEditButtonStyle({ display: "none" });
    setViewEdit(true);
  };

  const cancelMarkDown = () => {
    setViewEdit(false);
    setEditButtonStyle({
      margin: "0 0 1.3% 78%",
    });
  };

  const changeValue = (data: string | undefined) => {
    setValue(data);
  };

  const saveData = () => {
    if (currentUser) {
      firebase
        .auth()
        .currentUser!.getIdToken()
        .then((token) => {
          dispatch(
            updateReportType(token, report.id, { ...report, body: value })
          );
        });
    }
    setViewEdit(false);
    setEditButtonStyle({
      margin: "0 0 1.3% 78%",
    });
  };

  return (
    <div data-color-mode="light" className={classes.container}>
      {viewEdit && (
        <>
          <div className={classes.markDownButtons}>
            <Button
              variant="contained"
              color={"primary"}
              onClick={cancelMarkDown}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.saveButton}
              color={"primary"}
              onClick={() => saveData()}
            >
              Save Changes
            </Button>
          </div>
        </>
      )}

      {account?.editor && (
        <Button
          color={"primary"}
          variant="contained"
          disabled={viewEdit}
          style={editButtonStyle}
          onClick={openMarkDownEditor}
        >
          Edit Page
        </Button>
      )}

      <div className="wmde-markdown-var"></div>
      {viewEdit ? (
        <>
          <MDEditor
            height={600}
            value={value}
            onChange={(e) => changeValue(e)}
          />
        </>
      ) : (
        <MDEditor.Markdown
          style={{ padding: 15 }}
          source={value}
          linkTarget="_blank"
        />
      )}
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
  },
  markDownButtons: {
    margin: "0% 0% 1.5% 68%",
  },
  saveButton: {
    margin: "0 0 0 3%",
  },
});
