import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Header from "../../Header";

import Quote from "../../Quote";
import { CircularProgress } from "@material-ui/core";
import ReportPreviewGridPublic from "../../ReportPreviewGridPublic";
import ReportPreviewTablePublic from "../../ReportPreviewTablePublic";
import { getAccount } from "../Accounts/api";
import { PublicAccount } from "../Accounts/types";
import { DashboardPreviewButtons } from "./DashboardPreviewButtons";
import { PreviewReportsLayout } from "./types";
import { DashboardDrawer, DashboardDrawerReports } from "./Drawer";
import Report from "../../components/Report";
import clsx from "clsx";
import { Reports } from "../../shared/types";
import { Footer } from "./Footer";
import { trackPage, trackReportReferral } from "../../analytics";
import { Alert } from "@material-ui/lab";

const drawerWidth = 240;

const PublicDashboard = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<PublicAccount | null>(null);
  const [selectedReport, setSelectedReport] = useState<Reports | undefined>(
    undefined
  );
  const [error, setError] = useState<Error>(null);
  const [open, setOpen] = useState<boolean>(true);
  const [previewReportsLayout, setPreviewReportsLayout] =
    useState<PreviewReportsLayout>("grid");

  const navigate = useNavigate();
  const location = useLocation();
  const { accountId } = useParams();

  const fetchButtonName = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button: HTMLButtonElement = event.currentTarget;
    setPreviewReportsLayout(button.name.toLowerCase() as PreviewReportsLayout);
  };

  const handleSelectReport = (report: Reports) => {
    if (report.type === "link") {
      return;
    }

    const query = new URLSearchParams(location.search);
    if (report) {
      query.set("report", report.id);
    } else {
      query.set("", "");
    }

    navigate({
      pathname: location.pathname,
      search: `?${query.toString()}`,
    });
    setSelectedReport(report);
  };

  const setEmptyReport = () => {
    const defaultReport = account?.reports.find(
      (r) => r.id == account.defaultReportId
    );
    if (!defaultReport) {
      const query = new URLSearchParams(location.search);
      query.delete("report");
      navigate({
        pathname: location.pathname,
        search: `?${query.toString()}`,
      });
    } else {
      //@ts-ignore
      setSelectedReport(defaultReport);
    }
  };

  function trackReportReferralCallback() {
    trackReportReferral({
      reportId: selectedReport?.id,
      reportType: selectedReport?.type,
      accountId: selectedReport?.accountId!,
    });
  }

  useEffect(() => {
    if (accountId) {
      getAccount(accountId)
        .then((account) => {
          setAccount(account);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } else {
      navigate("/login");
    }
  }, [accountId]);

  useEffect(() => {
    if (account?.defaultReportId) {
      handleSelectReport(
        account.reports.find((report) => report.id == account.defaultReportId)!
      );
    }
  }, [account]);

  // todo: refactor later after merging RW-65
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("report") && !selectedReport) {
      return;
    }
    if (selectedReport) {
      document.title = "Report - " + selectedReport.name;
    } else {
      document.title = "Reports";
    }
    if (!loading) {
      trackPage(document.title);
    }
  }, [selectedReport?.id, loading]);

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
        <Quote />
      </div>
    );
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Header
        account={account}
        open={open}
        isDashboard={true}
        handleDrawerOpen={setOpen}
        setEmptyReport={setEmptyReport}
        AvtarOption={false}
      />

      <DashboardDrawer
        open={open}
        Reports={
          <DashboardDrawerReports
            reports={account?.reports ?? []}
            handleSelectReport={handleSelectReport}
            selectedReport={selectedReport}
          />
        }
      />
      <Grid
        container
        direction="column"
        style={{
          width: "100%",
          marginRight: "16px",
          marginTop: "64px",
          marginBottom: "64px",
        }}
      >
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {selectedReport && (
            <Report
              account={account}
              reportTab="0"
              report={selectedReport}
              open={open}
            />
          )}
          {!selectedReport && (
            <>
              <div className={classes.dashboardHeader}>
                <DashboardPreviewButtons
                  dashboardType={previewReportsLayout}
                  onClick={fetchButtonName}
                />
              </div>
              {previewReportsLayout === "grid" ? (
                <ReportPreviewGridPublic
                  account={account}
                  handleSelectReport={handleSelectReport}
                />
              ) : (
                <ReportPreviewTablePublic
                  account={account}
                  handleSelectReport={handleSelectReport}
                />
              )}
            </>
          )}
        </main>
      </Grid>
      <Footer
        style={{ width: open ? `calc(100vw - ${drawerWidth}px)` : "100%" }}
        onOpenCallback={trackReportReferralCallback}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    padding: "2.5625rem 0",
  },
  loader: {
    marginTop: theme.spacing(25, 0),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  dashboardHeader: {
    marginBottom: theme.spacing(2),
  },
  content: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    padding: "12px 12px 0 12px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
  },
}));

export default PublicDashboard;
