import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Quote from "../../../Quote";
import { CircularProgress } from "@material-ui/core";

import FSReport from "../../../components/FSReport";
import { getPublicReport, getTheme } from "../api";
import {
  trackPage,
  trackReportReferral,
  trackReportViewed,
} from "../../../analytics";
import { Footer } from "../../PublicDashboard/Footer";

type Theme = {
  colour1: string;
  colour2: string;
  hideTitle: boolean;
  logoUrl: string;
  navColour: string;
  accountName: string;
  organisationName: string;
};
type Reports = {
  id: string;
  type: string;
  description: string;
  url: string;
  name: string;
  width: string | undefined | number;
  height: string | undefined | number;
  sectionName: string;
  body?: string;
  iframe?: string | any;
  public?: boolean;
  accountId?: string;
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    paddingRight: 24,
    maxHeight: 40,
  },
  title: {
    flexGrow: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  container: {
    marginTop: "20px",
  },
  loader: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  hideFooter: {
    position: "absolute",
    width: "100%",
    height: "26px",
    background: "#fafafa",
    bottom: 0,
  },
  main: {
    width: "100%",
  },
}));

const PublicReport = () => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Reports | null>(null);
  const [accountTheme, setAccountTheme] = useState<Theme | null>(null);

  //get the report id from the url
  const { reportId } = useParams();

  useEffect(() => {
    if (selectedReport) {
      document.title = "Report - " + selectedReport.name;
      trackPage(document.title);
    }
  }, [selectedReport]);

  const showReport = () => {
    setIsLoaded(true);
    if (selectedReport) {
      trackReportViewed({
        reportId: selectedReport.id,
        reportType: selectedReport.type,
        reportPublic: "public",
      });
    }
  };

  function trackReportReferralCallback() {
    trackReportReferral({
      reportId: selectedReport?.id,
      reportType: selectedReport?.type,
      accountId: selectedReport?.accountId!,
    });
  }

  useEffect(() => {
    getPublicReport(reportId as string).then((result) => {
      if (result) setSelectedReport(result);
    });
    return;
  }, []);

  //wait unit we get the report to load the them.
  useEffect(() => {
    if (!selectedReport) {
      return;
    }

    if (selectedReport?.accountId) {
      getTheme(selectedReport.accountId).then((result) => {
        if (result) {
          setAccountTheme(result);
          showReport();
        }
      });
    }
    return;
  }, [selectedReport]);

  if (!isLoaded || !accountTheme?.logoUrl) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
        <Quote />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <>
        <div className={classes.paper}>
          <AppBar
            style={{ background: accountTheme?.navColour }}
            position="fixed"
          >
            <Toolbar>
              <img
                src={accountTheme?.logoUrl}
                alt="logo"
                className={classes.logo}
              />

              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {accountTheme?.accountName} : {selectedReport?.name}
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <main className={classes.main}>
          <div className={classes.drawerHeader} />
          {!isLoaded ? (
            <LinearProgress className="loading text-center" />
          ) : null}
          {!!selectedReport && (
            <FSReport reportTab="0" report={selectedReport} showTitle={false} />
          )}
        </main>
      </>
      <Footer onOpenCallback={trackReportReferralCallback} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    domainConfig: state.accounts.domainConfig,
    accounts: state.accounts.accounts,
    organisations: state.accounts.organisations,
    reports: state.accounts.reports,
    loading: false,
    error: state.accounts.error,
  };
};

export default connect(mapStateToProps)(PublicReport);
