import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Accounts, Reports } from "./shared/types";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    height: 320,
    width: 350,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

interface IReportPreviewGrid {
  account: Accounts | null;
  handleSelectReport: (report: Reports) => void;
}
export default function ReportPreviewGrid(props: IReportPreviewGrid) {
  const classes = useStyles();
  const { account, handleSelectReport } = props;

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        {account?.sections.map((section, s) => (
          <Grid key={section.name} container spacing={2}>
            {section.reports.map((report) => (
              <Grid key={report.id} item>
                <Card
                  className={classes.card}
                  onClick={() => handleSelectReport(report)}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={report.name}
                      height="200"
                      image={report.previewUrl}
                      title={report.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {report.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    {report.type === "link" ? (
                      <Button
                        component="a"
                        href={report.url.replace(
                          /^(?:(.*:)?\/\/)?(.*)/i,
                          (match, schemma, nonSchemmaUrl) =>
                            schemma ? match : `http://${nonSchemmaUrl}`
                        )}
                        target="_blank"
                        size="small"
                        color="primary"
                      >
                        view
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => props.handleSelectReport(report)}
                      >
                        view
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
