type PlanFeatures = {
  accounts: number;
};

type PlanType = "free" | "starter" | "pro" | "enterprise" | "canary";

export const plans: Record<PlanType, PlanFeatures> = {
  free: {
    accounts: 1,
  },
  starter: {
    accounts: 2,
  },
  pro: {
    accounts: 10,
  },
  enterprise: {
    accounts: 10,
  },
  canary: {
    accounts: 9999,
  },
};

export function canDo({
  feature,
  currentSize,
  plan,
}: {
  plan: PlanType;
  feature: keyof PlanFeatures;
  currentSize: number;
}) {
  return currentSize < plans[plan][feature];
}
