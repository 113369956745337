import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import FSReport from "./components/FSReport";
import { trackPage, trackReportViewed } from "./analytics";
import { getReport } from "./features/report/api";
import { Reports } from "./shared/types";

const EmbeddedReport = () => {
  const [loadingIFrame, setLoadingIFrame] = useState(true);
  const [selectedReport, setSelectedReport] = useState<Reports | null>(null);

  const { reportId } = useParams();
  const showReport = (rData: Reports) => {
    trackReportViewed({
      reportId: rData.id,
      reportType: rData.type,
      reportPublic: "embedded",
    });
    setSelectedReport(rData);
    setLoadingIFrame(false);
  };

  useEffect(() => {
    async function fetchReport() {
      if (reportId) {
        getReport(reportId).then(showReport);
      }
    }
    fetchReport();
  }, []);

  useEffect(() => {
    if (selectedReport) {
      document.title = "Report - " + selectedReport.name;
      trackPage(document.title);
    }
  }, [selectedReport]);

  if (loadingIFrame) {
    return (
      <>
        <CssBaseline />
        <LinearProgress className="loading text-center" />
      </>
    );
  }

  return (
    <div>
      <CssBaseline />
      <FSReport reportTab="0" report={selectedReport} embed={true} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    domainConfig: state.accounts.domainConfig,
    accounts: state.accounts.accounts,
    organisations: state.accounts.organisations,
    reports: state.accounts.reports,
    loading: false,
    error: state.accounts.error,
  };
};

export default connect(mapStateToProps)(EmbeddedReport);
