import {
  FETCH_SUBSCRIPTION_OPTIONS_BEGIN,
  FETCH_SUBSCRIPTION_OPTIONS_SUCCESS,
  FETCH_SUBSCRIPTION_OPTIONS_FAILURE,
} from "../actions/subscriptionActions";

const initialState = {
  organisationSubscription: null,
  manageBillingUrl: null,
  subscriptionOptions: [],
  loading: true,
  error: null,
};

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_OPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SUBSCRIPTION_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionOptions: action.payload.options,
        organisationSubscription: action.payload.currentSubscription,
        manageBillingUrl: action.payload.manageBillingUrl,
      };

    case FETCH_SUBSCRIPTION_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subscriptionOptions: [],
        organisationSubscription: null,
        manageBillingUrl: null,
      };

    default:
      return state;
  }
}
