import { isArray } from "lodash";
import { setAccount } from "../slices/selectedSlice";

export const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_INVITATION_BEGIN = "FETCH_USERS_INVITATION_BEGIN";
export const FETCH_USER_INVITATION_SUCCESS = "FETCH_USER_INVITATION_SUCCESS";

export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const FETCH_ORGANISATION_ACCOUNTS_SUCCESS =
  "FETCH_ORGANISATION_ACCOUNTS_SUCCESS";

export const FETCH_ORGANISATION_ACCOUNTS_BEGIN =
  "FETCH_ORGANISATION_ACCOUNTS_BEGIN";

export const SET_LOADING = "SET_LOADING";
export const FETCH_EMAIL_TEMPLATES_SUCCESS = "FETCH_EMAIL_TEMPLATES_SUCCESS";
export const EDIT_EMAIL_TEMPLATE_SUCCESS = "EDIT_EMAIL_TEMPLATE_SUCCESS";
export const EDIT_EMAIL_TEMPLATE_FAILURE = "EDIT_EMAIL_TEMPLATE_FAILURE";
export const RESET_EMAIL_TEMPLATES = "RESET_EMAIL_TEMPLATES";

export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_ORGANISATION_USER_ROLE_SUCCESS";

export const UPDATE_USER_ROLE_FAILURE = "UPDATE_ORGANISATION_USER_ROLE_FAILURE";

export interface UserProps {
  uid: string;
  name: string;
  email: string;
  lastLoginAt: string;
  verified: boolean;
  status: string;
}

export interface EmailTemplateProps {
  type: string;
  subject: string;
  body: string;
  organisationId: string;
}

export interface OrganisationAccountsProps {
  id: string;
  name: string;
  theme: Array<{ logoUrl: string; navColor: string; colour1: string }>;
  reports: [];
}

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN,
});

export const fetchUsersSuccess = (items: UserProps) => ({
  type: FETCH_USERS_SUCCESS,
  payload: items,
});

export const fetchUserInvitationBegin = () => ({
  type: FETCH_USERS_INVITATION_BEGIN,
  payload: {},
});

export const fetchUserInvitationSuccess = (items: undefined) => ({
  type: FETCH_USER_INVITATION_SUCCESS,
  payload: items,
});

export const fetchOrganisationAccountsBegin = () => ({
  type: FETCH_ORGANISATION_ACCOUNTS_BEGIN,
});

export const fetchOrganisationAccountsSuccess = (
  items: OrganisationAccountsProps
) => ({
  type: FETCH_ORGANISATION_ACCOUNTS_SUCCESS,
  payload: items,
});

export const fetchEmailTemplatesSuccess = (items: EmailTemplateProps) => ({
  type: FETCH_EMAIL_TEMPLATES_SUCCESS,
  payload: items,
});

export const removeUserSuccess = (items: string) => ({
  type: REMOVE_USER_SUCCESS,
  payload: items,
});

export const setLoading = (value: boolean) => ({
  type: SET_LOADING,
  value,
});

export const resetEmailTemplates = (value: EmailTemplateProps) => ({
  type: RESET_EMAIL_TEMPLATES,
  value,
});

export const editEmailTemplateFailure = (error: { error: Error }) => ({
  type: EDIT_EMAIL_TEMPLATE_FAILURE,
  payload: error,
});

export const editEmailTemplateSuccess = () => ({
  type: EDIT_EMAIL_TEMPLATE_SUCCESS,
});

export const updateUserRoleSuccess = (items: any) => ({
  type: UPDATE_USER_ROLE_SUCCESS,
  payload: items,
});

export const updateUserRoleFailure = (error: Error) => ({
  type: UPDATE_USER_ROLE_FAILURE,
  payload: error,
});

export function fetchUsers(
  token: string,
  isSettings: boolean,
  id: string | undefined
) {
  return (dispatch: (arg0: { type: string; payload?: UserProps }) => void) => {
    dispatch(fetchUsersBegin());
    let fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/users/?organisationId=`;
    if (isSettings) {
      fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/users/?accountId=`;
    }
    const headers = { Authorization: "Bearer " + token };
    fetch(fetchUrl + id, { headers })
      .then((response) => response.json())
      .then((result) => {
        if (result.length) dispatch(fetchUsersSuccess(result));
      });
  };
}

export function fetchUserInvitation(token: string, id: string) {
  return (
    dispatch: (arg0: { type: string; payload: string | any }) => void
  ) => {
    dispatch(fetchUserInvitationBegin());
    let fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/users/${id}/invitation`;
    const headers = { Authorization: "Bearer " + token };
    fetch(fetchUrl, { headers })
      .then((response) => response.json())
      .then((result) => {
        dispatch(fetchUserInvitationSuccess(result));
      });
  };
}

export function removeUser(
  token: string,
  isSettings: boolean,
  id: string | undefined,
  uid: string
) {
  return (dispatch: (arg0: { type: string; payload: string }) => void) => {
    const headers = { Authorization: "Bearer " + token };
    let fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/organisations/${id}/users/`;
    if (isSettings) {
      fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/accounts/${id}/users/`;
    }
    fetch(fetchUrl + uid, {
      method: "DELETE",
      headers,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) dispatch(removeUserSuccess(uid));
      });
  };
}

export function updateOrganisationUserRole(
  token: string,
  userId: string,
  organisationId: string,
  role: string
) {
  return (
    dispatch: (arg0: {
      type: string;
      payload: OrganisationAccountsProps | Error;
    }) => void
  ) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/organisations/${organisationId}/users/${userId}`,
      {
        headers,
        method: "PATCH",
        body: JSON.stringify({ role }),
      }
    ).then((response) => {
      if (response.status === 200) {
        dispatch(updateUserRoleSuccess({ userId, role }));
      } else {
        dispatch(updateUserRoleFailure(new Error("Failed to update role")));
      }
    });
  };
}

export function updateAccountUserRole(
  token: string,
  userId: string,
  accountId: string,
  role: string
) {
  return (
    dispatch: (arg0: {
      type: string;
      payload: OrganisationAccountsProps | Error;
    }) => void
  ) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/accounts/${accountId}/users/${userId}`,
      {
        headers,
        method: "PATCH",
        body: JSON.stringify({ role }),
      }
    ).then((response) => {
      if (response.status === 200) {
        dispatch(updateUserRoleSuccess({ userId, role }));
      } else {
        dispatch(updateUserRoleFailure(new Error("Failed to update role")));
      }
    });
  };
}

export function inviteUser(
  token: string,
  isSettings: boolean,
  id: string | undefined,
  email: string | undefined[]
) {
  return (
    dispatch: (arg0: {
      (
        dispatch: (arg0: {
          type: string;
          payload?: UserProps | undefined;
        }) => void
      ): void;
      type?: string;
      value?: boolean;
    }) => void
  ) => {
    dispatch((value) => setLoading(true));
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    let fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/organisations/${id}/invite/`;
    if (isSettings) {
      fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/accounts/${id}/invite/`;
    }
    let request;
    if (isArray(email)) {
      request = Promise.all(
        email.map((em) =>
          fetch(fetchUrl, {
            method: "POST",
            headers,
            body: JSON.stringify({ email: em }),
          })
        )
      );
    } else {
      request = fetch(fetchUrl, {
        method: "POST",
        headers,
        body: JSON.stringify({ email: email }),
      });
    }

    request
      .then(() => {
        dispatch(fetchUsers(token, isSettings, id));
      })
      .finally(() => dispatch((value) => setLoading(false)));
  };
}

export function fetchOrganisationAccounts(
  token: string,
  id: string | undefined
) {
  return (
    dispatch: (arg0: {
      type: string;
      payload?: OrganisationAccountsProps;
    }) => void
  ) => {
    if(id===undefined) return
    dispatch(fetchOrganisationAccountsBegin());
    const headers = { Authorization: "Bearer " + token };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/accounts/?organisationId=` + id,
      { headers }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.length) dispatch(fetchOrganisationAccountsSuccess(result));
      });
  };
}

export function fetchEmailTemplates(token: string, orgId: string | undefined) {
  return (
    dispatch: (arg0: { type: string; payload: EmailTemplateProps }) => void
  ) => {
    const headers = { Authorization: "Bearer " + token };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/organisations/${orgId}/email-templates`,
      { headers }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.length) dispatch(fetchEmailTemplatesSuccess(result));
      });
  };
}

export function editEmailTemplate(
  token: string,
  orgId: string | undefined,
  data: { type: string }
) {
  return (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch((value: EmailTemplateProps) => resetEmailTemplates(value));
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/organisations/${orgId}/email-templates/${data.type}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          dispatch(editEmailTemplateFailure({ error: result.message[0] }));
        } else {
          dispatch(editEmailTemplateSuccess());
          dispatch(fetchEmailTemplates(token, orgId));
        }
      })
      .catch((error) => dispatch(editEmailTemplateFailure(error)))
      .finally(() => dispatch(setLoading(false)));
  };
}

export function addOrganisationAccount(
  token: string,
  data: { organisationId: string | undefined }
) {
  return (
    dispatch: (
      arg0: (
        dispatch: (arg0: {
          type: string;
          payload: OrganisationAccountsProps;
        }) => void
      ) => void
    ) => void
  ) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          dispatch(fetchOrganisationAccounts(token, data.organisationId));
        }
        return result;
      });
  };
}

export function editOrganisationAccount(
  token: string,
  id: string,
  data: { organisationId: string | undefined }
) {
  return (
    dispatch: (
      arg0: (
        dispatch: (arg0: {
          type: string;
          payload: OrganisationAccountsProps;
        }) => void
      ) => void
    ) => void
  ) => {
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/` + id, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result)
          dispatch(fetchOrganisationAccounts(token, data.organisationId));
      });
  };
}

export function archiveOrganisationAccount(token: string, id: string) {
  return (
    dispatch: (
      arg0: (
        dispatch: (arg0: {
          type: string;
          payload: OrganisationAccountsProps;
        }) => void
      ) => void
    ) => void
  ) => {
    const headers = { Authorization: "Bearer " + token };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/` + id, {
      method: "PATCH",
      headers,
      body: JSON.stringify({ archived: true }),
    })
      .then((response) => response.json())
      .then((result) => {});
  };
}
