import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "./firebase";
import { Alert } from "@material-ui/lab";
import { AlertTitle } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import firebase from "firebase";

import { useNavigate } from "react-router-dom";
import Quote from "./Quote";
import { UserContext } from "./user-provider";
import { useDispatch, connect } from "react-redux";
import { fetchDomainConfig } from "./actions/accountActions";
import { Link } from "react-router-dom";
import { trackNewUserSignup, trackPage, trackUserSignIn } from "./analytics";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    paddingRight: 24,
    maxHeight: 40,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  loader: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = ({ domainConfig, isSignup = false, accountsLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const code = useQuery().get("code") || localStorage.getItem("code");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [signUp, setSignUp] = useState(useQuery().get("isSignup") || !!code);
  const classes = useStyles();

  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  const { user } = useContext(UserContext);

  useEffect(() => {
    dispatch(fetchDomainConfig());
  }, [dispatch]);

  // prevent user from signing up if they're invited with a code
  useEffect(() => {
    if (code) {
      localStorage.setItem("code", code);
    }
  }, []);

  useEffect(() => {
    if (isSignup) {
      document.title = "Sign up";
    } else {
      document.title = "Login";
    }
    trackPage(document.title);
  }, [isSignup]);

  // if we navigate from here, it works for login and signup
  // but it redirects to / before /accept-invitation responds
  // and the user will have no accounts and gets redirected to /new-organisation

  // if we navigate in handleForm, we navigate before user-provider state updates
  // and PrivateRoute redirect us back to /login
  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      navigate(from);
    }
  }, [user, loading]);

  if (domainConfig?.skipLogin && domainConfig?.defaultAccountId) {
    navigate(`/view-account/${domainConfig.defaultAccountId}`);
  }

  const handleTypeChange = () => {
    if (isSignup) {
      navigate("/login");
    } else {
      navigate("/signup");
    }
  };

  const onTermsSelectionChanged = ({ target }) => {
    console.log(target);
    setTermsAccepted(target.checked);
  };

  function handleLogin() {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        if (res.user) {
          if (!isSignup) {
            trackUserSignIn(res.user);
          } else {
            trackNewUserSignup(res.user);
          }
          localStorage.setItem("app_user_token", res.user.uid);
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrors(e.message);
        setLoading(false);
      });
  }

  function handleSignup() {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        if (code) {
          res.user.getIdToken().then((token) => {
            trackNewUserSignup(res.user);
            localStorage.setItem("app_user_token", res.user.uid);
            // dispatch(acceptInvitation(token, code));
            const headers = {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            };
            fetch(
              `${process.env.REACT_APP_API_ENDPOINT}/users/accept-invitation`,
              {
                method: "POST",
                headers,
                body: JSON.stringify({ code: code }),
              }
            ).then((result) => {
              // navigate(from);
              localStorage.removeItem("code");
              setLoading(false);
            });
          });
        } else {
          setLoading(false);
          navigate(from);
        }
      })
      .catch((e) => {
        setErrors(e.message);
        setLoading(false);
      });
  }

  const handleForm = (event) => {
    event.preventDefault();
    setLoading(true);
    if (isSignup) {
      handleSignup();
    } else {
      handleLogin();
    }
  };

  const loginWithGoogle = () => {
    setLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    auth
      .signInWithPopup(provider)
      .then(function (result) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            if (!isSignup) {
              trackUserSignIn(result.user);
            } else {
              trackNewUserSignup(result.user);
            }
            localStorage.setItem("app_user_token", token);
            setLoading(false);
            if (!code) {
              return;
            }
            const headers = {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            };

            fetch(
              `${process.env.REACT_APP_API_ENDPOINT}/users/accept-invitation`,
              {
                method: "POST",
                headers,
                body: JSON.stringify({ code: code }),
              }
            ).then(() => {
              navigate(from);
            });
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {loading || !domainConfig?.logoUrl ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.paper}>
          <AppBar
            style={{ background: domainConfig?.navColour }}
            position="fixed"
          >
            <Toolbar>
              <img
                src={domainConfig?.logoUrl}
                alt="logo"
                className={classes.logo}
              />

              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              ></Typography>
            </Toolbar>
          </AppBar>

          {Boolean(code) ? (
            <>
              <Alert severity="warning">
                <AlertTitle>Important!</AlertTitle>
                Please ensure you signup with the email address you received the
                invite with.
              </Alert>
              <TextField
                value={code}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Invite Code"
                name="email"
                autoFocus
              />
            </>
          ) : (
            ""
          )}
          <br />
          <Button
            onClick={loginWithGoogle}
            className="googleBtn"
            fullWidth
            variant="outlined"
            startIcon={
              <Avatar
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                }
                className={classes.small}
              />
            }
          >
            {!!code || isSignup ? "Signup with Google" : "Log in with Google"}
          </Button>
          <br />
          <Typography align="center" component="h2" variant="h5">
            - or -
          </Typography>
          <form onSubmit={(e) => handleForm(e)}>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="Password"
            />
            {isSignup ? (
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" />}
                label={
                  <div>
                    <span>I accept the </span>
                    <a
                      href={"https://reportwrapper.com/terms-and-conditions/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms of use
                    </a>
                    <span> and </span>
                    <a
                      href={"https://reportwrapper.com/privacy-policy/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy policy
                    </a>
                  </div>
                }
                labelPlacement="end"
                checked={termsAccepted}
                onChange={onTermsSelectionChanged}
              />
            ) : (
              <br />
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
              disabled={isSignup && !termsAccepted}
            >
              {isSignup ? "Signup" : "Login"}
            </Button>
            <Link
              to={"/resetpassword"}
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "10px",
              }}
            >
              Forgot Password?
            </Link>
            <Typography component="span" color="secondary">
              {error}
            </Typography>

            <Divider />
            {domainConfig.allowSignup ? (
              <Button
                fullWidth
                color="primary"
                className={classes.submit}
                onClick={() => handleTypeChange()}
              >
                {isSignup ? "Login Instead" : "Signup Instead"}
              </Button>
            ) : (
              ""
            )}
          </form>
          <br></br>
          <Quote />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    domainConfig: state.accounts.domainConfig,
    accountsLoading: state.accounts.loading,
  };
};

export default connect(mapStateToProps)(Login);
