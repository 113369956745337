import { Reports, ReportType } from "./types";
import { ReportCardType } from "../features/report/types";

type Field = {
  text: string;
  placeholder?: string;
  docsUrl?: string;
};

type Fields = {
  logo: string;
  name: Field;
  description: Field;
  url: Field;
  slug: ReportType;
};

export type ReportTemplateTypes = Exclude<ReportType, "iframe" | "link">;

export type ReportTemplateValues = {
  slug: string;
  integrationType: {
    formType: string;
    type: ReportCardType;
    fields?: Fields;
  };
  organization: {
    name: string;
    url: string;
    supportUrl: string;
    branding: {
      logo: string;
    };
  };
  supportDocs?: {
    url: string;
    video: string;
  };
};

export type ReportTemplate = Record<ReportTemplateTypes, ReportTemplateValues>;

export const reportTemplates: ReportTemplate = {
  markdown: {
    slug: "markdown",
    integrationType: {
      formType: "markdown",
      type: "documentation",
    },
    organization: {
      name: "Markdown",
      url: "https://www.markdownguide.org/",
      supportUrl: "https://www.markdownguide.org/",
      branding: {
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Markdown-mark.svg/1920px-Markdown-mark.svg.png",
      },
    },
  },
  "openapi-spec": {
    slug: "openapi",
    integrationType: {
      formType: "openapi",
      type: "documentation",
    },
    organization: {
      name: "OpenAPI",
      url: "https://swagger.io/specification/",
      supportUrl: "https://swagger.io/specification/",
      branding: {
        logo: "https://www.openapis.org/wp-content/uploads/sites/3/2018/02/OpenAPI_Logo_Pantone-1.png",
      },
    },
  },
  loom: {
    slug: "loom",
    integrationType: {
      formType: "loom",
      type: "documentation",
      fields: {
        logo: "https://bpb-ap-se2.wpmucdn.com/global2.vic.edu.au/dist/8/5256/files/2019/04/Loom-logo-new-2020-1024x309.png",
        name: {
          text: "Report Title",
          placeholder: "Loom video title",
        },
        description: {
          text: "Notes",
          placeholder: "How to add a report to Report Wrapper",
        },
        url: {
          text: "Embed URL",
          placeholder: "Loom embed URL",
        },
        slug: "loom",
      },
    },
    organization: {
      name: "Loom",
      url: "https://www.loom.com/",
      supportUrl: "https://support.loom.com/hc/en-us",
      branding: {
        logo: "https://bpb-ap-se2.wpmucdn.com/global2.vic.edu.au/dist/8/5256/files/2019/04/Loom-logo-new-2020-1024x309.png",
      },
    },
  },
  lookerStudio: {
    slug: "lookerStudio",
    integrationType: {
      formType: "lookerStudio",
      type: "reporting",
      fields: {
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Looker.svg/512px-Looker.svg.png",
        name: {
          text: "Report Title",
        },
        description: {
          text: "Notes",
        },
        url: {
          text: "Embed URL",
          docsUrl:
            "https://support.google.com/looker-studio/answer/9132022?hl=en",
        },
        slug: "lookerStudio",
      },
    },
    organization: {
      name: "Looker Studio",
      url: "https://looker.com/",
      supportUrl: "https://looker.com/",
      branding: {
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Looker.svg/512px-Looker.svg.png",
      },
    },
  },
  retool: {
    slug: "retool",
    integrationType: {
      formType: "retool",
      type: "applications",
      fields: {
        logo: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Retool_logo.svg",
        name: { text: "Title" },
        description: {
          text: "Notes",
        },
        url: {
          text: "Retool Public URL",
          docsUrl: "https://docs.retool.com/docs",
        },
        slug: "retool",
      },
    },
    organization: {
      name: "Retool",
      url: "https://retool.com/",
      supportUrl: "https://retool.com/",
      branding: {
        logo: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Retool_logo.svg",
      },
    },
  },
};

export const reportTypesTemplates = Object.entries(reportTemplates).reduce(
  (acc, [key, value]) => {
    const type = value.integrationType.type;
    const obj = { [type]: { ...acc[type], [key]: value } };
    return { ...acc, ...obj };
  },
  {} as Record<ReportCardType, Record<string, ReportTemplate>>
);

const iframeDefaultFields: Fields = {
  logo: "",
  name: {
    text: "Report Title",
  },
  description: {
    text: "Notes",
  },
  url: {
    text: "Embed URL",
  },
  slug: "iframe",
};

export function getIframeFields(
  report: ReportType | null,
  editedReport: Reports | null
): Fields {
  if (editedReport) {
    const slug = editedReport.integration?.slug;
    if (slug) {
      return (
        reportTemplates[slug].integrationType.fields ?? iframeDefaultFields
      );
    }
    return iframeDefaultFields;
  }
  if (report === "lookerStudio" || report === "retool" || report === "loom") {
    return (
      reportTemplates[report].integrationType.fields ?? iframeDefaultFields
    );
  } else {
    return iframeDefaultFields;
  }
}
