import firebase from "firebase";

export async function getUserToken(): Promise<string | undefined> {
  return firebase.auth().currentUser?.getIdToken();
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
};
export async function getApiHeaders() {
  const user = getCurrentUser();
  if (user) {
    const token = await getUserToken();
    return {
      ...headers,
      Authorization: "Bearer " + token,
    };
  }
  return headers;
}
