import { connect } from "react-redux";
import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { trackOrganizationCreated, trackPage } from "../../analytics";
import { useNavigate } from "react-router-dom";
import firebase from "firebase";
import { UserContext } from "../../user-provider";
import { createOrganisation } from "./api";
import { TextField } from "@material-ui/core";

// todo: add selected account as prop
// todo: display "error"
function CreateOrganisation() {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [error, setErrors] = useState<string>("");
  const [orgName, setOrgName] = useState("");
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const anchorRef = useRef<HTMLInputElement>(null);

  const classes = useStyles();
  const navigate = useNavigate();
  const currentUser = firebase.auth().currentUser;
  const { logout } = useContext(UserContext);

  useEffect(() => {
    document.title = "New Organisation";
    trackPage(document.title);
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    createOrganisation(orgName)
      .then((result) => {
        trackOrganizationCreated({
          uid: currentUser?.uid ?? "",
          organizationId: result.id,
          name: result.name,
        });
        setLoading(false);
        navigate("/first-run");
      })
      .catch((e) => {
        setErrors(e.message);
        setLoading(false);
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const signOut = () => {
    logout()
      .then(() => {
        localStorage.clear();
      })
      .catch(function (error) {
        // An error happened.
      });
    setOpen(false);
    navigate("/");
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, open)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {/*{account.name}*/}
          </Typography>
          <Button>
            <Avatar
              ref={anchorRef}
              aria-haspopup="true"
              aria-controls={openMenu ? "menu-list-grow" : undefined}
              onClick={handleToggle}
            >
              X
            </Avatar>
          </Button>
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                      <MenuItem onClick={signOut}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.noAccounts}>
                <form onSubmit={handleSubmit}>
                  <Card className={classes.root}>
                    <CardHeader
                      titleTypographyProps={{ variant: "body1" }}
                      title="What is the name of your Organisation?"
                    />
                    <CardContent className={classes.root}>
                      <TextField
                        name="name"
                        onChange={(e) => setOrgName(e.target.value)}
                        placeholder="Organisation Name"
                        fullWidth
                      />
                    </CardContent>
                    <CardActions>
                      <Button variant="contained" color="primary" type="submit">
                        Finish Signup
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    height: "64px",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  loader: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  noAccounts: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
  },
}));

const mapStateToProps = (state: any) => {
  return {
    accounts: state.accounts.accounts,
    organisations: state.accounts.organisations,
    accountsLoading: state.accounts.loading,
    error: state.accounts.error,
  };
};

export default connect(mapStateToProps)(CreateOrganisation);
