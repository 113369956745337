import React from "react";
import Grid from "@material-ui/core/Grid";
import LinkSVG from "../../../../../assets/svg/create-report/link.svg";
import { CreateExternalLinkForm } from "./CreateExternalLinkForm";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { useParams } from "react-router-dom";
import { createReport, updateReport } from "../../../api";
import { closeCreateReportModal } from "../../../../../slices/createReportModalSlice";
import {
  reportCreated,
  reportUpdated,
} from "../../../../../actions/accountActions";
import { Reports } from "../../../../../shared/types";
import { CreateReportHeader } from "../CreateReportHeader";
import { ExternalLinkFormData, ExternalLinkFormValues } from "./form.types";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { CreateReportParams } from "../../../types";

export function CreateExternalLinkReport() {
  const dispatch = useAppDispatch();
  const { id: accountId } = useParams();
  const editedReport = useAppSelector(
    (state) => state.createReportModal.editedReport
  );
  const defaultValues: ExternalLinkFormValues | undefined = editedReport
    ? {
        title: editedReport.name,
        url: editedReport.url,
        section: editedReport.sectionName,
      }
    : undefined;

  function handleReportCreated(createdReport: Reports) {
    dispatch(reportCreated(createdReport));
    dispatch(closeCreateReportModal());
  }

  function handleReportUpdated(updatedReport: Reports) {
    dispatch(reportUpdated(updatedReport));
    dispatch(closeCreateReportModal());
  }

  function handleSubmit(data: ExternalLinkFormData) {
    const report: CreateReportParams = {
      ...data,
      accountId: accountId ?? "",
      type: "link",
    };
    if (editedReport) {
      return updateReport(editedReport.id, report).then(handleReportUpdated);
    } else {
      return createReport(report).then(handleReportCreated);
    }
  }

  return (
    <Grid container direction="column">
      <CreateReportHeader
        style={{
          marginBottom: "1.25rem",
        }}
        logo={LinkSVG}
        title="External Link"
      />
      <CreateExternalLinkForm
        handleSubmit={handleSubmit}
        defaultValues={defaultValues}
      />
    </Grid>
  );
}
