import React from "react";

import { MarkDownReportFrame } from "./MarkdownReportFrame";
import { OpenApiReportFrame } from "./OpenApiReportFrame";
import { IframeReportFrame } from "./IframeReportFrame";
import { Accounts, Reports } from "../../shared/types";
import { RetoolReportFrame } from "./RetoolReportFrame";

export interface IReportFrameProps {
  report: Reports;
  account: Accounts;
}

export function ReportFrame({ report, account }: IReportFrameProps) {
  if (report.type === "markdown") {
    return <MarkDownReportFrame report={report} account={account} />;
  } else if (report.type === "openapi-spec") {
    return <OpenApiReportFrame report={report} />;
  } else if (RegExp("retool.com").test(report.url)) {
    return <RetoolReportFrame report={report} />;
  } else {
    return <IframeReportFrame report={report} />;
  }
}
