import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { closeCreateReportModal, setCreateReportType } from "../../../../slices/createReportModalSlice";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export function CreateReportModalHeader() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  function onLinkClick() {
    dispatch(setCreateReportType("link"));
  }

  function onClose() {
    dispatch(closeCreateReportModal());
  }

  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      className={classes.headerContainer}
    >
      <Button
        variant="contained"
        className={classes.headerButton}
        onClick={onLinkClick}
      >
        <Box display="flex" alignItems="center" gridGap="0.5rem">
          <Typography className={classes.headerButtonText}>
            add external link
          </Typography>
          <OpenInNewIcon className={classes.headerButtonIcon} />
        </Box>
      </Button>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
}



const useStyles = makeStyles(() => ({
  headerContainer: {
    gap: "1.25rem",
  },
  headerButton: {
    backgroundColor: "#E0E0E0",
    color: "rgba(0, 0, 0, 0.54)",
    height: "2rem",
  },
  headerButtonText: {
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  headerButtonIcon: {
    width: "1.125rem",
    height: "1.125rem",
  },
  closeButton: {
    width: "2.25rem",
    height: "2.25rem",
  },
}));
