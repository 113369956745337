import React from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { setCreateReportType } from "../../../../../slices/createReportModalSlice";
import { mapMarkdownFormData, MarkdownFormValues } from "./form.types";
import { CreateMarkdownReportFormView } from "./CreateMarkdownReportFormView";
import { CreateReportParams } from "../../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  title: yup.string().required(),
  notes: yup.string().optional().default(""),
  public: yup.boolean().required(),
  section: yup.string().required(),
});

type CreateMarkdownReportFormProps = {
  handleSubmit(data: Partial<CreateReportParams>): Promise<void>;
  defaultValues?: MarkdownFormValues;
};
export function CreateMarkdownReportForm({
  handleSubmit,
  defaultValues,
}: CreateMarkdownReportFormProps) {
  const dispatch = useAppDispatch();
  const form = useForm<MarkdownFormValues>({
    mode: "onChange",
    defaultValues: defaultValues ?? {
      title: "",
      notes: "",
      public: false,
      section: "API Documentation",
    },
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: MarkdownFormValues) {
    return await handleSubmit(mapMarkdownFormData(data));
  }

  function handleBackClick() {
    dispatch(setCreateReportType(null));
  }

  return (
    <CreateMarkdownReportFormView
      form={form}
      onSubmit={onSubmit}
      handleBackClick={handleBackClick}
    />
  );
}
