import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PoweredByLogo from "../../assets/powered-by-report-wrapper-badge-logo@2x.png";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

type FooterProps = {
  style?: React.CSSProperties;
  onOpenCallback?: () => void;
};
export function Footer({ onOpenCallback, style }: FooterProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(true);
    if (onOpenCallback) {
      onOpenCallback();
    }
  }

  return (
    <Paper className={classes.root} style={style} elevation={8}>
      <Divider />
      <Button>
        <img
          src={PoweredByLogo}
          alt="Powered by Report Wrapper"
          className={classes.img}
          onClick={handleClick}
        />
      </Button>
      <Dialog open={open} maxWidth="md" className={classes.dialog} fullWidth>
        <Grid container justifyContent="flex-end">
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            src="https://reportwrapper.com/docs-powered-by-report-wrapper"
            className={classes.iframe}
          />
        </Grid>
      </Dialog>
    </Paper>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
    marginTop: "2rem",
  },
  img: {
    height: "2.25rem",
  },
  dialog: {
    minHeight: "80vh",
  },
  iframe: {
    width: "100%",
    height: "80vh",
    border: "none",
  },
  iconButton: {
    margin: "0.5rem",
  },
}));
