import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import PaletteIcon from "@material-ui/icons/Palette";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import PaymentIcon from "@material-ui/icons/Payment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "./Header";
import Users from "./Users";
import OrganisationAccounts from "./OrganisationAccounts";
import OrganisationSubscription from "./OrganisationSubscription";
import EmailTemplates from "./EmailTemplates";
import AccessLogs from "./AccessLogs";
import { useDispatch, connect } from "react-redux";
import { fetchAccounts } from "./actions/accountActions";

import FormatListNumberedIcon from "@material-ui/icons/List";
import { Accounts, Organisations } from "./shared/types";
import { trackPage } from "./analytics";
import Theme from "./features/settings/Theme";
import { getUserToken } from "./shared/api";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  drawerHeader: {
    display: "flex",
    height: "64px",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  loader: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  hideFooter: {
    position: "absolute",
    width: "100%",
    height: "26px",
    background: "#fafafa",
    bottom: "5px",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

interface OrganisationlInterface {
  accounts: Accounts[];
  organisations: Organisations[];
  loading: boolean;
}

type SettingsItem =
  | "users"
  | "accounts"
  | "theme"
  | "billing"
  | "email-templates"
  | "access-logs";
function isOfTypeSettingsItem(x: string): x is SettingsItem {
  return [
    "users",
    "accounts",
    "theme",
    "billing",
    "email-templates",
    "access-logs",
  ].includes(x);
}

const Organisation = ({
  loading,
  accounts,
  organisations,
}: OrganisationlInterface) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [organisation, setOrganisation] = useState<Organisations | null>(null);
  const [selectedItem, setSelectedItem] = useState<SettingsItem>("users");
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = (state: boolean) => {
    setOpen(state);
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (organisation?.name) {
      document.title = "Organisation Settings - " + organisation.name;
      trackPage(document.title);
    }
  }, [organisation]);

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("setting");
    if (query && isOfTypeSettingsItem(query)) {
      setSelectedItem(query);
    }
  }, []);

  const setEmptyReport = () => {
    navigate({
      pathname: `/`,
      search: `?${query.toString()}`,
    });
  };

  useEffect(() => {
    if (organisations && organisations.length > 0) {
      const org = organisations.find((x) => x.id === id);
      if (org) {
        setOrganisation(org);
        document.title = org.name + ": Reports";
      }
    } else if (loading) {
      getUserToken().then((token) => {
        dispatch(fetchAccounts(token!));
      });
    }
  }, [id, loading, organisations]);

  function handleSelectItem(item: SettingsItem) {
    setSelectedItem(item);
    navigate({
      pathname: `/organisation/${id}`,
      search: `?setting=${item}`,
    });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Header
            accounts={accounts}
            open={open}
            account={organisation}
            organisations={organisations}
            handleDrawerOpen={handleDrawerOpen}
            setEmptyReport={setEmptyReport}
            isDashboard={false}
            report={null}
            reportData={null}
            setSelectedReport={null}
            settingOption={false}
            AccountOption={false}
            AvtarOption={false}
          ></Header>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.drawerHeader}>&nbsp;</div>
            <Divider />
            <List>
              <ListItem>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to={`/`}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Back to Reports" />
                </Link>
              </ListItem>
              <ListItem
                style={{ cursor: "pointer" }}
                selected={selectedItem === "users"}
                onClick={() => handleSelectItem("users")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </Link>
              </ListItem>

              <ListItem
                style={{ cursor: "pointer" }}
                selected={selectedItem === "accounts"}
                onClick={() => handleSelectItem("accounts")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <AccountTreeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Accounts" />
                </Link>
              </ListItem>
              <ListItem
                style={{ cursor: "pointer" }}
                selected={selectedItem === "theme"}
                onClick={() => handleSelectItem("theme")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <PaletteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Theme" />
                </Link>
              </ListItem>
              <ListItem
                style={{ cursor: "pointer" }}
                selected={selectedItem === "billing"}
                onClick={() => handleSelectItem("billing")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <PaymentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Billing" />
                </Link>
              </ListItem>
              <ListItem
                style={{ cursor: "pointer" }}
                selected={selectedItem === "email-templates"}
                onClick={() => handleSelectItem("email-templates")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Email Templates" />
                </Link>
              </ListItem>
              <ListItem
                style={{ cursor: "pointer" }}
                selected={selectedItem === "access-logs"}
                onClick={() => handleSelectItem("access-logs")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <FormatListNumberedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Access Logs" />
                </Link>
              </ListItem>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {selectedItem === "users" && <Users />}
            {selectedItem === "accounts" && (
              <OrganisationAccounts
                handleUpgradeAccount={() => handleSelectItem("billing")}
              />
            )}
            {selectedItem === "theme" && <Theme />}
            {selectedItem === "billing" && <OrganisationSubscription />}
            {selectedItem === "email-templates" && <EmailTemplates />}
            {selectedItem === "access-logs" && <AccessLogs />}
          </main>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    accounts: state.accounts.accounts,
    organisations: state.accounts.organisations,
    loading: state.accounts.loading,
  };
};

export default connect(mapStateToProps)(Organisation);
