import { RedocStandalone } from "redoc";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Reports } from "../../shared/types";

export function OpenApiReportFrame({ report }: { report: Reports }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <RedocStandalone specUrl={report.url} />
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    position: "relative",
    backgroundColor: "white",
    height: `100%`,
    maxWidth: "100%",
  },
});
