import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

type FormSectionTitleProps = {
  value: string;
}
export function FormSectionTitle({ value }: FormSectionTitleProps) {
  const classes = useStyles();

  return <Typography className={classes.title}>{value}</Typography>;
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "0.9375rem",
    fontWeight: 600,
  },
}));
