import React from "react";
import { Reports } from "../../shared/types";

export function IframeReportFrame({
  report,
  embed,
}: {
  report: Reports;
  embed?: boolean;
}) {
  const width = embed || report.allowFullWidth ? "100%" : report.width;
  // const height = embed ? "100%" : report.height;
  // temporarily default to 100% for the new ui
  const height = "100%";
  return (
    <iframe
      sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-popups-to-escape-sandbox allow-modals allow-presentation allow-storage-access-by-user-activation allow-downloads"
      title={report.name}
      src={report.url}
      width={width}
      height={height}
      allow="autoplay; fullscreen"
      style={{
        border: 0,
      }}
    />
  );
}
