import firebase from "firebase";

const config = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_PROJECTID}`,
  projectId: `${process.env.REACT_APP_FIREBASE_DATABASE}`,
  storageBucket: "theanalyticsapi.appspot.com",
  messagingSenderId: "-",
  appId: "1:512433183092:web:fa9fa87fadaeb9e0ebf421",
  measurementId: "G-K0DRBC8364",
};

firebase.initializeApp(config);

export const auth = firebase.auth();

export default firebase;
