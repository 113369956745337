import { createSlice } from "@reduxjs/toolkit";
import { Accounts, Organisations, Reports } from "../shared/types";

type SelectedState = {
  account: Accounts | null;
  organisation: Organisations | null;
  user: any;
  report: Reports | null;
};

const initialState: SelectedState = {
  account: null,
  organisation: null,
  user: null,
  report: null,
};

export const selectedSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload;
      state.report = null;
    },
    setOrganisation: (state, action) => {
      if (state.organisation?.id !== action.payload.id) {
        state.account = null;
        state.report = null;
        localStorage.removeItem("accountId");
      }
      state.organisation = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setReport(state, action) {
      state.report = action.payload;
    },
  },
});

export const { setAccount, setUser, setOrganisation, setReport } =
  selectedSlice.actions;

export default selectedSlice.reducer;
