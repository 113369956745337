export type ExternalLinkFormValues = {
  title: string;
  url: string;
  section: string;
};

export type ExternalLinkFormData = {
  name: string;
  url: string;
  sectionName: string;
};

export function mapExternalLinkFormData(
  data: ExternalLinkFormValues
): ExternalLinkFormData {
  return {
    name: data.title,
    url: data.url,
    sectionName: data.section,
  };
}
