import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Reports } from "./shared/types";
import { PublicAccount } from "./features/Accounts/types";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    height: 320,
    width: 350,
  },
  control: {
    padding: theme.spacing(2),
  },
  grid: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
  },
}));

interface IReportPreviewGridPublic {
  account: PublicAccount | null;
  handleSelectReport: (report: Reports) => void;
}
export default function ReportPreviewGridPublic(
  props: IReportPreviewGridPublic
) {
  const classes = useStyles();
  const { account, handleSelectReport } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.grid}>
          {account?.reports?.map((report, r) => (
            <Grid key={r} item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={report.name}
                    height="200"
                    image={report.previewUrl}
                    title={report.name}
                    onClick={() => handleSelectReport(report)}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {report.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  {report.type === "link" ? (
                    <Button
                      component="a"
                      href={report.url.replace(
                        /^(?:(.*:)?\/\/)?(.*)/i,
                        (match, schemma, nonSchemmaUrl) =>
                          schemma ? match : `http://${nonSchemmaUrl}`
                      )}
                      target="_blank"
                      size="small"
                      color="primary"
                    >
                      VIEW
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => props.handleSelectReport(report)}
                    >
                      VIEW
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}
