import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

type FormInputLabelProps = {
  value: string;
  required?: boolean;
  docsUrl?: string;
};
export function FormInputLabel({
  value,
  required,
  docsUrl,
}: FormInputLabelProps) {
  const classes = useStyles();

  return (
    <Typography className={classes.label}>
      {value}
      {docsUrl && (
        <>
          &nbsp;
          <Link className={classes.link} href={docsUrl} target="_blank">
            (what's this?)
          </Link>
        </>
      )}
      &nbsp;
      {required && "*"}
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  label: {
    color: "#36454F",
    textTransform: "capitalize",
  },
  link: {
    color: "#36454F",
    textDecoration: "underline",
    textTransform: "lowercase",
  },
}));
