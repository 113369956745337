import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";

const font = "'Amatic SC', cursive";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  quoteText: {
    fontSize: "40px",
    fontFamily: font,
    textAlign: "center",
    margin: "0 auto",
    //xwidth: "80%",
  },
  quoteFooter: {
    margin: "10px 0 10px 0",
    fontSize: "18px",
    textAlign: "right",
  },
  faQuoteLeft: {
    position: "relative",
    top: "34px",
  },
  faQuoteRight: {
    position: "relative",
    bottom: "23px",
    float: "right",
  },
}));

const Quote = (props) => {
  const classes = useStyles();

  const [quote, setQuote] = useState({ text: "test", author: "author" });

  const fallbackQuotes = [
    {
      text: "We have to learn to interrogate our data collection process, not just our algorithms",
      author: "Cathy O'Neil",
    },
    {
      text: "When used correctly, data is not the enemy of Intuitive Creative Thinkers; it is a powerful friend.",
      author: "Leena Patel",
    },
    {
      text: "Information overload creates poverty of intelligence and action.",
      author: "Jag Randhawa",
    },
    {
      text: "Excessive information creates its own form of blindness to what is actually going on.",
      author: "Alexander Chancellor",
    },
    {
      author: "Hans Rosling",
      text: "Free access to data doesn’t turn into knowledge without effort",
    },
    { text: "Maybe stories are just data with a soul.", author: "Brené Brown" },
    {
      text: "Spreadsheets are more important to marketing than pretty pictures these days.",
      author: "Trevor Carss",
    },
    {
      text: "All data has its beauty, but not everyone sees it.",
      author: "Damian Mingle",
    },
    {
      text: "What gets tracked, gets measured What gets measured, gets managed What gets managed, is improved.",
      author: "Eddie Gear",
    },
    {
      text: 'As one Google Translate engineer put it, "when you go from 10,000 training examples to 10 billion training examples, it all starts to work. Data trumps everything.',
      author: "Garry Kasparov",
    },
    {
      author: "Shitalkumar R Sukhdeve",
      text: "For getting success in business with Data Science, predictions must be backed by timely strategic actions.",
    },
    {
      author: "Pearl Zhu",
      text: "We are moving slowly into an era where Big Data is the starting point, not the end.",
    },
    {
      author: "Damian Mingle",
      text: "In Data Science if you want to help individuals, be empathetic and ask questions; that way, you can begin to understand their journey, too.”",
    },
    {
      text: "While all the data we have is about the past, all the decisions we make are about the future",
      author: "Alex Hagan",
    },
    {
      text: "Cash is King. Information is his twin.",
      author: "Sotero M Lopez II",
    },
    {
      author: "Jon Acuff",
      text: "That's all data is. A gift from yesterday that you receive today to make tomorrow better.",
    },
    {
      text: "A good chart widens our imagination and enhances our understanding by providing insights from numbers.",
      author: "Alberto Cairo",
    },
    {
      author: "Kurt Seapoint",
      text: "Destroy your money, you can earn more. Destroy your data, your existence is erased.",
    },
    {
      categories: { BM5Rx3L84fZqSGP0EPkQ: true },
      author: "Sir Arthur Conan Doyle, Sherlock Holmes",
      text: "It is a capital mistake to theorize before one has data. Insensibly one begins to twist facts to suit theories, instead of theories to suit facts.",
    },
    {
      text: "One person's data is another person's noise.",
      author: "K.C. Cole",
    },
    {
      author: "Damian Mingle",
      text: "Businesses should free themselves from dogma, especially when leveraging data to build a business. No one got very far living out other people’s thinking",
    },
    {
      author: "Mike Schmoker",
      text: "Things get done only if the data we gather can inform and inspire those in a position to make difference.",
    },
    {
      author: "Damian Duffy Mingle",
      text: "Aim for simplicity in Data Science. Real creativity won’t make things more complex. Instead, it will simplify them.",
    },
  ];

  const getQuote = () => {
    var item =
      fallbackQuotes[Math.floor(Math.random() * fallbackQuotes.length)];
    setQuote(item);
    /*
    const headers = { Authorization: "Bearer " };
    fetch(
		"https://us-central1-thatapiplatform.cloudfunctions.net/app/quotes/random",//`${process.env.REACT_APP_API_ENDPOINT}/accounts/${accountId}/access-log`,
      { headers }
    )
      .then((response) => response.json())
      .then((result) => {
		setQuote(result)
	})*/
  };

  useEffect(() => {
    getQuote();
  }, []);

  return (
    <blockquote>
      <i className={classes.faQuoteLeft}></i>
      <p className={classes.quoteText}>{quote.text}</p>
      <i className={classes.faQuoteRight}></i>
      <footer className={classes.quoteFooter}>
        {" "}
        — <span>{quote.author}</span>
      </footer>
    </blockquote>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    quotes: state.quotes,
  };
};

export default connect(mapStateToProps)(Quote);
