import React from "react";
import { Accounts, Reports } from "../../../shared/types";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { setReport } from "../../../slices/selectedSlice";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import UIDrawerNavMenu from "./UIDrawerNavMenu";
import { makeStyles } from "@material-ui/core/styles";

type UIDrawerReportsProps = {
  section: Accounts["sections"][0];
  handleOpenDialog: (report: Reports) => void;
  handleShareReportOpen: (report: Reports) => void;
  handleDeleteReport: (report: Reports) => void;
  isAllowedToEdit: boolean;
};
export function UIDrawerReports({
  section,
  handleOpenDialog,
  handleShareReportOpen,
  handleDeleteReport,
  isAllowedToEdit,
}: UIDrawerReportsProps) {
  const classes = useStyles({ colour1: "" });
  const selectedReport = useAppSelector((state) => state.selected.report);
  const dispatch = useAppDispatch();

  function handleSelectReport(report: Reports) {
    dispatch(setReport(report));
  }

  return (
    <>
      {(section.reports || []).map((report: Reports) =>
        report.type === "link" ? (
          <ListItem
            key={report.id}
            button
            component="a"
            target="_blank"
            href={report.url}
            onClick={() => handleSelectReport(report)}
          >
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={
                <>
                  {report.name}{" "}
                  <OpenInNew
                    style={{
                      marginLeft: "0.5rem",
                      marginBottom: "-0.2rem",
                      fontSize: "16px",
                      color: "#b0b0b0",
                    }}
                  />
                </>
              }
            />
            <ListItemSecondaryAction>
              <UIDrawerNavMenu
                sectionName={section.name}
                handleOpenDialog={handleOpenDialog}
                handleShareReportOpen={handleShareReportOpen}
                handleDeleteReport={handleDeleteReport}
                report={report}
                options={["edit", "delete"]}
                isAllowedToEdit={isAllowedToEdit}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ) : (
          <ListItem
            key={report.id}
            button
            onClick={() => handleSelectReport(report)}
            selected={selectedReport?.id === report.id}
          >
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={report.name}
              style={{ overflow: "hidden" }}
            />

            <ListItemSecondaryAction>
              <UIDrawerNavMenu
                sectionName={section.name}
                handleOpenDialog={handleOpenDialog}
                handleShareReportOpen={handleShareReportOpen}
                handleDeleteReport={handleDeleteReport}
                report={report}
                options={["edit", "share", "delete"]}
                isAllowedToEdit={isAllowedToEdit}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  listItemText: {
    fontSize: "1em",
  },
}));
