import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useClipboard from "react-hook-clipboard";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { useDispatch, connect } from "react-redux";
import {
  fetchUsers,
  fetchUserInvitation,
  removeUser,
  inviteUser,
  updateOrganisationUserRole,
  updateAccountUserRole,
} from "./actions/userActions";
import { useConfirm } from "material-ui-confirm";
import { trackInviteSent } from "./analytics";
import { getCurrentUser, getUserToken } from "./shared/api";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  loader: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

const Users = (props) => {
  const {
    isSettings = false,
    users,
    url,
    loading,
    user: authenticatedUser,
    account,
    organisation,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [email, setEmail] = useState("");
  const [showCopyModal, setShowCopyModal] = useState(false);

  const [clipboard, copyToClipboard] = useClipboard();

  const callRemoveUser = (uid) => {
    confirm({ description: "Are you sure you want to remove the user?" }).then(
      () => {
        if (currentUser && uid) {
          getUserToken().then((token) => {
            dispatch(removeUser(token, isSettings, id, uid));
          });
        }
      }
    );
  };

  const { id } = useParams();

  const callInviteUser = () => {
    trackInviteSent({
      userId: currentUser.uid,
      email,
      role: isSettings ? "account-user" : "organization-user", //checks if it's a new account user vs. an organization user
      organizationId: organisation?.id,
      accountId: account?.id,
    });

    getUserToken().then((token) => {
      dispatch(inviteUser(token, isSettings, id, email));
      setEmail("");
    });
  };

  const currentUser = getCurrentUser();

  const getUsers = () => {
    getUserToken().then((token) => {
      dispatch(fetchUsers(token, isSettings, id));
    });
  };

  const copyInvite = (uid) => {
    getUserToken().then((token) => {
      dispatch(fetchUserInvitation(token, uid));
    });
    setShowCopyModal(true);
  };

  useEffect(() => {
    if (currentUser && id) {
      getUsers();
    }
  }, [currentUser, id]);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function handleUpdateRole(userId, role) {
    getUserToken().then((token) => {
      if (isSettings) {
        dispatch(updateAccountUserRole(token, userId, id, role));
      } else {
        dispatch(updateOrganisationUserRole(token, userId, id, role));
      }
    });
  }

  const sortedUsers = [...users].sort((a, b) => {
    if (a.email < b.email || a.role === "owner") {
      return -1;
    }
    if (a.email > b.email) {
      return 1;
    }
    return 0;
  });

  return (
    <Container
      maxWidth="lg"
      className={classes.container}
      style={{ height: "100%" }}
    >
      <Grid container spacing={3} style={{ height: "100%" }}>
        <Grid item xs={12} style={{ height: "100%" }}>
          {loading ? (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Card>
                <CardHeader
                  titleTypographyProps={{ variant: "body1" }}
                  title={
                    isSettings
                      ? "Invite Clients or Colleagues to have access to this Account."
                      : "Invite others in your Organisation. By default they will have access to all your Accounts."
                  }
                />

                <CardActions>
                  <Paper
                    component="form"
                    className={`${classes.root} ${classes.width100}`}
                  >
                    <InputBase
                      name="email"
                      value={email}
                      fullWidth={true}
                      onInput={(e) => setEmail(e.target.value)}
                      className={classes.input}
                      placeholder="Enter their Email"
                    />
                  </Paper>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => callInviteUser()}
                  >
                    Invite
                  </Button>
                </CardActions>
              </Card>
              <br></br>
              <Divider />

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box fontWeight="fontWeightBold">Email</Box>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <Box fontWeight="fontWeightBold">Last Login</Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box fontWeight="fontWeightBold"></Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedUsers.map((user, s) => (
                      <TableRow key={s}>
                        <TableCell component="th" scope="row">
                          {user.email || user.name}
                        </TableCell>
                        <TableCell align="right">
                          {user.status === "invited" && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => copyInvite(user.uid)}
                            >
                              Copy Invite
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {user.status === "invited" ? "-" : user.lastLoginAt}
                        </TableCell>
                        <TableCell align="right">
                          <Select
                            value={user.role}
                            defaultValue={user.role}
                            disabled={
                              authenticatedUser &&
                              authenticatedUser.role !== "owner" ||
                              user.role === "owner"
                            }
                            renderValue={() => (
                              <div
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {user.role === "member" ? "Viewer" : user.role}
                              </div>
                            )}
                            onChange={(event) =>
                              handleUpdateRole(user.uid, event.target.value)
                            }
                          >
                            <MenuItem value="editor">Editor</MenuItem>
                            <MenuItem value="member">Viewer</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={
                              (authenticatedUser &&
                                authenticatedUser.role !== "owner") ||
                              user.role === "owner"
                            }
                            onClick={() => callRemoveUser(user.uid)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Dialog
                open={showCopyModal}
                fullWidth="true"
                maxWidth="md"
                onClose={() => setShowCopyModal(false)}
                aria-labelledby="invite-dialog"
              >
                <DialogContent dividers>
                  {url === null ? (
                    <div className={classes.loader}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <TextField
                      id="link"
                      fullWidth="true"
                      label="Invite link"
                      name="link"
                      defaultValue={url}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      copyToClipboard(url || "");
                      setOpen(true);
                    }}
                  >
                    Copy
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setShowCopyModal(false)}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  URL copied!
                </Alert>
              </Snackbar>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const mapStateToProps = (state, ownProps) => {
  const currentUser = getCurrentUser();
  const users = state.users.users || [];
  return {
    ...ownProps,
    users,
    user: users.find((user) => user.uid === currentUser.uid),
    url: state.users.url,
    loading: state.users.loading,
    account: state.selected.account,
    organisation: state.selected.organisation,
  };
};

export default connect(mapStateToProps)(Users);
