import { Control, Controller } from "react-hook-form";
import Box from "@material-ui/core/Box";
import { FormInputLabel } from "./FormInputLabel";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";

type FormInputSelectProps = {
  control: Control<any>;
  name: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  options: string[];
  disabled?: boolean;
};
export function FormInputSelect({
  control,
  label,
  name,
  required,
  options,
  placeholder,
  disabled,
}: FormInputSelectProps) {
  return (
    <Box display="flex" flexDirection="column" gridGap="0.5rem">
      <FormInputLabel value={label} required={required} />
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControl variant="outlined">
            <InputLabel>{placeholder}</InputLabel>
            <Select
              value={value}
              onChange={onChange}
              variant="outlined"
              label={placeholder}
              disabled={disabled}
            >
              {options.map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
}
