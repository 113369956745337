import { getApiHeaders, getUserToken } from "../../shared/api";
import { Reports } from "../../shared/types";
import firebase from "firebase";
import { CreateReportParams } from "./types";
import { assignInWith } from "lodash";

export async function getReport(reportId: string): Promise<Reports> {
  const headers = await getApiHeaders();
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports/${reportId}`, {
    method: "GET",
    headers,
  }).then((response) => response.json());
}

export async function getPublicReport(reportId: string): Promise<Reports> {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports/${reportId}`, {
    method: "GET",
  }).then((response) => response.json());
}

export async function deleteReport(reportId: string) {
  const headers = await getApiHeaders();
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports/${reportId}`, {
    method: "DELETE",
    headers,
  });
}

export async function updateReport(
  reportId: string,
  report: Partial<CreateReportParams>
): Promise<Reports> {
  const headers = await getApiHeaders();
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports/${reportId}`, {
    method: "POST",
    headers,
    body: JSON.stringify(report),
  }).then((response) => response.json());
}

export async function createReport(data: CreateReportParams): Promise<Reports> {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/reports/`;
  const headers = await getApiHeaders();
  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function getTheme(accountId: string) {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/accounts/${accountId}/theme`,
    {
      method: "GET",
    }
  ).then((response) => response.json());
}
