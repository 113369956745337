import firebase from "firebase";
import React, { useEffect, useState } from 'react';
//@ts-ignore
import Retool from "react-retool";
import { makeStyles } from "@material-ui/core/styles";
import { Reports } from "../../shared/types";

export function RetoolReportFrame({
  report,
  embed,
}: {
  report: Reports;
  embed?: boolean;
}) {
  const classes = useStyles();
  const currentUser = firebase.auth().currentUser;
  const width = embed || report.allowFullWidth ? "100%" : report.width;
  // const height = embed ? "100%" : report.height;
  const height = '100%'

  const [data, setData] = useState<string>();
  //get the token from the current uset and pass it to the retool iframe
   

   useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const authToken = await currentUser?.getIdToken();
      setData(authToken ? authToken : '');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const isBearerLoaded = (bearer:string|undefined) =>
  {
    return String(bearer).length>100
  }

  return (
    <div
      className={classes.container}
      id="container"
      style={{
        height,
        width,
      }}
    >
      {
        isBearerLoaded(data) ? <Retool
          url={report.url}
          data={{
            currentUserEmail: currentUser?.email,
            currentUserDisplayName: currentUser?.displayName,
            authToken: data
          }}
          sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-popups-to-escape-sandbox allow-modals allow-presentation allow-storage-access-by-user-activation allow-downloads"
        />: <div>loading...</div>
      }
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "white",
  },
});
