import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Quote from "../Quote";
import { useNavigate } from "react-router-dom";
import { trackPage } from "../analytics";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    paddingRight: 24,
    maxHeight: 40,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  loader: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const ResetPassword = () => {
  const navigate = useNavigate();
  console.log(navigate, "HISTORY");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  useEffect(() => {
    document.title = "Reset Password";
    trackPage(document.title);
  }, []);

  const sendMail = () => {
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErrorMessage(errorMessage);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <TextField
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <div style={{ width: "100%", color: "red" }}>{errorMessage}</div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={sendMail}
        >
          Send Verification
        </Button>
        <br></br>
        <Quote />
      </div>
    </Container>
  );
};

export default ResetPassword;
