import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { UserContext } from "./user-provider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { Accounts, Organisations, Reports } from "./shared/types";

import ShareModalUI from "./components/UI/UIDrawer/UIShareModal";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { resetAccounts } from "./actions/accountActions";
import { Crisp } from "crisp-sdk-web";
import { Badge } from "@material-ui/core";
import { ChatOutlined } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    paddingRight: 24,
    maxHeight: 40,
    cursor: "pointer",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: (props: { colour1: string }) => ({
    marginRight: theme.spacing(2),
    color: props.colour1,
  }),
  hide: {
    display: "none",
  },
  title: (props: { colour1: string }) => ({
    fontWeight: 600,
    color: props.colour1,
    flexGrow: 1,
    cursor: "pointer",
  }),
}));

export interface User {
  displayName: string;
}

interface IHeaderProps {
  account: Accounts | null | any;
  organisations?: Organisations[];
  open: boolean;
  isDashboard: boolean;
  handleDrawerOpen: (state: boolean) => void;
  user?: any;
  report?: Reports | null;
  setEmptyReport?: () => void;
  AvtarOption: boolean;
  handleSelectOrganisation: (organisationId: string) => void;
  setUpdateAccount: (isDirty: boolean) => void;
}

const Header = (props: IHeaderProps) => {
  const {
    account,
    organisations,
    open,
    isDashboard,
    handleDrawerOpen,
    user,
    report,
    setEmptyReport,
    AvtarOption,
    handleSelectOrganisation,
    setUpdateAccount,
  } = props;

  const classes = useStyles({ colour1: account?.theme?.colour1 || "#ffffff" });
  const { logout } = useContext(UserContext);
  const [openMenu, setOpenMenu] = useState(false);

  const [openShareReport, setOpenShareReport] = useState(false);
  const [crispMessageCount, setCount] = useState<number>(0);
  const anchorRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  let pageTitle = account?.theme?.hideTitle === true ? "" : account?.name;
  if (!isDashboard) {
    pageTitle += " - Settings";
  }

  useEffect(() => {
    initCrispChat(setCount);
  }, []);

  const signOut = () => {
    logout().then(() => {
      dispatch(resetAccounts());
      dispatch({ type: "USER_LOGOUT" });
      navigate("/login");
    });
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpenMenu(false);
  };
  const handleShareReportOpen = (report: Reports) => {
    setOpenShareReport(!openShareReport);
  };
  const handleShareReportStateChange = () => {
    setUpdateAccount(true);
  };

  function handleOrganisationClick(organisationId: string) {
    handleSelectOrganisation(organisationId);
    handleClose();
  }

  function handleSupportMenuClick() {
    if (!Crisp.chat.isVisible()) {
      Crisp.chat.show();
      Crisp.chat.open();
    } else {
      Crisp.chat.close();
      Crisp.chat.hide();
    }
  }

  return (
    <AppBar
      style={{ background: account?.theme?.navColour }}
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerOpen(true)}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerOpen(false)}
          edge="start"
          className={clsx(classes.menuButton, !open && classes.hide)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <img
          src={account?.theme?.logoUrl}
          alt="logo"
          className={classes.logo}
          onClick={setEmptyReport}
        />

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
          onClick={setEmptyReport}
        >
          {pageTitle}
        </Typography>

        {report && (
          <ShareModalUI
            openShareReport={openShareReport}
            handleShareReportOpen={handleShareReportOpen}
            report={report}
            account={account}
            handleShareReportStateChange={handleShareReportStateChange}
            showButton={true}
            //only allow editors to change public share permissions
            isAllowedToEdit={account.editor}
          />
        )}

        {AvtarOption ? (
          <>
            <Tooltip title={`${user?.email}`}>
              <Button onClick={handleToggle}>
                <Badge
                  overlap="rectangular"
                  color="secondary"
                  variant="dot"
                  badgeContent={crispMessageCount}
                  showZero={false}
                >
                  <Avatar
                    style={{ color: "white" }}
                    ref={anchorRef}
                    aria-haspopup="true"
                    aria-controls={openMenu ? "menu-list-grow" : undefined}
                  >
                    {user?.displayName ? user?.displayName.substr(0, 1) : null}
                  </Avatar>
                </Badge>
              </Button>
            </Tooltip>
          </>
        ) : (
          ""
        )}

        <Popper
          open={openMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                    <Box key="0000000">
                      <MenuItem
                        style={{
                          paddingTop: "10px",
                        }}
                        onClick={handleSupportMenuClick}
                      >
                        <Typography>Support</Typography>
                        &nbsp;&nbsp;
                        <Badge
                          overlap="rectangular"
                          color="secondary"
                          badgeContent={crispMessageCount}
                          showZero={false}
                        >
                          <ChatOutlined />
                        </Badge>
                      </MenuItem>
                    </Box>
                    {organisations &&
                      organisations.map((org) => (
                        <Box key={org.id}>
                          <Link
                            style={{ textDecoration: "none", color: "unset" }}
                            to={`/organisation/${org.id}`}
                          >
                            <MenuItem
                              selected={id === org.id}
                              onClick={() => handleOrganisationClick(org.id)}
                              id={org.id}
                            >
                              Team: {org.name}
                            </MenuItem>
                          </Link>
                          <Box my={1}>
                            <Divider />
                          </Box>
                        </Box>
                      ))}
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </AppBar>
  );
};

function initCrispChat(setCount: (count: number) => void) {

  try{
  Crisp.chat.hide();
  Crisp.chat.close();

  // Executed once a message is received by the visitor
  Crisp.message.onMessageReceived(() => {
    setCount(Crisp.chat.unreadCount());
  });
  // Executed once the chat window is opened
  Crisp.chat.onChatOpened(() => {
    setCount(Crisp.chat.unreadCount());
  });
  // Executed once the chat was closed
  Crisp.chat.onChatClose(() => {
    setCount(Crisp.chat.unreadCount());
    Crisp.chat.hide();
  });
}catch(e){
  console.log('Error loading crisp chat', e);
}
}

export default Header;
