export const FETCH_SUBSCRIPTION_OPTIONS_BEGIN =
  "FETCH_SUBSCRIPTION_OPTIONS_BEGIN";
export const FETCH_SUBSCRIPTION_OPTIONS_SUCCESS =
  "FETCH_SUBSCRIPTION_OPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTION_OPTIONS_FAILURE =
  "FETCH_SUBSCRIPTION_OPTIONS_FAILURE";

export const FETCH_ORGANISATION_SUBSCRIPTION_SUCCESS =
  "FETCH_ORGANISATION_ACCOUNTS_SUCCESS";

export const fetchSubscriptionOptionsBegin = () => ({
  type: FETCH_SUBSCRIPTION_OPTIONS_BEGIN,
});

export const fetchSubscriptionOptionsSuccess = (items: string) => ({
  type: FETCH_SUBSCRIPTION_OPTIONS_SUCCESS,
  payload: items,
});

export const fetchSubscriptionOptionsFailure = (error: Error) => ({
  type: FETCH_SUBSCRIPTION_OPTIONS_FAILURE,
  payload: { error },
});

export const fetchOrganisationSubscriptionSuccess = (items: string) => ({
  type: FETCH_ORGANISATION_SUBSCRIPTION_SUCCESS,
  payload: items,
});
export function fetchSubscriptionOptions(
  token: string,
  organisationId: string | undefined
) {
  return (
    dispatch: (arg0: { type: string; payload: string | any }) => void
  ) => {
    const headers = { Authorization: "Bearer " + token };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/subscriptions/options?organisationId=${organisationId}`,
      { headers }
    )
      .then((response) => response.json())
      .then((result) => {
        //if (result.length)
        dispatch(fetchSubscriptionOptionsSuccess(result));
      })
      .catch((error) => dispatch(fetchSubscriptionOptionsFailure(error)));
  };
}

export function fetchOrganisationSubscription(
  token: string,
  id: string | undefined
) {
  return (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const headers = { Authorization: "Bearer " + token };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/accounts/?organisationId=` + id,
      { headers }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.length)
          dispatch(fetchOrganisationSubscriptionSuccess(result));
      });
  };
}
