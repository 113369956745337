export type OpenApiFormValues = {
  title: string;
  notes: string;
  schemaUrl: string;
  public: boolean;
  section: string;
};

export type OpenApiFormData = {
  name: string;
  description: string;
  url: string;
  public: boolean;
  sectionName: string;
};

export function mapOpenApiFormData(data: OpenApiFormValues): OpenApiFormData {
  return {
    name: data.title,
    description: data.notes,
    url: data.schemaUrl,
    public: data.public,
    sectionName: data.section,
  };
}
