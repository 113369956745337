import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
import firebase from "firebase";
type User = firebase.User;

const analytics = Analytics({
  app: "TheReportAPI Prod",
  plugins: [
    segmentPlugin({
      writeKey: `${process.env.REACT_APP_SEGMENT_KEY}`,
    }),
  ],
});

export const identifyUser = (user: User) => {
  analytics.identify(user.uid, {
    name: user.displayName || "",
    email: user.email || "",
    plan: "free", //needs to be updated when we get a user to upgrade
  });
};

export const trackNewUserSignup = (user: User) => {
  analytics.track("Signed Up", {
    userId: user.uid,
    name: user.displayName || "",
    email: user.email || "",
    type: "organic", //needs to be updated when we get advertising traffic
  });
};
export const trackUserSignIn = (user: User) => {
  analytics.track("Signed In", {
    userId: user.uid,
  });
};

type TrackInviteSentParams = {
  uid: string;
  email: string;
  role: string;
  organizationId?: string;
  accountId?: string;
};
export const trackInviteSent = ({
  uid,
  email,
  role,
  organizationId,
  accountId,
}: TrackInviteSentParams) => {
  analytics.track("Invite Sent", {
    userId: uid,
    invitee_email: email,
    invitee_role: role,
    organization_id: organizationId,
    account_id: accountId,
  });
};

type TrackReportViewedParams = {
  userId?: string;
  reportId: string;
  reportType: string;
  reportPublic: string;
};
export const trackReportViewed = ({
  userId,
  reportId,
  reportType,
  reportPublic,
}: TrackReportViewedParams) => {
  analytics.track("Report Viewed", {
    userId,
    report_id: reportId,
    report_type: reportType,
    report_public: reportPublic,
  });
};

type TrackReportCreatedParams = {
  reportType: string;
  reportId: string;
  accountId: string;
  userId: string;
};
export const trackReportCreated = ({
  reportId,
  userId,
  reportType,
  accountId,
}: TrackReportCreatedParams) => {
  analytics.track("Report Created", {
    userId,
    report_id: reportId,
    report_type: reportType,
    account_id: accountId,
  });
};

type TrackReportVisibilityParams = {
  reportType: string;
  reportId: string;
  accountId: string;
  userId: string;
  visible: boolean;
};
export const trackReportVisibility = ({
  reportId,
  userId,
  reportType,
  accountId,
  visible,
}: TrackReportVisibilityParams) => {
  analytics.track("Report Visibility", {
    userId,
    report_id: reportId,
    report_type: reportType,
    visible,
    account_id: accountId,
  });
};

export const trackAccountViewed = (accountId: string, userId?: string) => {
  analytics.track("Account Viewed", {
    userId,
    account_id: accountId,
  });
};

type TrackOrganizationCreatedParams = {
  uid: string;
  organizationId: string;
  name: string;
};
export const trackOrganizationCreated = ({
  uid,
  name,
  organizationId,
}: TrackOrganizationCreatedParams) => {
  analytics.track("Organization Created", {
    userId: uid,
    account_name: name,
    organization_id: organizationId,
  });
};
type TrackAccountCreatedParams = {
  userId: string;
  accountId: string;
  name: string;
};
export const trackAccountCreated = ({
  userId,
  name,
  accountId,
}: TrackAccountCreatedParams) => {
  analytics.track("Account Created", {
    userId,
    account_name: name,
    account_id: accountId,
  });
};

export const trackAccountThemeUpdated = (accountId: string, userId: string) => {
  analytics.track("Account Theme Updated", {
    userId,
    account_id: accountId,
  });
};

export const trackOrganizationThemeUpdated = (
  organizationId: string,
  userId: string
) => {
  analytics.track("Organization Theme Updated", {
    userId,
    organization_id: organizationId,
  });
};

type TrackReportReferralParams = {
  reportId?: string;
  reportType?: string;
  accountId: string;
};
export const trackReportReferral = ({
  reportId,
  reportType,
  accountId,
}: TrackReportReferralParams) => {
  analytics.track("Report Referral", {
    report_id: reportId,
    report_type: reportType,
    account_id: accountId,
  });
};

export const trackPage = (title: string) => {
  analytics.page({
    title,
  });
};
