import React from "react";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import {
  mapOpenApiFormData,
  OpenApiFormData,
  OpenApiFormValues,
} from "./form.types";
import { setCreateReportType } from "../../../../../slices/createReportModalSlice";
import { useForm } from "react-hook-form";
import { CreateOpenApiReportFormView } from "./CreateOpenApiReportFormView";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  title: yup.string().required(),
  notes: yup.string().optional().default(""),
  schemaUrl: yup.string().url().required("schema url must be a valid url"),
  section: yup.string().required(),
  public: yup.boolean().required(),
});

type CreateOpenApiReportForm = {
  defaultValues?: OpenApiFormValues;
  handleSubmit: (data: OpenApiFormData) => Promise<void>;
};
export function CreateOpenApiReportForm({
  defaultValues,
  handleSubmit,
}: CreateOpenApiReportForm) {
  const dispatch = useAppDispatch();

  async function onSubmit(data: OpenApiFormValues) {
    return await handleSubmit(mapOpenApiFormData(data));
  }

  function handleBackClick() {
    dispatch(setCreateReportType(null));
  }

  const form = useForm<OpenApiFormValues>({
    mode: "onChange",
    defaultValues: defaultValues ?? {
      title: "",
      notes: "",
      schemaUrl: "",
      public: false,
      section: "API Documentation",
    },
    resolver: yupResolver(schema),
  });
  return (
    <CreateOpenApiReportFormView
      form={form}
      onSubmit={onSubmit}
      handleBackClick={handleBackClick}
    />
  );
}
