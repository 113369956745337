import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export function FormSplitter() {
  const classes = useStyles();
  return <div className={classes.divider}></div>;
}

const useStyles = makeStyles(() => ({
  divider: {
    width: "1px",
    height: "100%",
    backgroundColor: "rgba(198, 198, 198, 0.47)",
    justifySelf: "center",
  },
}));
