import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { auth } from "./firebase";
import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PaletteIcon from "@material-ui/icons/Palette";
import PeopleIcon from "@material-ui/icons/People";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "./Header";
import Users from "./Users";
import { useDispatch, connect } from "react-redux";
import { fetchAccounts } from "./actions/accountActions";
import { Accounts, Organisations, Reports } from "./shared/types";
import { trackPage } from "./analytics";
import Theme from "./features/settings/Theme";
import { RootState } from "./store/store";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { setAccount } from "./slices/selectedSlice";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  drawerHeader: {
    display: "flex",
    height: "64px",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  loader: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  hideFooter: {
    position: "absolute",
    width: "100%",
    height: "26px",
    background: "#fafafa",
    bottom: "5px",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

interface SettingsModalInterface {
  accounts: Accounts[] | undefined;
  account: Accounts | null;
  organisations: Organisations[];
  loading: boolean;
}

const Settings = ({
  loading,
  accounts,
  account,
  organisations,
}: SettingsModalInterface) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState("users");

  useEffect(() => {
    if (account) {
      document.title = "Account Settings - " + account.name;
      trackPage(document.title);
    }
  }, [account]);

  const handleDrawerOpen = (state: boolean) => {
    setOpen(state);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const setEmptyReport = () => {
    navigate({
      pathname: `/account/${id}`,
      search: `?${query.toString()}`,
    });
  };

  const currentUser = firebase.auth().currentUser;

  auth.onAuthStateChanged((currentUser) => {
    if (currentUser && accounts && accounts.length <= 0) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          dispatch(fetchAccounts(token));
        });
    }
  });

  useEffect(() => {
    if (currentUser && id && accounts && accounts.length <= 0) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          dispatch(fetchAccounts(token));
        });
    } else if (currentUser && id && accounts && accounts.length > 0) {
      const currentAccount = accounts.find((x) => x.id === id);
      dispatch(setAccount(currentAccount));
    }
  }, [currentUser, id, accounts]);

  if (!account) return <></>;

  return (
    <div className={classes.root}>
      <CssBaseline />
      {loading && !account ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Header
            setEmptyReport={setEmptyReport}
            user={currentUser}
            accounts={accounts}
            account={account}
            open={open}
            organisations={organisations}
            handleDrawerOpen={handleDrawerOpen}
            isDashboard={false}
            report={null}
            reportData={null}
            setSelectedReport={null}
            settingOption={false}
            AccountOption={false}
            AvtarOption={false}
            handleSelectOrganisation={function (organisationId: string): void {
              throw new Error("Function not implemented.");
            }}
            setUpdateAccount={function (isDirty: boolean): void {
              throw new Error("Function not implemented.");
            }}
          ></Header>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.drawerHeader}>&nbsp;</div>
            <Divider />
            <List>
              <ListItem>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to={`/account/${id}`}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Back to Reports" />
                </Link>
              </ListItem>
              <ListItem
                selected={selectedItem === "users"}
                onClick={() => setSelectedItem("users")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </Link>
              </ListItem>
              <ListItem
                selected={selectedItem === "theme"}
                onClick={() => setSelectedItem("theme")}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                  }}
                  to="#"
                >
                  <ListItemIcon>
                    <PaletteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Theme" />
                </Link>
              </ListItem>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {selectedItem === "users" && <Users isSettings={true} />}
            {selectedItem === "theme" && (
              <Theme isAllowedToEdit={!account.editor} />
            )}
          </main>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accounts: state.accounts.accounts,
    account: state.selected.account,
    organisations: state.accounts.organisations,
    loading: state.accounts.loading,
    error: state.accounts.error,
  };
};

export default connect(mapStateToProps)(Settings);
