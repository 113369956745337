import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

type CreateReportTutorialCardProps = {
  url: string;
  video: string;
};
export function CreateReportTutorialCard({
  url,
  video,
}: CreateReportTutorialCardProps) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <iframe
        src={video}
        width="100%"
        allow="autoplay; fullscreen"
        className={classes.iframe}
      />
      <Button
        href={url}
        style={{
          width: "100%",
          color: "#2F80ED",
          borderColor: "#2F80ED",
        }}
        variant="outlined"
      >
        Support Documents
      </Button>
    </Card>
  );
}

const useStyles = makeStyles(() => ({
  card: {
    width: "18.75rem",
    height: "16.875rem",
    background:
      "linear-gradient(137deg, rgba(49, 172, 241, 0.11) 0%, rgba(160, 41, 163, 0.21) 100%), #FFF",
    padding: "1.5rem",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.5rem",
  },
  iframe: {
    border: "1px solid #9747FF",
    borderRadius: "1px",
  },
}));
