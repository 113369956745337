import React, { Component, createContext } from "react";
import { auth } from "./firebase";

export const UserContext = createContext({
  user: null,
  loading: true,
  getIdToken: async () => null,
  getIdTokenResult: async () => null,
  logout: async () => null,
});

class UserProvider extends Component {
  state = {
    user: null,
    loading: true,
  };

  componentDidMount() {
    auth.onAuthStateChanged((userAuth) => {
      this.setState({ user: userAuth, loading: false });
    });
  }

  handleAuthError(err) {
    console.warn("Got auth error, signing out", err);
    auth.signOut();
  }

  async logout() {
    this.setState((state) => {
      try {
        auth.signOut().then(function () {
          localStorage.clear();
        });
      } catch (e) {
        console.log(e);
      }
      return { user: null, loading: false };
    });
  }

  render() {
    return this.state.loading ? null : (
      <UserContext.Provider
        value={{
          user: this.state.user,
          loading: this.state.loading,
          getIdToken: async () =>
            auth.currentUser
              ? await auth.currentUser.getIdToken().catch(this.handleAuthError)
              : null,
          getIdTokenResult: async () =>
            auth.currentUser
              ? await auth.currentUser
                  .getIdTokenResult()
                  .catch(this.handleAuthError)
              : null,
          logout: async () => await this.logout(),
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
