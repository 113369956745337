import React from "react";
import { ReportFrame } from "./ReportFrame";
import { Accounts, Reports } from "../shared/types";

export interface ReportProps {
  report: Reports;
  account: Accounts;
}

const Report = ({ report, account }: ReportProps) => {
  return (
    <div id="report-outer" style={{ height: "calc(100vh - 84px)" }}>
      <ReportFrame account={account} report={report} />
    </div>
  );
};

export default Report;
