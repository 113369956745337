import { Navigate } from "react-router-dom";
import React, { useContext } from "react";
import { UserContext } from "./user-provider";

export default function PrivateRoute(props) {
  const { element: Component, ...rest } = props;
  const { user } = useContext(UserContext);
  if (user) {
    return <Component />;
  }
  return <Navigate to={{ pathname: "/login" }} />;
  // return (
  //   <Route
  //     {...rest}
  //     render={(routeProps) => {
  //       return user ? (
  //         <Component {...routeProps} />
  //       ) : (
  //         <Navigate  to={{ pathname: "/login", state: { from: routeProps.location } }} />
  //       );
  //     }}
  //   />
  // );
}
