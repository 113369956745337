import React, { useEffect, useContext, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import firebase from "firebase";

import { makeStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";
import { UserContext } from "./user-provider";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Accounts } from "./shared/types";
import { trackPage } from "./analytics";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    height: "64px",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    marginTop: "64px",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  loader: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  noAccounts: {
    marginTop: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
  },
}));

const AcceptInvite = () => {
  const classes = useStyles();

  const { logout } = useContext(UserContext);
  const [account, setAccount] = useState<Accounts | null>(null);
  const [accounts, setAccounts] = useState([]);
  const [open, setOpen] = useState<boolean | null>(true);
  const [loading, setLoading] = useState(true);
  const [error, setErrors] = useState("");
  const [code, setCode] = useState("");
  const [orgName, setOrgName] = useState("");
  const [openMenu, setOpenMenu] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  useEffect(() => {
    if (account) {
      document.title = "Accept Invitation - " + account.name;
      trackPage(document.title);
    }
  }, [account]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();

  const signOut = () => {
    logout()
      .then(() => {
        localStorage.clear();
      })
      .catch(function (error) {
        // An error happened.
      });
    setOpen(null);
    navigate("/");
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };
  const { id } = useParams();
  const submitInviteCode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    firebase
      .auth()!
      .currentUser!.getIdToken()
      .then((token) => {
        const headers = {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/accept-invitation`, {
          method: "POST",
          headers,
          body: JSON.stringify({ code: code }),
        })
          .then(() => {
            setLoading(false);
            navigate("/");
          })
          .catch((e) => {
            setErrors(e.message);
            setLoading(false);
          });
      });
  };

  const signUp = () => {
    setLoading(true);
    firebase
      .auth()!
      .currentUser!.getIdToken()
      .then((token) => {
        const headers = {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/organisations`, {
          method: "POST",
          headers,
          body: JSON.stringify({ name: orgName }),
        })
          .then(() => {
            setLoading(false);
            navigate("/");
          })
          .catch((e) => {
            setErrors(e.message);
            setLoading(false);
          });
      });
  };
  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      setLoading(false);
    });
  }, [setAccounts, navigate, id]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <AppBar position="absolute" className={clsx(classes.appBar, open)}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {!!account?.name}
              </Typography>
              <Button>
                <Avatar
                  ref={anchorRef}
                  aria-haspopup="true"
                  aria-controls={openMenu ? "menu-list-grow" : undefined}
                  onClick={handleToggle}
                >
                  {(account?.name || "X").charAt(0)}
                </Avatar>
              </Button>
              <Popper
                open={openMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                          {accounts &&
                            accounts.map((accnt: Accounts, s) => (
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "unset",
                                }}
                                to={`/account/${accnt.id}`}
                                key={s}
                              >
                                <MenuItem
                                  selected={id === accnt.id}
                                  onClick={(e) => {
                                    setAccount(accnt);
                                    handleClose(e);
                                  }}
                                >
                                  {accnt?.name}
                                </MenuItem>
                              </Link>
                            ))}
                          <Box my={1}>
                            <Divider />
                          </Box>
                          {!!id && (
                            <Link
                              style={{ textDecoration: "none", color: "unset" }}
                              to={`/account/${id}/settings`}
                            >
                              <MenuItem>Settings</MenuItem>
                            </Link>
                          )}
                          <Box my={1}>
                            <Divider />
                          </Box>
                          <MenuItem onClick={signOut}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className={classes.noAccounts}>
                    <Card className={classes.root}>
                      <CardHeader
                        titleTypographyProps={{ variant: "body1" }}
                        title="Enter your emailed Invite Code"
                      />
                      <CardContent className={classes.root}>
                        <Paper component="form" className={classes.root}>
                          <InputBase
                            name="code"
                            onChange={(e) => setCode(e.target.value)}
                            value={code}
                            placeholder=" Invite Code"
                          />
                        </Paper>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => submitInviteCode(e)}
                        >
                          Submit
                        </Button>
                      </CardActions>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </main>
        </>
      )}
    </div>
  );
};

export default AcceptInvite;
