import React from "react";
import { PreviewReportsLayout } from "./types";
import { Box, Button } from "@material-ui/core";

type DashboardLayoutButtonsProps = {
  dashboardType: PreviewReportsLayout;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function DashboardPreviewButtons({
  dashboardType,
  onClick,
}: DashboardLayoutButtonsProps) {
  return (
    <Box display="flex" gridColumnGap="0.75rem">
      {["Grid", "Table"].map((label) => (
        <Button
          key={label}
          name={label}
          onClick={(e) => onClick(e)}
          size="medium"
          variant={
            dashboardType.toLowerCase() === label.toLowerCase()
              ? "contained"
              : "outlined"
          }
          color="primary"
        >
          {label}
        </Button>
      ))}
    </Box>
  );
}
