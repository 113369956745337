import {
  SET_LOADING,
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_INVITATION_BEGIN,
  FETCH_USER_INVITATION_SUCCESS,
  REMOVE_USER_SUCCESS,
  FETCH_ORGANISATION_ACCOUNTS_SUCCESS,
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  EDIT_EMAIL_TEMPLATE_SUCCESS,
  EDIT_EMAIL_TEMPLATE_FAILURE,
  RESET_EMAIL_TEMPLATES,
  UPDATE_USER_ROLE_SUCCESS,
  FETCH_ORGANISATION_ACCOUNTS_BEGIN,
} from "../actions/userActions";

const initialState = {
  users: [],
  organisationAccounts: [],
  emailTemplates: [],
  emailTemplateSuccess: null,
  emailTemplateError: null,
  loading: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }
    case RESET_EMAIL_TEMPLATES: {
      return {
        ...state,
        emailTemplateError: null,
        emailTemplateSuccess: null,
      };
    }
    case FETCH_USERS_BEGIN:
      return {
        ...state,
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case FETCH_USERS_INVITATION_BEGIN:
      return {
        ...state,
        url: null,
      };
    case FETCH_USER_INVITATION_SUCCESS:
      return {
        ...state,
        url: action.payload.url || "",
      };

    case FETCH_ORGANISATION_ACCOUNTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ORGANISATION_ACCOUNTS_SUCCESS:
      return {
        ...state,
        organisationAccounts: action.payload,
        loading: false,
      };

    case FETCH_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplates: action.payload,
      };

    case EDIT_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        emailTemplateSuccess: true,
        emailTemplateError: null,
      };

    case EDIT_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        emailTemplateError: action.payload.error,
        emailTemplateSuccess: null,
      };

    case REMOVE_USER_SUCCESS:
      const users = state.users.filter((item) => item.uid !== action.payload);
      return {
        ...state,
        users,
      };
    case UPDATE_USER_ROLE_SUCCESS:
      const _users = state.users.map((user) => {
        if (user.uid === action.payload.userId) {
          return {
            ...user,
            role: action.payload.role,
          };
        }
        return user;
      });
      return {
        ...state,
        users: _users,
      };
    default:
      return state;
  }
}
