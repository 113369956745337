import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CreateReportHeader } from "../CreateReportHeader";
import OpenApiSVG from "../../../../../assets/svg/create-report/openapi.svg";
import { CreateReportParams } from "../../../types";
import Grid from "@material-ui/core/Grid";
import { CreateOpenApiReportForm } from "./CreateOpenApiReportForm";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { Reports } from "../../../../../shared/types";
import {
  reportCreated,
  reportUpdated,
} from "../../../../../actions/accountActions";
import { closeCreateReportModal } from "../../../../../slices/createReportModalSlice";
import { useNavigate, useParams } from "react-router-dom";
import { createReport, updateReport } from "../../../api";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { OpenApiFormData, OpenApiFormValues } from "./form.types";

export function CreateOpenApiReport() {
  const dispatch = useAppDispatch();
  const { id: accountId } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const editedReport = useAppSelector(
    (state) => state.createReportModal.editedReport
  );

  const defaultValues: OpenApiFormValues | undefined = editedReport
    ? {
        title: editedReport.name,
        notes: editedReport.description,
        schemaUrl: editedReport.url,
        public: editedReport.public,
        section: editedReport.sectionName,
      }
    : undefined;

  function handleReportUpdate(updatedReport: Reports) {
    dispatch(reportUpdated(updatedReport));
    dispatch(closeCreateReportModal());
  }

  function handleReportCreated(createdReport: Reports) {
    dispatch(reportCreated(createdReport));
    dispatch(closeCreateReportModal());
    navigate(`/account/${accountId}?report=${createdReport.id}`);
  }

  function handleSubmit(data: OpenApiFormData) {
    const report: CreateReportParams = {
      ...data,
      accountId: accountId ?? "",
      type: "openapi-spec",
    };
    if (editedReport) {
      return updateReport(editedReport.id, report).then(handleReportUpdate);
    }
    return createReport(report).then(handleReportCreated);
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <CreateReportHeader logo={OpenApiSVG} />
      <CreateOpenApiReportForm
        handleSubmit={handleSubmit}
        defaultValues={defaultValues}
      />
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    gap: "1.25rem",
  },
}));
