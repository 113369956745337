import React from "react";
import Button from "@material-ui/core/Button";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Accounts, Reports } from "./shared/types";

interface IReportPreviewTable {
  account: Accounts | null;
  handleSelectReport: (report: Reports) => void;
  report?: Reports;
}
function truncate(str: string, n: number): string {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}
const ReportPreviewTable = (props: IReportPreviewTable) => {
  const { account, handleSelectReport } = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {account?.sections.map((section, s) =>
              section.reports.map((report) => (
                <TableRow key={report.id}>
                  <TableCell component="th" scope="row">
                    <strong>{report.name}</strong>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <i>{truncate(report.description || "", 100)}</i>
                  </TableCell>
                  <TableCell align="right">
                    {report.type === "link" ? (
                      <Button
                        component="a"
                        href={report.url.replace(
                          /^(?:(.*:)?\/\/)?(.*)/i,
                          (match, schemma, nonSchemmaUrl) =>
                            schemma ? match : `http://${nonSchemmaUrl}`
                        )}
                        target="_blank"
                        size="small"
                        color="primary"
                        variant="contained"
                      >
                        VIEW
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSelectReport(report)}
                      >
                        VIEW
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportPreviewTable;
