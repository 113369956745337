import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch, connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress } from "@material-ui/core";
import { TextField, FormControl, Typography } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import { fetchEmailTemplates, editEmailTemplate } from "./actions/userActions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  loader: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
}));

const EmailTemplates = (props) => {
  const { handleSubmit, control, errors: fieldsErrors } = useForm();
  const [editTemplate, setEditTemplate] = useState({});
  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const [stateEmailTemplateError, setStateEmailTemplateError] = useState();
  const { emailTemplates, loading, emailTemplateSuccess, emailTemplateError } =
    props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = useParams();

  const currentUser = firebase.auth().currentUser;

  const getEmailTemplates = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        dispatch(fetchEmailTemplates(token, id));
      });
  };

  const callUpdateTemplate = (data) => {
    if (currentUser) {
      if (editTemplate.type) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            data.type = editTemplate.type;
            dispatch(editEmailTemplate(token, id, data));
          });
      }
    }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  useEffect(() => {
    if (currentUser && id) {
      getEmailTemplates();
    }
  }, [currentUser, id]);

  useEffect(() => {
    if (emailTemplateSuccess) {
      setShowEditTemplate(false);
    }
    if (emailTemplateError) {
      setStateEmailTemplateError(emailTemplateError);
    }
  }, [emailTemplateSuccess, emailTemplateError]);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box fontWeight="fontWeightBold">Subject</Box>
                  </TableCell>
                  <TableCell>
                    <Box fontWeight="fontWeightBold" align="right">
                      Type
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box></Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {emailTemplates &&
                  emailTemplates.map((template, s) => (
                    <TableRow key={s}>
                      <TableCell component="th" scope="row">
                        {template.subject}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: "right" }}
                      >
                        {template.type}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShowEditTemplate(true);
                            setEditTemplate(template);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={showEditTemplate}
            onClose={() => {
              setStateEmailTemplateError();
              setShowEditTemplate(false);
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle
              id="form-dialog-title"
              onClose={() => {
                setStateEmailTemplateError();
                setShowEditTemplate(false);
              }}
            >
              <span style={{ whiteSpace: "pre-wrap" }}>Update Template </span>
            </DialogTitle>
            <form onSubmit={handleSubmit(callUpdateTemplate)}>
              <DialogContent style={{ paddingTop: 0 }}>
                {stateEmailTemplateError !== null && (
                  <FormLabel error={true}>{stateEmailTemplateError}</FormLabel>
                )}
                <FormControl fullWidth>
                  <Controller
                    name="subject"
                    style={{ marginTop: 10, paddingBottom: 20 }}
                    as={
                      <TextField
                        id="subject"
                        helperText={
                          fieldsErrors?.subject
                            ? fieldsErrors?.subject?.message
                            : null
                        }
                        label="Subject"
                        name="subject"
                        fullWidth
                        error={fieldsErrors?.subject}
                      />
                    }
                    defaultValue={editTemplate.subject || ""}
                    control={control}
                    rules={{
                      required: "Required",
                      minLength: {
                        value: 1,
                        message: "The minimum length should be 1",
                      },
                      maxLength: {
                        value: 255,
                        message: "The maximum length can't exceed be 255",
                      },
                    }}
                  />
                  <Controller
                    name="body"
                    as={
                      <TextField
                        id="subject"
                        helperText={
                          fieldsErrors?.body ? fieldsErrors?.body?.message : null
                        }
                        label="Body"
                        name="body"
                        multiline
                        rows={5}
                        fullWidth
                        error={fieldsErrors?.body}
                      />
                    }
                    defaultValue={editTemplate.body || ""}
                    control={control}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setStateEmailTemplateError();
                    setShowEditTemplate(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  value="submit"
                  color="primary"
                >
                  {loading ? (
                    <div className={classes.loader}>
                      <CircularProgress size={20} color="white" />
                    </div>
                  ) : (
                    <span>Save</span>
                  )}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    loading: state.users.loading,
    emailTemplates: state.users.emailTemplates,
    emailTemplateSuccess: state.users.emailTemplateSuccess,
    emailTemplateError: state.users.emailTemplateError,
  };
};

export default connect(mapStateToProps)(EmailTemplates);
