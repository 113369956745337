import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { routes, openRoutes } from "./routes.js";
import UserProvider from "./user-provider";
import PrivateRoute from "./private-route";
import { Provider, useDispatch } from "react-redux";
import { ConfirmProvider } from "material-ui-confirm";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ChatboxPosition, Crisp } from "crisp-sdk-web";
import { store } from "./store/store";
import { fetchDomainConfig } from "./actions/accountActions";
import { Alert, AlertTitle } from "@material-ui/lab";

Sentry.init({
  dsn: "https://d448167783b3497f93231b6b736f5b1d@o439042.ingest.sentry.io/5758229",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function AppRoutes() {
  return (
    <Routes>
      {openRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          element={route.main}
        />
      ))}
      {routes.map((route, i) => (
        <Route
          path={route.path}
          key={i}
          element={
            <PrivateRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              element={route.main}
            />
          }
        />
      ))}
      <Route
        path="*"
        element={
          <Alert severity="error">
            <AlertTitle>Error 404</AlertTitle>
            This page does not exist
          </Alert>
        }
      />
    </Routes>
  );
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID);
    Crisp.setPosition(ChatboxPosition.Right);
  }, []);

  useEffect(() => {
    dispatch(fetchDomainConfig());
  }, []);

  return (
    <ConfirmProvider>
      <UserProvider>
        <div className="App">
          <Router>
            <AppRoutes />
          </Router>
        </div>
      </UserProvider>
    </ConfirmProvider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);
