import React from "react";

const PeopleIcon = (props: any) => {
  return (
    <svg
      style={{ fill: "white", marginRight: "5px" }}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      width={20}
      height={20}
      {...props}
    >
      <title>{"Essential Icon Set Outline"}</title>
      <path d="M36.297 31.891A13.662 13.662 0 1049.96 18.23 13.679 13.679 0 0036.297 31.89zm24.029 0A10.366 10.366 0 1149.96 21.525 10.366 10.366 0 0160.326 31.89z" />
      <path d="M77.844 46.954a19.777 19.777 0 00-12.937 4.813 25.067 25.067 0 00-29.895 0A19.875 19.875 0 002.166 66.863v4.812a1.648 1.648 0 001.648 1.648h20.947v4.944a1.648 1.648 0 001.648 1.648H73.51a1.648 1.648 0 001.648-1.648v-4.944h20.947a1.648 1.648 0 001.648-1.648v-4.812a19.925 19.925 0 00-19.909-19.909zM5.462 66.863a16.612 16.612 0 0126.995-12.97l-.33.362-.642.676c-.363.395-.725.807-1.071 1.22l-.577.741c-.346.445-.66.906-.956 1.368-.297.461-.33.478-.478.725-.33.56-.66 1.154-.956 1.747 0 .148-.165.297-.23.445a28.776 28.776 0 00-.973 2.324c0 .197-.115.395-.181.593-.198.593-.38 1.203-.528 1.813-.082.28-.131.577-.197.857a18.096 18.096 0 00-.28 1.648c-.066.56-.083.626-.116.94-.033.312-.082.46-.099.691H5.463zm22.595 9.707v-4.648a1.088 1.088 0 000-.23A21.886 21.886 0 0163.902 55.21c.527.461 1.038.923 1.516 1.4a21.721 21.721 0 016.411 15.163v4.796zm66.4-6.593H75.076V69.5c0-.165-.083-.676-.116-1.022s-.197-.808-.28-1.302c-.082-.495-.132-.676-.214-1.006-.082-.33-.214-.84-.33-1.269l-.296-.972a36.771 36.771 0 00-.462-1.22c-.165-.411-.247-.626-.379-.922-.132-.297-.379-.808-.577-1.203-.197-.396-.28-.594-.445-.874-.164-.28-.478-.79-.725-1.186-.247-.396-.313-.528-.494-.775s-.61-.84-.94-1.253c-.33-.412-.296-.412-.461-.61-.494-.593-1.022-1.186-1.648-1.746l-.247-.149a16.612 16.612 0 0126.995 12.872z" />
      <path d="M77.844 24.08a10.976 10.976 0 1010.96 10.959 10.96 10.96 0 00-10.96-10.96zm0 18.639a7.68 7.68 0 117.664-7.68 7.68 7.68 0 01-7.664 7.68zM11.115 35.039a10.96 10.96 0 1010.96-10.96 10.976 10.976 0 00-10.96 10.96zm18.623 0a7.663 7.663 0 11-7.663-7.663 7.68 7.68 0 017.663 7.663z" />
    </svg>
  );
};

export default PeopleIcon;
