import React from "react";
import { OpenApiFormValues } from "./form.types";
import { FormSectionTitle } from "../Form/FormSectionTitle";
import { FormInputText } from "../FormInput/FormInputText";
import { Chip, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { FormInputLabel } from "../FormInput/FormInputLabel";
import { FormInputCheckbox } from "../FormInput/FormInputCheckbox";
import { FormSplitter } from "../Form/FormSplitter";
import { FormInputSelect } from "../FormInput/FormInputSelect";
import { reportSections } from "../reportSections";
import { FormButton } from "../Form/FormButton";
import { makeStyles } from "@material-ui/core/styles";
import { UseFormReturn } from "react-hook-form";

type CreateOpenApiReportFormViewProps = {
  form: UseFormReturn<OpenApiFormValues>;
  handleBackClick: () => void;
  onSubmit: (data: OpenApiFormValues) => void;
};
export function CreateOpenApiReportFormView({
  form,
  onSubmit,
  handleBackClick,
}: CreateOpenApiReportFormViewProps) {
  const { control, handleSubmit } = form;
  const classes = useStyles();
  const submitDisabled =
    !form.formState.isValid ||
    (!form.formState.isDirty && form.formState.defaultValues?.title !== "");
  const showBackButton = form.formState.defaultValues?.title === "";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.outerContainer}>
        <div className={classes.leftContainer}>
          <FormSectionTitle value="Settings" />
          <FormInputText
            name="title"
            label="title"
            control={control}
            placeholder="How to access your API Key"
            error={form.formState.errors.title?.message}
            disabled={form.formState.isSubmitting}
            required
          />
          <FormInputText
            name="notes"
            label="notes"
            control={control}
            placeholder="How to guide for users to get their API Key"
            error={form.formState.errors.notes?.message}
            disabled={form.formState.isSubmitting}
          />
          <FormInputText
            name="schemaUrl"
            label="Schema URL"
            control={control}
            placeholder="https://api.reportwrapper.com/openapi"
            docsUrl="https://swagger.io/specification/"
            error={form.formState.errors.schemaUrl?.message}
            disabled={form.formState.isSubmitting}
          />
          <Box display="flex" flexDirection="column" gridGap="0.5rem">
            <FormInputLabel value="Publishing" />
            <Typography variant="caption">
              Decide how you want to share the report
            </Typography>
            <FormInputCheckbox
              control={control}
              name="publishing"
              label="Notify teammates when I save"
              disabled
            />
            <Chip
              size="small"
              label="Coming soon"
              color="secondary"
              disabled
              variant="outlined"
              style={{ width: "fit-content" }}
            />
            <FormInputCheckbox
              control={control}
              name="public"
              label="Make page public"
              disabled={form.formState.isSubmitting}
            />
          </Box>
        </div>
        <FormSplitter />
        <div className={classes.leftContainer}>
          <FormSectionTitle value="Display Options" />
          <FormInputSelect
            name="section"
            control={control}
            label="Section"
            placeholder="Support Documents"
            options={reportSections}
            disabled={form.formState.isSubmitting}
          />
        </div>
      </div>
      <Box
        display="flex"
        justifyContent={showBackButton ? "space-between" : "flex-end"}
        marginTop="1.5rem"
      >
        {showBackButton && (
          <FormButton
            value="Back"
            onClick={handleBackClick}
            isLoading={form.formState.isSubmitting}
          />
        )}
        <FormButton
          value="Submit"
          isSubmit
          isLoading={form.formState.isSubmitting}
          disabled={submitDisabled}
        />
      </Box>
    </form>
  );
}

const useStyles = makeStyles(() => ({
  outerContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5.25rem 1fr",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
  },
}));
