import React from "react";
import Login from "./Login";
import PublicReport from "./features/report/components/PublicReport";
import EmbeddedReport from "./EmbeddedReport";
import Organisation from "./Organisation";
import AcceptInvite from "./AcceptInvite";
import Settings from "./Settings";
import ResetPassword from "./components/ResetPassword";
import PublicDashboard from "./features/PublicDashboard/PublicDashboard";
import CreateOrganisation from "./features/organisation/CreateOrganisation";
import { Dashboard } from "./features/Dashboard";

const openRoutes = [
  { name: "Login", path: "/login", exact: true, main: <Login /> },
  {
    name: "Signup",
    path: "/signup",
    exact: true,
    main: <Login isSignup={true} />,
  },
  {
    name: "PublicReport",
    path: "/view/:reportId",
    exact: true,
    main: <PublicReport />,
  },
  {
    name: "PublicAccount",
    path: "/view-account/:accountId",
    exact: true,
    main: <PublicDashboard />,
  },
  {
    name: "EmbeddedReport",
    path: "/embed/:reportId",
    exact: true,
    main: <EmbeddedReport />,
  },
  {
    name: "ResetPassword",
    path: "/resetpassword",
    exact: true,
    main: <ResetPassword />,
  },
];

const routes = [
  { name: "Dashboard", path: "/", exact: true, main: () => <Dashboard /> },
  {
    name: "DashboardFirstRun",
    path: "/first-run",
    exact: true,
    main: () => <Dashboard />,
  },
  {
    name: "Settings",
    path: "/account/:id/settings",
    exact: true,
    main: () => <Settings />,
  },
  {
    name: "Dashboard",
    path: "/account/:id",
    exact: true,
    main: () => <Dashboard />,
  },
  {
    name: "Organisation",
    path: "/organisation/:id/*",
    exact: true,
    main: () => <Organisation />,
  },
  {
    name: "OrganisationSubscription",
    path: "/organisation/:id/subscription",
    exact: true,
    main: () => <Organisation />,
  },
  {
    name: "AcceptInvite",
    path: "/accept-invite",
    exact: true,
    main: () => <AcceptInvite />,
  },
  {
    name: "NewOrg",
    path: "/new-organisation",
    exact: true,
    main: () => <CreateOrganisation />,
  },
];

export { openRoutes, routes };
