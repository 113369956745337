import { ReportCardType } from "../../types";
import DocumentationIcon from "../../../../assets/svg/create-report/documentation.svg";
import ReportingIcon from "../../../../assets/svg/create-report/reporting.svg";
import ApplicationsIcon from "../../../../assets/svg/create-report/applications.svg";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const reportIconHeaderIcons: Record<ReportCardType, string> = {
  documentation: DocumentationIcon,
  reporting: ReportingIcon,
  applications: ApplicationsIcon,
};
function ReportTemplateHeaderIcons({ name }: { name: ReportCardType }) {
  return <img src={reportIconHeaderIcons[name]} alt="" />;
}

type CreateReportHeaderProps = {
  title: ReportCardType;
};
export function CreateReportCardHeader({ title }: CreateReportHeaderProps) {
  const classes = useStyles();

  return (
    <Box display="flex" gridColumnGap="0.75rem" alignItems="center">
      <ReportTemplateHeaderIcons name={title} />
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    textTransform: "capitalize",
    color: "#36454F",
  },
}));
