import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../../Header";

import { connect } from "react-redux";
import {
  fetchAccount,
  fetchAccounts,
  resetAccounts,
} from "../../actions/accountActions";
import {
  trackReportViewed,
  trackAccountViewed,
  identifyUser,
  trackPage,
} from "../../analytics";
import Quote from "../../Quote";
import { CircularProgress } from "@material-ui/core";

import {
  Accounts,
  DomainConfig,
  Organisations,
  Reports,
} from "../../shared/types";
import { fetchOrganisationAccounts } from "../../actions/userActions";
import { getUserToken, getCurrentUser } from "../../shared/api";
import { deleteReport } from "../report/api";
import {
  setAccount,
  setOrganisation,
  setReport,
} from "../../slices/selectedSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import UIDrawer from "../../components/UI/UIDrawer";
import { RootState } from "../../store/store";
import { DashboardReports } from "./DashboardReports";
import { CreateReportModal } from "../report/components/CreateReportModal/CreateReportModal";
import {
  openCreateReportModal,
  setEditedReport,
} from "../../slices/createReportModalSlice";
import { useConfirm } from "material-ui-confirm";

const drawerWidth = 240;

interface IDashboardProps {
  domainConfig?: DomainConfig;
  accounts: Accounts[];
  organisations: Organisations[];
  reports: Reports;
  loading: boolean;
  error: Object;
  organisationAccounts: Accounts[];
  selectedReport: Reports | null;
}

interface IState {
  loading: boolean;
}

const Dashboard = ({
  loading,
  accounts,
  organisations,
  domainConfig,
  organisationAccounts,
  error,
  selectedReport,
}: IDashboardProps & IState) => {
  const classes = useStyles({ colour1: "#000" });
  const dispatch = useAppDispatch();
  const [updateAccount, setUpdateAccount] = useState<boolean>(false);
  const [reportData, setReportData] = useState<Reports | null>(null);
  const [open, setOpen] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const account = useAppSelector((state) => state.selected.account);
  const organisation = useAppSelector((state) => state.selected.organisation);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const confirm = useConfirm();


  const handleOpenDialog = (report: Reports) => {
    if (report.id) {
      setReportData(report);
      setOpenDialog(true);
    } else {
      setReportData({
        id: "",
        name: "",
        description: "",
        type: "iframe",
        url: "",
        width: "",
        height: 1000,
        sectionName: report.sectionName ? report.sectionName : "Reports",
        public: false,
        allowFullWidth: true,
        previewUrl: "",
      });
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSelectAccount = (selectedAccount: Accounts) => {
    dispatch(setAccount(selectedAccount));
    localStorage.setItem("accountId", selectedAccount.id);
    if (account && account.id !== selectedAccount.id && getCurrentUser()!.uid) {
      trackAccountViewed(selectedAccount.id, getCurrentUser()!.uid);
    }
  };

  const handleSelectOrganisation = (organisationId: string) => {
    dispatch(
      setOrganisation(organisations.find((o) => o.id === organisationId))
    );
    getUserToken().then((token) => {
      dispatch(fetchOrganisationAccounts(token, organisationId));
    });
  };

  const handleSelectReport = (report: Reports) => {
    trackReportViewed({
      userId: getCurrentUser()!.uid,
      reportId: report.id,
      reportType: report.type,
      reportPublic: "private",
    });

    if (report.type === "link") {
      return;
    }

    const query = new URLSearchParams(location.search);
    if (report) {
      query.set("report", report.id);
    } else {
      query.set("", "");
    }

    navigate({
      pathname: location.pathname,
      search: `?${query.toString()}`,
    });
  };

  const setEmptyReport = () => {
    dispatch(setReport(null));
    const query = new URLSearchParams(location.search);
    query.delete("report");
    navigate({
      pathname: location.pathname,
      search: `?${query.toString()}`,
    });
  };

  const handleDeleteReport = (report) => {

    const rData  = report || reportData;
      console.log('handleDeleteReport',report)

    confirm({ description: "Are you sure you want to remove the user?" }).then(
      () => {

        deleteReport(rData!.id).then(() => {
          handleCloseDialog();
          setUpdateAccount(true);
        });
      }
    );
  };

  const handleDrawerOpen = (state: boolean) => {
    setOpen(state);
  };

  // selects a report if the url contains a report id
  useEffect(() => {
    if (account) {
      const query = new URLSearchParams(location.search);
      if (query.get("report")) {
        account.sections.forEach((section) => {
          const report = section.reports.find(
            (x: Reports) => x.id === query.get("report")
          );
          if (report) {
            dispatch(setReport(report));
            return;
          }
        });
      } else {
        dispatch(setReport(null));
      }
    }
  }, [location, account]);

  // fetch organisation accounts for a user and selects an organisation
  useEffect(() => {
    if (organisations.length === 0) {
      // still loading
      return;
    }
    const selectedOrganisation = organisation ?? organisations[0];
    if (!organisation) {
      dispatch(setOrganisation(selectedOrganisation));
    }
    getUserToken().then((token) => {
      dispatch(fetchOrganisationAccounts(token, selectedOrganisation.id));
    });
  }, [dispatch, id, organisations.length]);

  // this useEffect is used to fetch the accounts one initial render
  useEffect(() => {
    if (accounts.length > 0) {
      return;
    }
    const currentUser = getCurrentUser();
    identifyUser(currentUser!);
    getUserToken().then((token) => {
      dispatch(fetchAccounts(token));
    });
  }, []);

  // handle select account or redirect to create new org
  useEffect(() => {
    if (
      loading ||
      (organisationAccounts.length === 0 && organisations.length > 0)
    ) {
      return;
    }
    if (accounts.length === 0) {
      // handle redirect
      if (domainConfig?.allowNewOrgCreation) {
        navigate("/new-organisation");
      } else {
        navigate("/accept-invite");
      }
      dispatch(resetAccounts());
    } else {
      if (id) {
        // we have the accountId, just select that account
        const requestedAccount =
          accounts.find((account) => account.id === id) ?? accounts[0];
        handleSelectAccount(requestedAccount);
      } else {
        // set selected account
        const query = new URLSearchParams(location.search);
        const accountId = localStorage.getItem("accountId");
        // if (organisation.length === 0) then user has access to only 1 account
        const tempId =
          organisations.length === 0
            ? accounts[0].id
            : organisationAccounts[0]?.id;
        let aid =
          accountId && organisationAccounts.some((x) => x.id === accountId)
            ? accountId
            : tempId;
        navigate({
          pathname: `/account/${aid}`,
          search: `?${query.toString()}`,
        });
      }
    }
  }, [accounts, loading, organisationAccounts.length, id]);

  //updateAccount
  useEffect(() => {
    if (updateAccount) {
      getUserToken().then((token: string) => {
        dispatch(fetchAccount(token, id));
        setUpdateAccount(false);
      });
    }
  }, [accounts, loading, organisations, navigate, id, updateAccount]);

  useEffect(() => {
    if (selectedReport) {
      handleSelectReport(selectedReport);
      document.title = "Report - " + selectedReport.name;
    } else {
      document.title = "Reports";
    }
    if (!loading) {
      trackPage(document.title);
    }
  }, [selectedReport?.id, loading]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
          <Quote />
        </div>
      ) : (
        <>
          <Header
            reportData={reportData}
            report={selectedReport}
            accounts={accounts}
            account={account}
            open={open}
            user={getCurrentUser()}
            organisations={organisations}
            isDashboard={true}
            handleDrawerOpen={handleDrawerOpen}
            setSelectedReport={selectedReport}
            setEmptyReport={setEmptyReport}
            AvtarOption={true}
            settingOption={true}
            AccountOption={true}
            handleSelectOrganisation={handleSelectOrganisation}
            setUpdateAccount={setUpdateAccount}
          ></Header>
          <UIDrawer
            open={open}
            account={account}
            handleOpenDialog={(report) => {
              if (report) {
                dispatch(setEditedReport(report));
              }
              dispatch(openCreateReportModal());
            }}
            handleDeleteReport={handleDeleteReport}
            setUpdateAccount={setUpdateAccount}
          />
          <DashboardReports />
          <CreateReportModal />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  container: {
    paddingTop: theme.spacing(4, 0),
    paddingBottom: theme.spacing(4, 0),
  },
  loader: {
    marginTop: theme.spacing(25, 0),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  hideFooter: {
    position: "absolute",
    width: "100%",
    height: "26px",
    background: "#fafafa",
    bottom: 0,
  },
  add_button: {
    left: 0,
    right: 0,
    bottom: 0,
    position: "sticky",
    zIndex: 1,
    width: "100%",
    background: "#fff",
  },
  button: {
    margin: theme.spacing(1, 0),
    left: "17%",
  },
  hidden: { display: "none" },
  resizable: {
    resize: "both",
    overflow: "auto",
    position: "relative",
  },
  setColor: (props: { colour1: string }) => ({
    color: props.colour1,
  }),
  listItemText: {
    fontSize: "1em",
  },
}));

const mapStateToProps = (state: RootState) => {
  return {
    domainConfig: state.accounts.domainConfig,
    accounts: state.accounts.accounts,
    organisations: state.accounts.organisations,
    organisationAccounts: state.users.organisationAccounts,
    loading: state.accounts.loading || state.users.loading,
    error: state.accounts.error,
    selectedReport: state.selected.report,
  };
};

export default connect(mapStateToProps)(Dashboard);
