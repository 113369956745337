import { PublicAccount } from "./types";
import { getApiHeaders } from "../../shared/api";

export async function getAccount(accountId: string): Promise<PublicAccount> {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/accounts/${accountId}`,
    {
      method: "GET",
    }
  );
  const data = await response.json();
  if (response.status !== 200) {
    throw new Error(data.message);
  }
  return data;
}

export async function updateAccountPublic(
  accountId: string,
  isPublic: boolean
) {
  const headers = await getApiHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/accounts/${accountId}`,
    {
      body: JSON.stringify({
        public: isPublic,
      }),
      headers,
      method: "POST",
    }
  );
  return response.json();
}
