import { useForm } from "react-hook-form";
import React from "react";
import { CreateExternalLinkFormView } from "./CreateExternalLinkFormView";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { setCreateReportType } from "../../../../../slices/createReportModalSlice";
import {
  ExternalLinkFormData,
  ExternalLinkFormValues,
  mapExternalLinkFormData,
} from "./form.types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  title: yup.string().required(),
  url: yup.string().url().required(),
  section: yup.string().required(),
});

type CreateExternalLinkFormProps = {
  handleSubmit(data: ExternalLinkFormData): Promise<void>;
  defaultValues?: ExternalLinkFormValues;
};
export function CreateExternalLinkForm({
  defaultValues,
  handleSubmit,
}: CreateExternalLinkFormProps) {
  const dispatch = useAppDispatch();
  const form = useForm<ExternalLinkFormValues>({
    mode: "onChange",
    defaultValues: defaultValues ?? {
      title: "",
      section: "Reports",
      url: "",
    },
    resolver: yupResolver(schema),
  });

  function onSubmit(data: ExternalLinkFormValues) {
    return handleSubmit(mapExternalLinkFormData(data));
  }

  function handleBackClick() {
    dispatch(setCreateReportType(null));
  }

  return (
    <CreateExternalLinkFormView
      form={form}
      onSubmit={onSubmit}
      handleBackClick={handleBackClick}
    />
  );
}
