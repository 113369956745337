import React from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { closeCreateReportModal } from "../../../../slices/createReportModalSlice";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { InfoOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

type CreateReportHeaderProps = {
  logo?: string;
  title?: string;
  style?: React.CSSProperties;
};
export function CreateReportHeader({
  title,
  style,
  logo,
}: CreateReportHeaderProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  function handleClose() {
    dispatch(closeCreateReportModal());
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={style}
    >
      <Grid
        container
        alignItems="center"
        style={{
          width: "fit-content",
        }}
      >
        {logo && <img className={classes.logo} src={logo} alt="" />}
        {title && <Typography className={classes.title}>{title}</Typography>}
      </Grid>
      <Box>
        <IconButton
          className={classes.headerIconButton}
          href="http://support.reportwrapper.com/"
          target="_blank"
        >
          <InfoOutlined />
        </IconButton>
        <IconButton className={classes.headerIconButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  headerIconButton: {
    width: "2.5rem",
    height: "2.5rem",
  },
  logo: {
    maxWidth: "7.5rem",
  },
  title: {
    fontSize: "1.5rem",
    marginLeft: "1rem",
  },
}));
