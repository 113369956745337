import {
  Box,
  MenuItem,
  Button,
  Select,
  Grid,
  Tabs,
  Tab,
  Typography,
  Divider,
} from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { Link as ReactLink } from "react-router-dom";

import firebase from "firebase";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Drafts } from "@material-ui/icons";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { Accounts, Reports } from "../shared/types";
import NotionIcon from "../components/UI/Icons/NotionIcon";

const useStyles = makeStyles({
  shareBox: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    width: "600px",
    padding: "20px",
    borderRadius: "10px",
  },

  textInputField: {
    width: "100%",
    overflow: "both",
  },
  textAreaField: {
    width: "100%",
    overflow: "both",
  },
  dropDown: {
    margin: "0px 10px",
    display: "flex",
    alignItems: "center",
    height: "40px",
  },
  shareLink: {
    margin: "0px 5px",
  },
  fieldWrapper: {
    padding: "10px",
    borderBottom: "#e8e8e8 .1px solid",
    height: "auto",
  },
  buttonWrapper: {
    padding: "10px",
    height: "auto",
  },
  boardLink: {
    color: "white",
    backgroundColor: "#4066F8",
    textTransform: "none",
    margin: "5px 5px",
  },
  fieldInfoWrapper: {
    width: "100%",
    borderRadius: "5px",
    border: ".1px solid",
    padding: "10px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  },
  cancelBtn: {
    color: "black",
    textTransform: "none",
    margin: "5px 5px",
  },
  tagDiv: {
    display: "flex",
    alignItems: "center",
    height: " 24px",
    margin: " 2px",
    lineIeight: "22px",
    backgroundColor: "#fafafa",
    border: "1px solid #e8e8e8",
    borderRadius: "2px",
    boxSizing: "content-box",
    padding: " 0 4px 0 10px",
    outline: 0,
    overflow: " hidden",
    " &:focus": {
      borderColor: "#40a9ff",
      backgroundColor: "#e6f7ff",
    },
  },
  emailValidation: {
    color: "red",
    padding: "5px",
  },
});

interface IShareReportModel {
  account: Accounts | null;
  report: Reports | null;
  isSettings: boolean;
  setIsPublic: (isPublic: boolean) => void;
  isPublic: boolean;
  isAllowedToEdit: boolean;
}
const ShareReportModal = React.forwardRef(
  (
    {
      account,
      report,
      setIsPublic,
      isPublic,
      isAllowedToEdit = true,
    }: IShareReportModel,
    ref
  ) => {
    const classes = useStyles();
    const [dropdownValue, setDropdownValue] = React.useState("noaccess");
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [embedURL, setEmbedURL] = React.useState("");
    const [viewURL, setViewURL] = React.useState("");

    useEffect(() => {
      if (!window) return;

      setViewURL(
        window.location.protocol +
          "//" +
          window.location.host +
          "/view/" +
          report!.id
      );
      setEmbedURL(
        window.location.protocol +
          "//" +
          window.location.host +
          "/embed/" +
          report!.id
      );
    }, []);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setIsPublic(event.target.value === "canview");
      setDropdownValue(event.target.value as string);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    return (
      <>
        <Box>
          <Box className={classes.shareBox}>
            <>
              <Box className={classes.fieldWrapper}>
                <div className={classes.dropDown}>
                  <LanguageIcon
                    style={{ marginRight: "5px", fill: "#082746" }}
                  />
                  Anyone with the link
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={isPublic ? "canview" : dropdownValue}
                    onChange={handleChange}
                    className={classes.dropDown}
                    variant="outlined"
                    disabled={!isAllowedToEdit}
                  >
                    <MenuItem value="canview">Can view</MenuItem>
                    <MenuItem value="noaccess">No access</MenuItem>
                  </Select>
                </div>
              </Box>
              {isPublic && (
                <ShareOptionsTab
                  report={report}
                  account={account}
                  embedURL={embedURL}
                  viewURL={viewURL}
                />
              )}
              <Divider style={{ marginTop: "1rem" }} />
              <Box className={classes.fieldWrapper}>
                <div className={classes.dropDown}>
                  See who it's currently privately shared with:
                  <ReactLink
                    to={`/account/${account!.id}/settings`}
                    className={classes.shareLink}
                  >
                    Sharing Settings
                  </ReactLink>
                </div>
              </Box>
            </>
          </Box>
        </Box>
      </>
    );
  }
);
export default ShareReportModal;

type EmbedCodeViewProps = {
  report: Reports | null;
  account: Accounts | null;
  embedURL?: string;
  viewURL: string;
};

function ShareOptionsTab({
  report,
  account,
  embedURL,
  viewURL,
}: EmbedCodeViewProps) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const classes = useStyles();
  const handleTabChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => setSelectedTabIndex(newValue);
  return (
    <>
      <Tabs
        value={selectedTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab label="Links" />
        <Tab label="Embed Code" />
        <Tab label="Social" />
        {/* <Tab label="Data-Feeds" /> */}
      </Tabs>
      {selectedTabIndex === 0 && (
        <Box className={classes.buttonWrapper} style={{ margin: "10px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
            style={{ padding: "1px" }}
          >
            <Typography variant="body1" style={{ marginBottom: "1rem" }}>
              Share this report with anyone. Simply click the button below to
              get the link.
            </Typography>
            <Box>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(`${viewURL}`);
                }}
                color="default"
                variant="contained"
                startIcon={<FileCopyIcon />}
              >
                Copy Link
              </Button>
            </Box>
            <Box style={{ marginLeft: "1rem" }}>
              <Link href={viewURL} rel="noopener" target="_blank">
                <Button
                  color="default"
                  variant="contained"
                  startIcon={<OpenInNewIcon />}
                >
                  View Link
                </Button>
              </Link>
            </Box>
          </Grid>{" "}
        </Box>
      )}

      {selectedTabIndex === 1 && (
        <Box className={classes.buttonWrapper} style={{ margin: "10px" }}>
          <EmbedCodeView
            report={report}
            account={account}
            embedURL={embedURL}
            viewURL={viewURL}
          />
        </Box>
      )}
      {selectedTabIndex === 2 && (
        <Box className={classes.buttonWrapper} style={{ margin: "10px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
            style={{ padding: "1px" }}
          >
            <Typography variant="body1" style={{ marginBottom: "1rem" }}>
              Click to share on social media. Alternatively, can also copy the
              link from the Links tab.
            </Typography>
            <Box style={{ marginLeft: "1rem" }}>
              <Link
                href={
                  "https://www.linkedin.com/sharing/share-offsite/?url=" +
                  viewURL
                }
                rel="noopener"
                target="_blank"
              >
                <Button
                  startIcon={<LinkedInIcon />}
                  color="default"
                  variant="contained"
                >
                  LinkedIn
                </Button>
              </Link>
            </Box>
            <Box style={{ marginLeft: "1rem" }}>
              <Link
                href={
                  "https://twitter.com/share?text=" +
                  encodeURIComponent(report?.name ? report?.name : "") +
                  "&url=" +
                  encodeURIComponent(viewURL) +
                  "="
                }
                rel="noopener"
                target="_blank"
              >
                <Button
                  startIcon={<TwitterIcon />}
                  color="default"
                  variant="contained"
                >
                  Twitter
                </Button>
              </Link>
            </Box>
            <Box style={{ marginLeft: "1rem" }}>
              <Link
                href={
                  "https://mail.google.com/mail/?view=cm&fs=1&su=" +
                  encodeURIComponent(report?.name ? report?.name : "") +
                  "&body=" +
                  encodeURIComponent(viewURL)
                }
                rel="noopener"
                target="_blank"
              >
                <Button
                  startIcon={<Drafts />}
                  color="default"
                  variant="contained"
                >
                  Gmail
                </Button>
              </Link>
            </Box>
          </Grid>{" "}
        </Box>
      )}
    </>
  );
}

function EmbedCodeView({ report, account, embedURL }: EmbedCodeViewProps) {
  const embedCode = `<div style="position: relative; padding-bottom: 56.25%; height: 0;">
  <iframe
    src="${embedURL}"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    allow="autoplay; fullscreen"
    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border:0"
    sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-presentation allow-modals allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation allow-downloads-without-user-activation allow-storage-access-by-user-activation"
    title="${report?.name}"
  ></iframe> 
</div>`;
  const handleCopyEmbedCode = () => {
    navigator.clipboard.writeText(`${embedCode}`);
  };

  return (
    <Box>
      <Box style={{ marginBottom: "3rem" }}>
        <Typography
          variant="h5"
          style={{ marginBottom: "1rem", fontSize: "medium", fontWeight: 600 }}
        >
          Add to Notion
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "1rem" }}>
          Click the button below to copy the Notion link to your clipboard. In
          Notion, paste the link and choose 'Create Embed' option. Read more{" "}
          <a
            href="https://www.notion.so/help/embed-and-connect-other-apps#insert-an-embed"
            rel="noreferrer"
            target="_blank"
          >
            {" "}
            here
          </a>
          .
        </Typography>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(`${embedURL}`);
          }}
          color="default"
          variant="contained"
          startIcon={<NotionIcon />}
        >
          Copy Notion Embed Link
        </Button>
      </Box>
      <Box>
        <Typography
          variant="h5"
          style={{ marginBottom: "1rem", fontSize: "medium", fontWeight: 600 }}
        >
          iFrame Embed Code
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "1rem" }}>
          Click the button below to copy the embed code to your clipboard. You
          can adjust the height and width of the iframe to fit your needs.
        </Typography>
        <Button
          onClick={() => {
            handleCopyEmbedCode();
          }}
          color="default"
          variant="contained"
          startIcon={<FileCopyIcon />}
        >
          Copy Embed Code
        </Button>
      </Box>
    </Box>
  );
}
