export const reportSections = [
  "Pages",
  "Reports",
  "Scheduled",
  "Design Docs",
  "Tools",
  "Customer Success",
  "API Documentation",
  "API Testing",
  "API Settings",
  "API Monitoring",
];
