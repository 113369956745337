import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

const drawerWidth = 240;

type DashboardDrawerProps = {
  open: boolean;
  Reports: React.ReactNode;
};
export function DashboardDrawer({ open, Reports }: DashboardDrawerProps) {
  const classes = useStyles();

  return (
    <Drawer
      className={clsx(classes.drawer, { [classes.drawerClosed]: !open })}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader} />
      <Divider />
      {Reports}
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerClosed: {
    width: 0,
  },
}));
