import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

type CreateReportCardProps = {
  img: string;
  onClick: () => void;
  comingSoon?: boolean;
};
export function CreateReportCard({
  img,
  onClick,
  comingSoon,
}: CreateReportCardProps) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} onClick={onClick}>
      <Button className={classes.button}>
        <img src={img} alt="" width="95%" style={{ marginTop: "-4px" }} />
      </Button>
      {comingSoon && <div className={classes.comingSoon}>Coming Soon</div>}
    </Paper>
  );
}

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",

    height: "5.875rem",
    borderRadius: "0.5rem",
    border: "solid 8px #fff",

    scrollSnapAlign: "start",
  },
  button: {
    flexGrow: 2,
  },
  comingSoon: {
    background: "#FF7F00",
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "0.625rem",
    fontFamily: "Roboto",
    fontWeight: 600,
  },
}));
