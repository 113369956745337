import { Organisations } from "../../shared/types";
import { getApiHeaders } from "../../shared/api";

export async function createOrganisation(name: string): Promise<Organisations> {
  const headers = await getApiHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/organisations`,
    {
      method: "POST",
      headers,
      body: JSON.stringify({ name }),
    }
  );
  const data = await response.json();
  return data as Organisations;
}
