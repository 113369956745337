export type MarkdownFormValues = {
  title: string;
  notes: string;
  public: boolean;
  section: string;
};

export type MarkdownFormData = {
  name: string;
  description: string;
  public: boolean;
  sectionName: string;
};

export function mapMarkdownFormData(data: MarkdownFormValues): MarkdownFormData {
  return {
    name: data.title,
    description: data.notes,
    public: data.public,
    sectionName: data.section,
  };
}