import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Accounts, Reports } from "./features/Dashboard/Dashboard";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { PublicAccount, PublicAccountReports } from "./features/PublicDashboard/PublicDashboard";

interface IReportPreviewTableProps {
  account: PublicAccount | null;
  handleSelectReport: (report: PublicAccountReports | any) => void;
  report?: PublicAccountReports;
}

const ReportPreviewTablePublic = (props: IReportPreviewTableProps) => {
  const { account, handleSelectReport } = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Box fontWeight="fontWeightBold">Report</Box>
              </TableCell>
              <TableCell align="right">
                {/* <Box fontWeight="fontWeightBold">view</Box> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {account?.reports?.map((report, r) => (
              <TableRow key={r}>
                <TableCell component="th" scope="row">
                  {report.name}
                </TableCell>
                <TableCell align="right">
                  {report.type === "link" ? (
                    <Button
                      component="a"
                      href={report.url.replace(
                        /^(?:(.*:)?\/\/)?(.*)/i,
                        (match, schemma, nonSchemmaUrl) =>
                          schemma ? match : `http://${nonSchemmaUrl}`
                      )}
                      target="_blank"
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      VIEW
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => props.handleSelectReport(report)}
                    >
                      VIEW
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportPreviewTablePublic;
