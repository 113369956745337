import List from "@material-ui/core/List";
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Reports } from "../../../shared/types";

type ReportMenusProps = {
  reports: Reports[];
  handleSelectReport: (report: Reports) => void;
  selectedReport?: Reports;
};
export function DashboardDrawerReports({
  reports,
  handleSelectReport,
  selectedReport,
}: ReportMenusProps) {
  return (
    <List>
      {reports.map((report) => (
        <div key={report.id}>
          {report.type === "link" ? (
            <ListItem
              button
              component="a"
              target="_blank"
              href={report.url}
              onClick={() => handleSelectReport(report)}
            >
              <Tooltip
                title={<span style={{ fontSize: "1.5em" }}></span>}
                disableHoverListener={!report.description}
              >
                <ListItemText primary={report.name} />
              </Tooltip>
              <ListItemSecondaryAction>
                <IconButton
                  component="a"
                  target="_blank"
                  href={report.url}
                  edge="end"
                  aria-label="open"
                  onClick={() => handleSelectReport(report)}
                >
                  <OpenInNewIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => handleSelectReport(report)}
              selected={selectedReport === report}
            >
              <Tooltip
                title={<span style={{ fontSize: "1.5em" }}></span>}
                disableHoverListener={!report.description}
              >
                <ListItemText
                  primary={report.name}
                  style={{ overflow: "hidden" }}
                />
              </Tooltip>
            </ListItem>
          )}
        </div>
      ))}
    </List>
  );
}
