import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  reportTemplates,
  ReportTemplateTypes,
  reportTypesTemplates,
} from "../../../../shared/reportTemplates";
import { CreateReportCardHeader } from "./CreateReportCardHeader";
import { CreateReportCard } from "./CreateReportCard";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { setCreateReportType } from "../../../../slices/createReportModalSlice";
import { ReportCardType } from "../../types";

export function CreateReportCards() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.outerContainer}>
      {(Object.keys(reportTypesTemplates) as ReportCardType[]).map((name) => (
        <Grid container direction="column" className={classes.innerContainer}>
          <CreateReportCardHeader title={name} />
          <div className={classes.reportContainer}>
            {(
              Object.keys(reportTypesTemplates[name]) as ReportTemplateTypes[]
            ).map((x) => (
              <CreateReportCard
                key={x}
                img={reportTemplates[x].organization.branding.logo}
                onClick={() => {
                  dispatch(setCreateReportType(x));
                }}
              />
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  outerContainer: {
    gap: "1.25rem",
    margin: "1rem 0 0 0",
    padding: "1.25rem",
    borderRadius: "0.5rem",
    overflow: "hidden",
    background:
      "linear-gradient(104deg, rgba(49, 172, 241, 0.11) 0%, rgba(160, 41, 163, 0.21) 100%)",
  },
  innerContainer: {
    gap: "0.75rem",
    maxWidth: "100%",
  },
  reportContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "20%",
    gap: "1.25rem",
    maxWidth: "100%",

    paddingBottom: "0.5rem",
    overflowX: "auto",
    overscrollBehaviorInline: "contain",

    scrollSnapType: "inline mandatory",
    scrollPaddingInline: "2rem",
  },
}));
