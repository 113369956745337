import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CreateReportHeader } from "../CreateReportHeader";
import Grid from "@material-ui/core/Grid";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { Reports } from "../../../../../shared/types";
import {
  reportCreated,
  reportUpdated,
} from "../../../../../actions/accountActions";
import { closeCreateReportModal } from "../../../../../slices/createReportModalSlice";
import { useNavigate, useParams } from "react-router-dom";
import { createReport, updateReport } from "../../../api";
import { CreateIframeReportForm } from "./CreateIframeReportForm";
import {
  IFrameReportFields,
  IframeFormData,
  IframeFormValues,
} from "./form.types";
import { CreateReportParams } from "../../../types";
import { useAppSelector } from "../../../../../hooks/useAppSelector";

type CreateIframeReportProps = {
  fields: IFrameReportFields;
};
export function CreateIframeReport({ fields }: CreateIframeReportProps) {
  const dispatch = useAppDispatch();
  const { id: accountId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const editedReport = useAppSelector(
    (state) => state.createReportModal.editedReport
  );
  const defaultValues: IframeFormValues | undefined = editedReport
    ? {
        applicationName: editedReport.name,
        notes: editedReport.description,
        public: editedReport.public,
        section: editedReport.sectionName,
        publicUrl: editedReport.url,
        allowFullWidth: editedReport.allowFullWidth,
      }
    : undefined;

  function handleReportCreated(report: Reports) {
    dispatch(reportCreated(report));
    dispatch(closeCreateReportModal());
    navigate(`/account/${accountId}?report=${report.id}`);
  }

  function handleReportUpdated(updatedReport: Reports) {
    dispatch(reportUpdated(updatedReport));
    dispatch(closeCreateReportModal());
  }

  function handleSubmit(data: IframeFormData) {
    const integration =
      fields.slug === "iframe" ? undefined : { slug: fields.slug };
    const report: CreateReportParams = {
      ...data,
      accountId: accountId ?? "",
      type: "iframe",
      integration,
    };
    if (editedReport) {
      return updateReport(editedReport.id, report).then(handleReportUpdated);
    }
    return createReport(report).then(handleReportCreated);
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <CreateReportHeader logo={fields.logo} />
      <CreateIframeReportForm
        handleSubmit={handleSubmit}
        fields={fields}
        defaultValues={defaultValues}
      />
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    gap: "1.25rem",
  },
}));
