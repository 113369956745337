import { createSlice } from "@reduxjs/toolkit";
import { Reports, ReportType } from "../shared/types";

type InitialState = {
  open: boolean;
  report: ReportType | null;
  editedReport: Reports | null;
};

const initialState: InitialState = {
  open: false,
  report: null,
  editedReport: null,
};

export const createReportModalSlice = createSlice({
  name: "createReportModal",
  initialState,
  reducers: {
    openCreateReportModal: (state) => {
      state.open = true;
    },
    closeCreateReportModal: (state) => {
      state.open = false;
      state.report = null;
      state.editedReport = null;
    },
    setCreateReportType: (state, action) => {
      state.report = action.payload;
    },
    setEditedReport(state, action) {
      state.editedReport = action.payload;
      state.report = action.payload.integration?.slug ?? action.payload.type;
    },
  },
});

export const {
  openCreateReportModal,
  closeCreateReportModal,
  setCreateReportType,
  setEditedReport,
} = createReportModalSlice.actions;

export default createReportModalSlice.reducer;
