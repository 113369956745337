export type IframeFormValues = {
  applicationName: string;
  notes: string;
  publicUrl: string;
  public: boolean;
  section: string;
  allowFullWidth: boolean;
};

export type IframeFormData = {
  name: string;
  description: string;
  url: string;
  public: boolean;
  sectionName: string;
  allowFullWidth: boolean;
};

export type IFrameReportFields = {
  logo: string;
  name: {
    text: string;
    placeholder?: string;
  };
  description: {
    text: string;
    placeholder?: string;
  };
  url: {
    text: string;
    docsUrl?: string;
  };
  slug: string;
};

export function mapIframeFormData(data: IframeFormValues): IframeFormData {
  return {
    name: data.applicationName,
    description: data.notes,
    url: data.publicUrl,
    public: data.public,
    sectionName: data.section,
    allowFullWidth: data.allowFullWidth,
  };
}
