import React from "react";
// @ts-ignore
import Retool from "react-retool";

import { connect } from "react-redux";
import { PublicMarkdownReportFrame } from "./ReportFrame/PublicMarkdownReportFrame";
import { IframeReportFrame, OpenApiReportFrame } from "./ReportFrame";
import { Reports } from "../shared/types";
import { RetoolReportFrame } from "./ReportFrame";

interface FSReportProps {
  report: Reports;
  showTitle?: boolean;
  embed?: boolean;
}

function FSReportByType({
  report,
  embed,
}: {
  report: Reports;
  embed?: boolean;
}) {
  if (report.type === "markdown") {
    return <PublicMarkdownReportFrame report={report} />;
  } else if (report.type === "openapi-spec") {
    return <OpenApiReportFrame report={report} />;
  } else if (RegExp("retool.com").test(report.url)) {
    return <RetoolReportFrame report={report} embed={embed} />;
  } else {
    return <IframeReportFrame report={report} embed={embed} />;
  }
}

const FSReport = ({ report, showTitle = false, embed }: FSReportProps) => {
  // const height: embed ? "100vh" : "100%"
  const height = "100vh";

  return (
    <div id="report-outer" style={{ height, display: "flex" }}>
      {showTitle && report.name && <h1>{report.name}</h1>}
      <FSReportByType report={report} embed={embed} />
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...ownProps,
    reports: state.accounts.reports,
  };
};

export default connect(mapStateToProps)(FSReport);
