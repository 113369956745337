import { configureStore, Reducer } from "@reduxjs/toolkit";
import accountsReducer from "../reducers/accountReducer";
import userReducer from "../reducers/userReducer";
import subscriptionReducer from "../reducers/subscriptionReducer";
import selectedReducer from "../slices/selectedSlice";
import createReportModalReducer from "../slices/createReportModalSlice";
import { combineReducers } from "redux";

const appReducer = combineReducers({
  accounts: accountsReducer,
  users: userReducer,
  subscriptions: subscriptionReducer,
  selected: selectedReducer,
  createReportModal: createReportModalReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
