import MDEditor from "@uiw/react-md-editor";
import React from "react";
import { Reports } from "../../shared/types";

export function PublicMarkdownReportFrame({ report }: { report: Reports }) {
  return (
    <div data-color-mode="light" style={{ width: "100%" }}>
      <div className="wmde-markdown-var"></div>
      `
      <MDEditor.Markdown
        style={{ padding: 15 }}
        source={report.body}
        linkTarget="_blank"
      />
    </div>
  );
}
