import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

type FormButtonProps = {
  value: string;
  onClick?: () => void;
  isSubmit?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};
export function FormButton({
  value,
  isSubmit,
  onClick,
  disabled,
  isLoading,
}: FormButtonProps) {
  const classes = useStyles();
  return (
    <Button
      className={[classes.button, isSubmit ? classes.submitButton : ""].join(
        " "
      )}
      variant="contained"
      type={isSubmit ? "submit" : "button"}
      onClick={onClick}
      disabled={disabled || isLoading}
      disableElevation
    >
      {isSubmit && isLoading && (
        <CircularProgress className={classes.circularProgress} />
      )}
      {value}
    </Button>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    paddingInline: "2.5rem",
    position: "relative",
  },
  circularProgress: {
    maxWidth: "1.25rem",
    maxHeight: "1.25rem",
    position: "absolute",
    left: "0.75rem",
  },
  submitButton: {
    backgroundColor: "#2F80ED",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(47,128,237,0.9)",
    },
  },
}));
