import { Control, Controller } from "react-hook-form";
import Box from "@material-ui/core/Box";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

type FormInputCheckboxProps = {
  control: Control<any>;
  name: string;
  defaultChecked?: boolean;
  label?: string;
  disabled?: boolean;
};
export function FormInputCheckbox({
  name,
  control,
  label,
  defaultChecked,
  disabled,
}: FormInputCheckboxProps) {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" gridGap="0.5rem">
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={<Typography className={classes.label}>{label}</Typography>}
            control={
              <Checkbox
                checked={value}
                onChange={(event) => onChange(event.target.checked)}
                defaultChecked={defaultChecked}
                disabled={disabled}
              />
            }
          />
        )}
      />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "0.6875rem",
  },
}));
