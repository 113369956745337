import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Reports } from "../../../shared/types";
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import { EditSharp , DeleteSharp} from "@material-ui/icons";
import { size } from "lodash";
import PeopleIcon from "../Icons/PeopleIcon";

const useStyles = makeStyles((theme: Theme) => ({
  IconButton: {
    "&": {
      borderRadius: "4px",
    },
  },
}));

interface UIDrawerNavMenuProps {
  report: Reports;
  handleSelectReport?: (report: Reports) => void;
  handleOpenDialog: (report: Reports, sectionName: string) => void;
  handleDeleteReport: (report: Reports, sectionName: string) => void;
  handleShareReportOpen: (report: Reports) => void;
  sectionName: string;
  options: string[];
  isAllowedToEdit?: boolean;
}

export default function UIDrawerNavMenu({
  report,
  handleSelectReport,
  handleOpenDialog,
  handleDeleteReport,
  handleShareReportOpen,
  sectionName,
  options,
  isAllowedToEdit = true,
}: UIDrawerNavMenuProps) {
  const classes = useStyles({ colour1: "" });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showfeature = (option: string) => {
    return options.includes(option);
  };

  //if (!isAllowedToEdit) remove edit and delete from the array
  if (!isAllowedToEdit) {
    options = options
      .filter((option) => option !== "edit")
      .filter((option) => option !== "delete");
  }

  if (!report) return <></>;

  return (
    <div>
      <IconButton
        aria-controls="report-mini-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.IconButton}
        style={{
          //margin: "0.1rem",
          //width: "20px",
          //display: "flex",
          //justifyContent: "left",
          //textTransform: "none",
          fontWeight: "bold",

          //textAlign: "left",
        }}
        //variant="outlined"

        size="small"
      >
        ...
      </IconButton>
      <Menu
        id="report-mini-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {showfeature("share") && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              handleShareReportOpen(report);
            }}
            style={{}}
          >
            <ListItemIcon>
              <PeopleIcon style={{ fill: "#626262" }} />
            </ListItemIcon>
            <ListItemText
              primary="Share Options"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {showfeature("edit") && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              handleOpenDialog(report, sectionName);
            }}
            style={{}}
          >
            <ListItemIcon>
              <EditSharp style={{}} fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {showfeature("delete") && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              handleDeleteReport(report, sectionName);
            }}
            style={{}}
          >
            <ListItemIcon>
              <DeleteSharp style={{}} fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        <Box padding={2}>
          <Typography variant="caption">Description</Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ maxWidth: "300px" }}
          >
            {report.description}
          </Typography>
        </Box>
      </Menu>
    </div>
  );
}
