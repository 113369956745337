import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useDispatch, connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Typography } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";

import {
  fetchSubscriptionOptions,
  fetchOrganisationSubscription,
} from "./actions/subscriptionActions";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

const OrganisationSubscription = (props) => {
  console.log(props);
  const { handleSubmit, control, errors: fieldsErrors } = useForm();
  const confirm = useConfirm();
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);
  const { organisationSubscription, subscriptionOptions, manageBillingUrl } =
    props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = useParams();

  const currentUser = firebase.auth().currentUser;

  const openStripeSubscription = async (stripeSessionId) => {
    const stripe = await stripePromise;
    stripe
      .redirectToCheckout({
        sessionId: stripeSessionId,
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.log(result);
      });
  };

  const handleStripeRedirect = (data) => {
    console.log(data);
  };

  const handleSubscriptionOptionClick = async (stripePriceId) => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    // Call your backend to create the Checkout Session
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/subscriptions/create-checkout-session`,
      {
        body: JSON.stringify({
          organisationId: id,
          stripePriceId: stripePriceId,
        }),
        headers,
        method: "POST",
      }
    );

    const session = await response.json();
    //alert(session.stripeSessionId)
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.stripeSessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  const getOrganisationSubscription = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        dispatch(fetchOrganisationSubscription(token, id));
      });
  };

  const getSubscriptionOptions = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        dispatch(fetchSubscriptionOptions(token, id));
      });
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const getDateFromSeconds = (seconds) => {
    var curdate = new Date(null);
    curdate.setTime(seconds * 1000);
    return curdate.toISOString(); //
    //console.log(formattedTime);
  };
  useEffect(() => {
    if (
      currentUser &&
      id &&
      (!subscriptionOptions || subscriptionOptions.length == 0)
    ) {
      getOrganisationSubscription();
      getSubscriptionOptions();
    }
  }, [
    currentUser,
    id,
    organisationSubscription,
    subscriptionOptions,
    manageBillingUrl,
  ]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        {manageBillingUrl ? (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                titleTypographyProps={{ variant: "body1" }}
                title={
                  "Current Subscription: " +
                  String(organisationSubscription.status).toUpperCase()
                }
              />

              <CardContent>
                <Typography variant="body2" component="p">
                  {organisationSubscription.cancelAt
                    ? "  Your Subscription will expire on : " +
                      getDateFromSeconds(organisationSubscription.cancelAt)
                    : ""}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  href={manageBillingUrl}
                  target="_blank"
                >
                  Manage Billing
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                titleTypographyProps={{ variant: "body1" }}
                title="Subscription Options"
              />
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box fontWeight="fontWeightBold"></Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box></Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box></Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box></Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionOptions &&
                      subscriptionOptions.map((option, s) => (
                        <TableRow key={s}>
                          <TableCell component="th" scope="row">
                            {option.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {option.formattedPrice}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {option.description}
                          </TableCell>
                          <TableCell align="right">
                            {option.subscribed ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                href={option.url}
                              >
                                Manage
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleSubscriptionOptionClick(
                                    option.stripePriceId
                                  )
                                }
                              >
                                Subscribe
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <CardHeader
              titleTypographyProps={{ variant: "body1" }}
              title={"Invoice Billing"}
            />
            <CardContent>
              <Typography variant="body2" component="p">
                Some Enterprise clients prefer Invoice based billing, if that's
                you then get in-touch and we can set something up.{" "}
                <a href="mailto:Contact@ThatApiCompany.com">
                  Contact@ThatApiCompany.com
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    subscriptionOptions: state.subscriptions.subscriptionOptions,
    organisationSubscription: state.subscriptions.organisationSubscription,
    manageBillingUrl: state.subscriptions.manageBillingUrl,
  };
};

export default connect(mapStateToProps)(OrganisationSubscription);
