import React from "react";
import { FormSectionTitle } from "../Form/FormSectionTitle";
import { FormInputText } from "../FormInput/FormInputText";
import Box from "@material-ui/core/Box";
import { FormInputLabel } from "../FormInput/FormInputLabel";
import { Chip, Typography } from "@material-ui/core";
import { FormInputCheckbox } from "../FormInput/FormInputCheckbox";
import { FormSplitter } from "../Form/FormSplitter";
import { FormInputSelect } from "../FormInput/FormInputSelect";
import { FormButton } from "../Form/FormButton";
import { makeStyles } from "@material-ui/core/styles";
import { ExternalLinkFormValues } from "./form.types";
import { reportSections } from "../reportSections";
import { UseFormReturn } from "react-hook-form";

type CreateExternalLinkFormViewProps = {
  onSubmit: (data: any) => void;
  handleBackClick: () => void;
  form: UseFormReturn<ExternalLinkFormValues>;
};
export function CreateExternalLinkFormView({
  form,
  onSubmit,
  handleBackClick,
}: CreateExternalLinkFormViewProps) {
  const { control, handleSubmit } = form;
  const classes = useStyles();
  const submitDisabled =
    !form.formState.isValid ||
    (!form.formState.isDirty && form.formState.defaultValues?.title !== "");
  const showBackButton = form.formState.defaultValues?.title === "";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.outerContainer}>
        <div className={classes.leftContainer}>
          <FormSectionTitle value="Settings" />
          <FormInputText
            name="title"
            label="Title"
            placeholder="Customer Support Page"
            control={control}
            error={form.formState.errors.title?.message}
            disabled={form.formState.isSubmitting}
            required
          />
          <FormInputText
            name="url"
            label="URL"
            placeholder="https://support.reportwrapper.com"
            error={form.formState.errors.url?.message}
            control={control}
            disabled={form.formState.isSubmitting}
            required
          />

          <Box
            display="flex"
            flexDirection="column"
            gridGap="0.5rem"
            className={classes.disabled}
          >
            <FormInputLabel value="Publishing" />
            <Typography variant="caption">
              Decide how you want to share the report
            </Typography>
            <FormInputCheckbox
              control={control}
              name="publishing"
              label="Notify teammates when I save"
              disabled={form.formState.isSubmitting}
            />
            <Chip
              size="small"
              label="Coming soon"
              color="secondary"
              disabled
              variant="outlined"
              style={{ width: "fit-content" }}
            />
          </Box>
        </div>
        <FormSplitter />
        <div className={classes.leftContainer}>
          <FormSectionTitle value="Display Options" />
          <FormInputSelect
            name="section"
            control={control}
            label="Section"
            placeholder="Support Documents"
            options={reportSections}
            disabled={form.formState.isSubmitting}
          />

          <Box
            display="flex"
            flexDirection="column"
            gridGap="0.5rem"
            className={classes.disabled}
          >
            <FormInputLabel value="Automated screenshots" />

            <Typography variant="caption">
              Take a snapshot of the page every few hours
            </Typography>
            <FormInputCheckbox control={control} name="snapshots" label="Yes" />

            <Typography variant="caption">Open link in a new window</Typography>
            <FormInputCheckbox control={control} name="newTab" label="Yes" />
            <Chip
              size="small"
              label="Coming soon"
              color="secondary"
              disabled
              variant="outlined"
              style={{ width: "fit-content" }}
            />
          </Box>
        </div>
      </div>
      <Box
        display="flex"
        justifyContent={showBackButton ? "space-between" : "flex-end"}
        marginTop="1.5rem"
      >
        {showBackButton && (
          <FormButton
            value="Back"
            onClick={handleBackClick}
            isLoading={form.formState.isSubmitting}
          />
        )}
        <FormButton
          value="Submit"
          isSubmit
          isLoading={form.formState.isSubmitting}
          disabled={submitDisabled}
        />
      </Box>
    </form>
  );
}

const useStyles = makeStyles(() => ({
  outerContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5.25rem 1fr",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));
