import { useAppSelector } from "../../hooks/useAppSelector";
import React, { useState } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import clsx from "clsx";
import Report from "../../components/Report";
import ReportPreviewGrid from "../../ReportPreviewGrid";
import { setReport } from "../../slices/selectedSlice";
import ReportPreviewTable from "../../ReportPreviewTable";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 240;

export function DashboardReports() {
  const selectedReport = useAppSelector((state) => state.selected.report);
  const account = useAppSelector((state) => state.selected.account);
  const [display, setDisplay] = useState<"grid" | "table">("grid");
  const dispatch = useAppDispatch();
  const classes = useStyles({ colour1: "#000" });

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div className={classes.drawerHeader} />
      {!selectedReport && (
        <div className={classes.buttonGroup}>
          <DashboardDisplay
            display={display}
            onClick={(x) => setDisplay(x as "grid" | "table")}
          />
        </div>
      )}
      <div>
        {selectedReport ? (
          <Report account={account!} report={selectedReport} />
        ) : display === "grid" ? (
          <ReportPreviewGrid
            account={account}
            handleSelectReport={(report) => dispatch(setReport(report))}
          />
        ) : display === "table" ? (
          <ReportPreviewTable
            account={account}
            handleSelectReport={(report) => dispatch(setReport(report))}
          />
        ) : (
          <ReportPreviewGrid
            account={account}
            handleSelectReport={(report) => dispatch(setReport(report))}
          />
        )}
      </div>
    </main>
  );
}
type DashboardDisplayProps = {
  display: "grid" | "table";
  onClick: (x: "grid" | "table") => void;
};
const DashboardDisplay = ({ display, onClick }: DashboardDisplayProps) => {
  const classes = useStyles({ colour1: "#000" });

  return (
    <>
      {(["grid", "table"] as ["grid", "table"]).map((buttonLabel) => (
        <Button
          key={buttonLabel}
          name={buttonLabel}
          onClick={() => onClick(buttonLabel)}
          size="medium"
          variant={buttonLabel === display ? "contained" : "outlined"}
          color="primary"
          className={classes.buttonOption}
        >
          {buttonLabel}
        </Button>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    padding: "12px 12px 0 12px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  buttonOption: {
    marginRight: "6px",
    textTransform: "capitalize",
  },
  buttonGroup: {
    margin: "0 0 1.3% 78%",
  },
}));
