import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import Divider from "@material-ui/core/Divider";
import { useDispatch, connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import {
  TextField,
  FormControl,
  Typography,
  CardContent,
} from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";

import {
  fetchOrganisationAccounts,
  addOrganisationAccount,
  editOrganisationAccount,
  archiveOrganisationAccount,
} from "./actions/userActions";
import { getCurrentUser, getUserToken } from "./shared/api";
import { fetchSubscriptionOptions } from "./actions/subscriptionActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { canDo } from "./stripeFeatures";
import { trackAccountCreated } from "./analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

function getSubscriptionPlanType(subscriptions) {
  if (subscriptions.organisationSubscription === null) {
    return "free";
  }
  return subscriptions.organisationSubscription.option.name
    .split(" ")[0]
    .toLowerCase();
}

const OrganisationAccounts = (props) => {
  const { handleSubmit, control, errors: fieldsErrors } = useForm();
  const confirm = useConfirm();
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);
  const [editAccount, setEditAccount] = useState({});
  const { organisationAccounts, subscriptions } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentUser = getCurrentUser();
  const newName = "";

  const [newAccountName, setNewAccountName] = useState({});
  const callArchiveAccount = (aid) => {
    confirm({
      description: "This will archive the Account and all of its Reports.",
    }).then(() => {
      if (currentUser) {
        getUserToken().then((token) => {
          dispatch(archiveOrganisationAccount(token, aid));
        });
      }
    });
  };

  const callNewAccount = (data) => {

    if (currentUser) {
      if (editAccount.id) {
        data.organisationId = id;
        getUserToken().then((token) => {
          dispatch(editOrganisationAccount(token, editAccount.id, {name: newAccountName}));
        });
      } else {
        data.organisationId = id;
        getUserToken().then((token) => {
          dispatch(addOrganisationAccount(token, data)).then((result) => {
            trackAccountCreated({
              userId: currentUser.uid,
              accountId: result.id,
              name: result.name,
            });
          });
        });
      }
      setShowNewAccountModal(false);
    }
  };

  const getOrganisationAccounts = () => {
    getUserToken().then((token) => {
      dispatch(fetchOrganisationAccounts(token, id));
    });
  };

  useEffect(() => {
    getUserToken().then((token) => {
      dispatch(fetchSubscriptionOptions(token, id));
    });
  }, []);

  const plan = getSubscriptionPlanType(subscriptions);

  const canCreateAccount = !!plan
    ? canDo({
        plan,
        feature: "accounts",
        currentSize: organisationAccounts.length || 0,
      })
    : true;

  useEffect(() => {
    if (currentUser && id) {
      getOrganisationAccounts();
    }
  }, [currentUser, id]);

  if (subscriptions.loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              titleTypographyProps={{ variant: "h5" }}
              title="Accounts"
            />
            <CardContent title="">
              Accounts are used to group together a set of Reports, Apps,
              Videos, Tools and Links.
              <br />
              Perfect for internal use for your Organisation or external use for
              your Clients.
            </CardContent>

            <CardActions>
              {canCreateAccount ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowNewAccountModal(true)}
                  disabled={!canCreateAccount}
                >
                  Create New Account
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={props.handleUpgradeAccount}
                >
                  Upgrade to add more accounts
                </Button>
              )}
            </CardActions>
          </Card>
          <br></br>
          <Divider />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box fontWeight="fontWeightBold">Name</Box>
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="right">
                    <Box></Box>
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="right">
                    <Box></Box>
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="right">
                    <Box></Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organisationAccounts &&
                  organisationAccounts.map((account, s) => (
                    <TableRow key={s}>
                      <TableCell component="th" scope="row">
                        {account.name}
                      </TableCell>
                      <TableCell style={{ width: 60 }} align="right">
                        <IconButton
                          onClick={() => {
                            setEditAccount(account);
                            setNewAccountName(account.name)
                            setShowNewAccountModal(true);
                          }}
                          edge="end"
                          aria-label="open"
                        >
                          <EditIcon size="sm" />
                        </IconButton>
                      </TableCell>

                      <TableCell style={{ width: 60 }} align="right">
                        <Link
                          style={{ textDecoration: "none", color: "unset" }}
                          to={`/account/${account.id}/settings`}
                        >
                          <IconButton edge="end" aria-label="open">
                            <SettingsIcon size="sm" />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => callArchiveAccount(account.id)}
                        >
                          Archive
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={showNewAccountModal}
            onClose={() => setShowNewAccountModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              onClose={() => setShowNewAccountModal(false)}
            >
              <span style={{ whiteSpace: "pre-wrap" }}>{editAccount?.id ?"Account Name" : "Create Account"} </span>
            </DialogTitle>
            <form onSubmit={handleSubmit(callNewAccount)}>
              <DialogContent style={{ paddingTop: "0" }}>
                <FormControl fullWidth>
                  <Controller
                    name="name"
                    render={({ control, ...inputProps }) => (
                      <TextField
                        id="name"
                        helperText={
                          fieldsErrors?.name ? fieldsErrors?.name?.message : null
                        }
                        label="Name"
                        name="name"
                        defaultValue={editAccount.name || ""}
                        fullWidth
                        onChange={(e) => setNewAccountName(e.target.value)}
                      />
                    )}
                    defaultValue={editAccount.name || ""}
                    control={control}
                    rules={{
                      required: "Required",
                      minLength: {
                        value: 1,
                        message: "The minimum length should be 1",
                      },
                      maxLength: {
                        value: 255,
                        message: "The maximum length can't exceed be 255",
                      },
                    }}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => setShowNewAccountModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  value="submit"
                  color="primary"
                >
                  Next
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Grid>
      </Grid>
    </Container>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => props.onClose()}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const mapStateToProps = (state, ownProps) => {
  console.log("STATE", state);
  return {
    ...ownProps,
    organisationAccounts: state.users.organisationAccounts,
    subscriptions: state.subscriptions,
  };
};

export default connect(mapStateToProps)(OrganisationAccounts);
