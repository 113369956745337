import React, { ReactNode, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import Divider from "@material-ui/core/Divider";
import { useDispatch, connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { TextField, FormControl, Typography } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";

import { fetchAccountAccessLogs } from "./actions/accountActions";
import {
  fetchOrganisationAccounts,
  OrganisationAccountsProps,
} from "./actions/userActions";
import { Accounts, Reports } from "./features/Dashboard/Dashboard";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: "20px",
    left: "20%",
    alignSelf: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  width100: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export interface UserProps {
  uid: string;
  name: string;
  email: string;
  lastLoginAt: string;
  verified: boolean;
}

export interface AccessLogsProps {
  uid: string;
  accountId: Accounts;
  reportId: Reports;
  createdAt: number;
  reportName: Reports | any;
  userName: UserProps | any;
}

const getDateFromSeconds = (seconds: number) => {
  var curdate = new Date();
  curdate.setTime(seconds * 1000);
  return formatDate(curdate); //.toISOString();//
};
function formatDate(dateVal: string | number | Date) {
  var newDate = new Date(dateVal);

  var sMonth = padValue(newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour: number | string = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM = "AM";

  var iHourCheck: number = typeof sHour === "string" ? parseInt(sHour) : sHour;

  if (iHourCheck > 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return (
    sMonth +
    "-" +
    sDay +
    "-" +
    sYear +
    " " +
    sHour +
    ":" +
    sMinute +
    " " +
    sAMPM
  );
}

function padValue(value: string | number) {
  return value < 10 ? "0" + value : value;
}

let selectedAccount = "";

const AccessLogs = (props: {
  accessLogs: AccessLogsProps[];
  organisationAccounts: OrganisationAccountsProps[];
}) => {
  const { accessLogs, organisationAccounts } = props;
  console.log("AccessLogs", props, selectedAccount);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = useParams();

  const currentUser = firebase.auth().currentUser;

  const [age, setAge] = React.useState("");

  const handleChange = (event: { target: { value: any } }) => {
    selectedAccount = event.target.value;
    firebase
      .auth()
      .currentUser!.getIdToken()
      .then((token: string) => {
        dispatch(fetchAccountAccessLogs(token, event.target.value));
      });
  };

  const getAccountAccessLogs = () => {
    firebase
      .auth()
      .currentUser!.getIdToken()
      .then((token) => {
        dispatch(fetchAccountAccessLogs(token, organisationAccounts[0].id));
      });
  };

  const getOrganisationAccounts = () => {
    firebase
      .auth()
      .currentUser!.getIdToken()
      .then((token) => {
        dispatch(fetchOrganisationAccounts(token, id));
      });
  };

  useEffect(() => {
    console.log(`useEffect`, accessLogs, organisationAccounts);
    if (currentUser && id) {
      getOrganisationAccounts();
      /* if(!accessLogs)getAccountAccessLogs();*/
    }
  }, [currentUser, id, accessLogs]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              titleTypographyProps={{ variant: "body1" }}
              title="A Log of each time someone views a Report on one of your Accounts"
            />
            <CardContent>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Account</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAccount}
                  onChange={handleChange}
                >
                  {organisationAccounts?.map((account, i) => (
                    <MenuItem value={account.id} key={i}>
                      {account.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box fontWeight="fontWeightBold">Report</Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box fontWeight="fontWeightBold">User</Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box fontWeight="fontWeightBold">Date</Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accessLogs ? (
                    accessLogs.map((option, s) => (
                      <TableRow key={s}>
                        <TableCell component="th" scope="row">
                          {option.reportName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {option.userName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getDateFromSeconds(option.createdAt)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Select an Account
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
const mapStateToProps = (
  state: {
    accounts: { accessLogs: AccessLogsProps };
    users: { organisationAccounts: OrganisationAccountsProps };
  },
  ownProps: any
) => {
  return {
    ...ownProps,
    accessLogs: state.accounts.accessLogs,
    organisationAccounts: state.users.organisationAccounts,
  };
};

export default connect(mapStateToProps)(AccessLogs);
