import {
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Accounts, Reports } from "../../../shared/types";
import ShareModalUI from "./UIShareModal";
import clsx from "clsx";
import { UIDrawerHeader } from "./UIDrawerHeader";
import { UIDrawerReports } from "./UIDrawerReports";

const drawerWidth = 240;

interface IDrawerProps {
  account: Accounts | null;
  open?: boolean;
  handleOpenDialog: (report?: Reports) => void;
  setUpdateAccount: (isDirty: boolean) => void;
  handleDeleteReport: (report?: Reports) => void;
}
export function UIDrawer({
  account,
  open,
  handleOpenDialog,
  setUpdateAccount,
  handleDeleteReport
}: IDrawerProps) {
  const [openShareReport, setOpenShareReport] = useState(false);
  const [currentReport, setCurrentReport] = useState<Reports | null>(null);
  const classes = useStyles({ colour1: "" });

  const reportSum =
    account?.sections?.reduce((previous, current) => {
      return previous + current.reports.length;
    }, 0) ?? 0;
  const showCreateReportButton = reportSum === 0;

  const handleShareReportOpen = (report: Reports) => {
    setCurrentReport(report);
    setOpenShareReport(!openShareReport);
  };

  const handleShareReportStateChange = () => {
    setUpdateAccount(true);
  };

  const handleCreateReport = (sectionName?: string) => {
    handleOpenDialog();
  };/*
  const handleDeleteReport = (report: Reports) => {
    setCurrentReport(report);
    setOpenShareReport(!openShareReport);
  };*/
  return (
    <>
      <Drawer
        className={clsx(classes.drawer, { [classes.drawerClosed]: !open })}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <UIDrawerHeader />
        <Divider />
        <List disablePadding dense>
          <ListItemText>&nbsp;</ListItemText>
          {account?.sections &&
            account?.sections.map((section) => (
              <div key={section.name}>
                <ListSubheader>
                  <>{section.name}</>
                  <ListItemSecondaryAction>
                    {!!account?.editor && account.editor && (
                      <IconButton
                        {...section}
                        className={classes.iconButton}
                        onClick={() => handleCreateReport(section.name)}
                        size="small"
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListSubheader>
                <UIDrawerReports
                  section={section}
                  handleOpenDialog={handleOpenDialog}
                  handleShareReportOpen={handleShareReportOpen}
                  handleDeleteReport={handleDeleteReport}
                  isAllowedToEdit={account && account.editor}
                />
              </div>
            ))}
          {showCreateReportButton && account?.editor && account.editor && (
            <ListItem className={classes.drawerCenter}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCreateReport()}
              >
                Add Report
                <AddIcon fontSize="small" />
              </Button>
            </ListItem>
          )}
        </List>
      </Drawer>
      <ShareModalUI
        openShareReport={openShareReport}
        handleShareReportOpen={handleShareReportOpen}
        
        report={currentReport}
        account={account}
        handleShareReportStateChange={handleShareReportStateChange}
        showButton={false}
        isAllowedToEdit={!!account && account.editor}
      />
    </>
  );
}

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerClosed: {
    width: 0,
  },
  iconButton: {
    "&": {
      borderRadius: "0.25rem",
    },
  },
}));
